import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";

import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";
import DataTable from "Components/UI/DataTable";
import NewAccountDialog from "./FDAccountDialog";

import { getFDDependencies } from "Actions/Vendors/fixedDeposits.action";

const ListAccounts = (props) => {
    const stateObject = useSelector((state) => state.fixedDeposits);
    const accounts = stateObject.accounts;
    const columns = [
        {
            dataField: "id",
            text: "Account No",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "scheme.schemeName",
            text: "Scheme",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "accountHolder.name",
            text: "Name",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.mobile",
            text: "Mobile",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.nomineeName",
            text: "Nominee",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.nomineeRelation",
            text: "Relation",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.nomineeAge",
            text: "Age",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
    ];
    return (
        <Row>
            <Col md="12">
                <br />
                {accounts ? (
                    <DataTable
                        keyField="id"
                        columns={columns}
                        data={accounts}
                        search={true}
                        pagination={true}
                        button={false}
                        dialogButton={true}
                        dialogAction={props.setDialog}
                        buttonLabel="Create New Account"
                        className="transaction-table"
                    />
                ) : (
                    ""
                )}
            </Col>
        </Row>
    );
};

const FDAccount = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [showDialog, setDialog] = useState(false);
    const [state, setState] = useState({});

    const stateObject = useSelector((state) => state.fixedDeposits);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const pageNavItems = [
        {
            title: "Fixed Deposits",
            active: true,
            to: "/fixed-deposits",
        },
    ];

    useEffect(() => {
        dispatch(getFDDependencies());
    }, [dispatch]);

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Fixed Deposits">
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <NewAccountDialog
                                setAlertStatus={setAlertStatus}
                                showDialog={showDialog}
                                setDialog={setDialog}
                            />
                            <ListAccounts setDialog={setDialog} />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default FDAccount;
