import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal, Table } from "react-bootstrap";
import { Input, SearchableSelect, CheckBoxInput } from "Components/UI/Input";
import * as Utils from "Helpers/utils";

import { createScheme, updateScheme } from "Actions/Vendors/fixedDeposits.action";

const SchemeDialog = (props) => {
    const [state, setState] = useState({
        schemeName: "",
        interestRate: 0,
        isDouble: false,
        maxLoanPercentage: "",
        frequency: "",

        depositIntrest_15_30: "",
        loanIntrest_15_30: "",

        depositIntrest_30_45: "",
        loanIntrest_30_45: "",

        depositIntrest_45_3: "",
        loanIntrest_45_3: "",

        depositIntrest_3_6: "",
        loanIntrest_3_6: "",

        depositIntrest_6_12: "",
        loanIntrest_6_12: "",

        depositIntrest_12_24: "",
        loanIntrest_12_24: "",

        depositIntrest_24_36: "",
        loanIntrest_24_36: "",

        depositIntrest_above_36: "",
        loanIntrest_above_36: "",
    });

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.fixedDeposits);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    useEffect(() => {
        if (props.schemeId) {
            const scheme = stateObject.schemes.find((item) => props.schemeId === item.schemeId);
            const data = JSON.parse(scheme.data);
            if (scheme) {
                let isDouble = false;
                if (scheme.isDouble === 1) {
                    isDouble = true;
                }
                setState({
                    schemeName: scheme.schemeName,
                    interestRate: data.interestRate,
                    maxLoanPercentage: data.maxLoanPercentage,
                    isDouble,
                    frequency: data.frequency,

                    depositIntrest_15_30: data.depositIntrest_15_30,
                    loanIntrest_15_30: data.loanIntrest_15_30,

                    depositIntrest_30_45: data.depositIntrest_30_45,
                    loanIntrest_30_45: data.loanIntrest_30_45,

                    depositIntrest_45_3: data.depositIntrest_45_3,
                    loanIntrest_45_3: data.loanIntrest_45_3,

                    depositIntrest_3_6: data.depositIntrest_3_6,
                    loanIntrest_3_6: data.loanIntrest_3_6,

                    depositIntrest_6_12: data.depositIntrest_6_12,
                    loanIntrest_6_12: data.loanIntrest_6_12,

                    depositIntrest_12_24: data.depositIntrest_12_24,
                    loanIntrest_12_24: data.loanIntrest_12_24,

                    depositIntrest_24_36: data.depositIntrest_24_36,
                    loanIntrest_24_36: data.loanIntrest_24_36,

                    depositIntrest_above_36: data.depositIntrest_above_36,
                    loanIntrest_above_36: data.loanIntrest_above_36,
                });
            }
        } else {
            resetForm();
        }
    }, [props.schemeId]);

    const submitForm = (e) => {
        props.setAlertStatus(true);
        const data = {
            interestRate: state.interestRate,
            maxLoanPercentage: state.maxLoanPercentage,
            frequency: state.frequency,

            depositIntrest_15_30: state.depositIntrest_15_30,
            loanIntrest_15_30: state.loanIntrest_15_30,

            depositIntrest_30_45: state.depositIntrest_30_45,
            loanIntrest_30_45: state.loanIntrest_30_45,

            depositIntrest_45_3: state.depositIntrest_45_3,
            loanIntrest_45_3: state.loanIntrest_45_3,

            depositIntrest_3_6: state.depositIntrest_3_6,
            loanIntrest_3_6: state.loanIntrest_3_6,

            depositIntrest_6_12: state.depositIntrest_6_12,
            loanIntrest_6_12: state.loanIntrest_6_12,

            depositIntrest_12_24: state.depositIntrest_12_24,
            loanIntrest_12_24: state.loanIntrest_12_24,

            depositIntrest_24_36: state.depositIntrest_24_36,
            loanIntrest_24_36: state.loanIntrest_24_36,

            depositIntrest_above_36: state.depositIntrest_above_36,
            loanIntrest_above_36: state.loanIntrest_above_36,
        };
        if (props.schemeId) {
            dispatch(
                updateScheme({
                    id: props.schemeId,
                    schemeId: props.schemeId,
                    schemeName: state.schemeName,
                    isDouble: state.isDouble ? 1 : 0,
                    data,
                })
            );
        } else {
            dispatch(
                createScheme({
                    schemeName: state.schemeName,
                    isDouble: state.isDouble ? 1 : 0,
                    data,
                })
            );
        }
    };
    const resetForm = () => {
        setState({
            schemeName: "",
            interestRate: 0,
            isDouble: false,
            maxLoanPercentage: "",
            frequency: "",

            depositIntrest_15_30: "",
            loanIntrest_15_30: "",

            depositIntrest_30_45: "",
            loanIntrest_30_45: "",

            depositIntrest_45_3: "",
            loanIntrest_45_3: "",

            depositIntrest_3_6: "",
            loanIntrest_3_6: "",

            depositIntrest_6_12: "",
            loanIntrest_6_12: "",

            depositIntrest_12_24: "",
            loanIntrest_12_24: "",

            depositIntrest_24_36: "",
            loanIntrest_24_36: "",

            depositIntrest_above_36: "",
            loanIntrest_above_36: "",
        });
    };

    const getTableRow = (timeRange, depositField, loanField) => {
        return (
            <tr>
                <td style={{ verticalAlign: "middle" }}>{timeRange}</td>
                <td>
                    <Input
                        type="text"
                        name={depositField}
                        id={depositField}
                        label=""
                        placeholder="Deposit Interest"
                        value={state[depositField]}
                        onChange={(e) => onChangeHandler(depositField, e.target.value)}
                    />
                </td>
                <td>
                    <Input
                        type="text"
                        name={loanField}
                        id={loanField}
                        label=""
                        placeholder="Loan Interest"
                        value={state[loanField]}
                        onChange={(e) => onChangeHandler(loanField, e.target.value)}
                    />
                </td>
            </tr>
        );
    };

    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>New Scheme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="schemeName"
                            controlId="formschemeName"
                            label="Enter Account Name"
                            placeholder="Enter Account Name"
                            value={state.schemeName}
                            onChange={(e) => onChangeHandler("schemeName", e.target.value)}
                            errMessage={errors["schemeName"]}
                        />
                    </Col>
                    <Col md={2} className="mt-5px">
                        <br />
                        <CheckBoxInput
                            checked={state.isDouble}
                            value={state.isDouble}
                            label="Is Double"
                            onClick={(e) => onChangeHandler("isDouble", e.target.checked)}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type="text"
                            name="interestRate"
                            controlId="forminterestRate"
                            label="Interest Rate"
                            value={state.interestRate}
                            onChange={(e) => onChangeHandler("interestRate", e.target.value)}
                            errMessage={errors["interestRate"]}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type="text"
                            name="maxLoanPercentage"
                            id="maxLoanPercentage"
                            label="Max Loan Percentage"
                            placeholder="Maxoan Percentage"
                            value={state.maxLoanPercentage}
                            onChange={(e) => onChangeHandler("maxLoanPercentage", e.target.value)}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            type="text"
                            name="frequency"
                            id="frequency"
                            label="Frequency"
                            placeholder="Frequency"
                            value={state.frequency}
                            onChange={(e) => onChangeHandler("frequency", e.target.value)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Table responsive borderless>
                            <thead>
                                <tr>
                                    <th>Monthly Range</th>
                                    <th>Deposit Interest</th>
                                    <th>Loan Interest</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getTableRow(
                                    "1/2 - 1",
                                    "depositIntrest_15_30",
                                    "loanIntrest_15_30"
                                )}

                                {getTableRow(
                                    "1 - 1 1/2",
                                    "depositIntrest_30_45",
                                    "loanIntrest_30_45"
                                )}

                                {getTableRow(
                                    "1 1/2 - 3",
                                    "depositIntrest_45_3",
                                    "loanIntrest_45_3"
                                )}

                                {getTableRow("3 - 6", "depositIntrest_3_6", "loanIntrest_3_6")}

                                {getTableRow("6 - 12", "depositIntrest_6_12", "loanIntrest_6_12")}

                                {getTableRow(
                                    "12 - 24",
                                    "depositIntrest_12_24",
                                    "loanIntrest_12_24"
                                )}

                                {getTableRow(
                                    "24 - 36",
                                    "depositIntrest_24_36",
                                    "loanIntrest_24_36"
                                )}

                                {getTableRow(
                                    " Above 36",
                                    "depositIntrest_above_36",
                                    "loanIntrest_above_36"
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="mt-5px">
                        <br />
                        <Button variant="primary" onClick={submitForm}>
                            Submit
                        </Button>
                        &nbsp;
                        <Button variant="secondary" onClick={resetForm}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

const Scheme = (props) => {
    const [showDialog, setDialog] = useState(false);

    const stateObject = useSelector((state) => state.fixedDeposits);

    const schemeOptions = Utils.getSearchableOptions(stateObject.schemes, "schemeName", "schemeId");

    return (
        <>
            <SchemeDialog
                showDialog={showDialog}
                setDialog={setDialog}
                setAlertStatus={props.setAlertStatus}
                schemeId={props.schemeId}
            />
            <Row>
                <Col md={10} style={{ paddingRight: "0px" }}>
                    <SearchableSelect
                        label="Select Scheme"
                        Options={schemeOptions}
                        controlId="formScheme"
                        name="scheme"
                        value={props.schemeId}
                        errMessage={props.errMessage}
                        onChange={(selectedOption) => {
                            if (selectedOption.length > 0) {
                                props.setSchemeId(selectedOption[0].value);
                            }
                        }}
                    />
                </Col>
                <Col md={2} style={{ paddingLeft: "1px" }}>
                    <br />
                    <Button
                        variant="secondary"
                        className="mt-7px"
                        onClick={() => {
                            setDialog(true);
                        }}
                    >
                        ...
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default Scheme;
