import { LoanAccountConstants as ActionConstant } from "Actions/constants";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    loanAccounts: [],
    loanAccount: null,
    loanSchemes: [],
    loanCategories: [],
    accountNumber: 0,
    pledgeItems: [],
};

const LoanAccountReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                loanAccounts: {},
                loanAccount: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                loanAccount: action.payload.object,
            };
            break;
        case ActionConstant.GET_ALL:
            state = {
                ...state,
                loanAccounts: [],
                loanAccount: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                loanAccounts: action.payload.objects,
            };
            break;
        case ActionConstant.GET_LOAN_ACCOUNT_CATEGORY:
            state = {
                ...state,
                PROCESSING: true,
                loanCategories: [],
            };
            break;
        case ActionConstant.GET_LOAN_ACCOUNT_CATEGORY_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                loanCategories: action.payload.loanCategories,
            };
            break;
        case ActionConstant.CREATE_LOAN_SCHEME:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_LOAN_SCHEME_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                loanSchemes: action.payload.loanSchemes,
            };
            break;

        case ActionConstant.GET_LOAN_SCHEMES:
            state = {
                ...state,
                PROCESSING: true,
                loanSchemes: [],
            };
            break;
        case ActionConstant.GET_LOAN_ACCOUNT_NUMBER_SUCCESS:
            state = {
                ...state,
                accountNumber: action.payload.accountNumber,
            };
            break;
        case ActionConstant.GET_LOAN_SCHEMES_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                loanSchemes: action.payload.loanSchemes,
            };
            break;
        case ActionConstant.ADD_PLEDGE_ITEM:
            const pledgeItem = action.payload;
            state = {
                ...state,
                pledgeItems: [...state.pledgeItems, pledgeItem],
            };
            break;
        case ActionConstant.REMOVE_PLEDGE_ITEM:
            state = {
                ...state,
                pledgeItems: state.pledgeItems.filter((item) => item.id !== action.payload.id),
            };
            break;
        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default LoanAccountReducer;
