import getActions from "Actions/base.action";
import { CurrentAccountConstants } from "../constants";

const baseActions = getActions(CurrentAccountConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getDependencies = () => {
    return {
        type: CurrentAccountConstants.GET_CURRENT_ACCOUNT_DEPENDENCIES,
        payload: null,
        success: CurrentAccountConstants.GET_CURRENT_ACCOUNT_DEPENDENCIES_SUCCESS,
    };
};

const getAllSchemes = () => {
    return {
        type: CurrentAccountConstants.GET_ALL_CURRENT_ACCOUNT_SCHEMES,
        payload: null,
        success: CurrentAccountConstants.GET_ALL_CURRENT_ACCOUNT_SCHEMES_SUCCESS,
    };
};

const createScheme = (data) => {
    return {
        type: CurrentAccountConstants.CREATE_CURRENT_ACCOUNT_SCHEME,
        payload: data,
        success: CurrentAccountConstants.CREATE_CURRENT_ACCOUNT_SCHEME_SUCCESS,
    };
};

const updateScheme = (data) => {
    return {
        type: CurrentAccountConstants.UPDATE_CURRENT_ACCOUNT_SCHEME,
        payload: data,
        success: CurrentAccountConstants.UPDATE_CURRENT_ACCOUNT_SCHEME_SUCCESS,
    };
};

const createTransaction = (data) => {
    return {
        type: CurrentAccountConstants.CREATE_CURRENT_ACCOUNT_TRANSACTION,
        payload: data,
        success: CurrentAccountConstants.CREATE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS,
    };
};

const getAllTransactions = (data) => {
    return {
        type: CurrentAccountConstants.GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS,
        payload: data,
        success: CurrentAccountConstants.GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS_SUCCESS,
    };
};

const deleteApprovalTransaction = (data) => {
    return {
        type: CurrentAccountConstants.DELETE_CURRENT_ACCOUNT_APPROVAL_TRANSACTION,
        success: CurrentAccountConstants.DELETE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS,
        payload: data,
        failed: CurrentAccountConstants.ERROR,
    };
};

const deleteTransaction = (data) => {
    return {
        type: CurrentAccountConstants.DELETE_CURRENT_ACCOUNT_TRANSACTION,
        success: CurrentAccountConstants.DELETE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS,
        payload: data,
        failed: CurrentAccountConstants.ERROR,
    };
};

const getApprovalTransactions = (data) => {
    return {
        type: CurrentAccountConstants.GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION,
        success: CurrentAccountConstants.GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const approveTransactions = (data) => {
    return {
        type: CurrentAccountConstants.APPROVE_CURRENT_ACCOUNT_TRANSACTION,
        success: CurrentAccountConstants.APPROVE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const setSelectedAll = () => {
    return {
        type: CurrentAccountConstants.SET_SELECTED_CURRENT_ACCOUNT_TRANSACTIONS,
        payload: null,
    };
};

const setSelectedOne = (data) => {
    return {
        type: CurrentAccountConstants.SET_SELECTED_CURRENT_ACCOUNT_TRANSACTION,
        payload: data,
    };
};

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    failed,
    getAllSchemes,
    createScheme,
    updateScheme,
    createTransaction,
    getDependencies,
    getAllTransactions,
    deleteTransaction,
    approveTransactions,
    getApprovalTransactions,
    setSelectedAll,
    setSelectedOne,
    deleteApprovalTransaction,
};
