import BaseSaga from "../base.saga";
import { UserConstant as ActionConstants } from "Actions/constants";
import { failed } from "Actions/Admin/society.action";
import { takeLatest } from "redux-saga/effects";

const endPoint = "/admin/users";

const sagaWatchers = BaseSaga(endPoint, failed);
const SagaActions = {
    ...sagaWatchers,
};

export function* watchUserRequests() {
    yield takeLatest(ActionConstants.CREATE, SagaActions.create);
    yield takeLatest(ActionConstants.UPDATE, SagaActions.update);
    yield takeLatest(ActionConstants.DELETE, SagaActions.remove);
    yield takeLatest(ActionConstants.GET_ALL, SagaActions.getAll);
    yield takeLatest(ActionConstants.GET, SagaActions.getOne);
}
