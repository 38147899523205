import React from "react";
import { Row, Col } from "react-bootstrap";
import Layout from "Components/Layout";

export const Forbidden = (props) => {
    return (
        <Layout>
            <Row className="justify-content-md-center ">
                <Col md="12" className="text-center">
                    <br />
                    <h1>Forbidden!</h1>
                    <h4>Code 403</h4>
                </Col>
            </Row>
        </Layout>
    );
};
