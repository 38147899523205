import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavDropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "Actions/auth";
import { adminNav, vendorNav } from "./_nav";
import $ from "jquery";
import logo from "Assets/Img/logo.svg";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

const Header = (props) => {
    const history = useHistory();
    const location = useLocation();
    //auth assigned value in ./reducers/index.js
    const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const navDropdownTitle = <i className="fa fa-user-o fa-lg"></i>;
    const LogOut = () => {
        dispatch(logout());
    };

    const path = location.pathname.split("/");
    let locationId = "";
    path.forEach((element) => {
        locationId = locationId + "/" + element;
    });

    locationId = locationId.substring(1, locationId.length);
    const renderLoggedInLinks = () => {
        return (
            <NavDropdown title={navDropdownTitle} id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to="/profile">
                    Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" onClick={LogOut}>
                    Logout
                </NavDropdown.Item>
            </NavDropdown>
        );
    };

    const toggleMenu = () => {
        $("#sidebar-wrapper").toggleClass("toggled");
        $("#page-wrapper").toggleClass("page-wrapper-ml-0");
    };

    /*const showDropDown = (e) => {
        $(".dropdown-btn").removeClass('selectedDropDown')
        e.target.className += " selectedDropDown"        
        const obj = $(".selectedDropDown")         
        let dropdownContent = obj[0].nextElementSibling        
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
    }*/

    const topNavigation = () => {
        return (
            <nav className="navbar navbar-expand-lg navbar-light top-navbar">
                <div className="sidebar-heading">
                    <img src={logo} alt="coreFin" width="220px" />
                </div>
                <button className="btn btn-default" id="menu-toggle" onClick={toggleMenu}>
                    <i className="fa fa-bars fa-lg"></i>
                </button>
                <ul className="navbar-nav ml-auto ">
                    <li className="nav-item">
                        <Button variant="link" className="nav-link">
                            <i className="fa fa-bell-o fa-lg" aria-hidden="true"></i>
                        </Button>
                    </li>
                    {renderLoggedInLinks()}
                </ul>
            </nav>
        );
    };
    /*<a href="javascript:void(0)" id="1" className="list-group-item list-group-item-action dropdown-btn" onClick={showDropDown}>
        Dropdown <i className="fa fa-caret-down"></i>
    </a>
    <div className="dropdown-container">
        <a href="#" className="list-group-item list-group-item-action">Link 1</a>
        <a href="#" className="list-group-item list-group-item-action">Link 2</a>
        <a href="#" className="list-group-item list-group-item-action">Link 3</a>
    </div>
    <a href="javascript:void(0)" id="2" className="list-group-item list-group-item-action dropdown-btn" onClick={showDropDown}>
        Dropdown <i className="fa fa-caret-down"></i>
    </a>
    <div className="dropdown-container">
        <a href="#" className="list-group-item list-group-item-action">Link 1</a>
        <a href="#" className="list-group-item list-group-item-action">Link 2</a>
        <a href="#" className="list-group-item list-group-item-action">Link 3</a>
    </div>*/
    const adminSideNavigation = () => {
        /* return (
			<div className="bg-light border-right" id="sidebar-wrapper">
				<div className="list-group list-group-flush">
					{adminNav.map((item, idx) => {
						if (item.subMenu) {
							console.log("HERE");
							return "HERE";
						} else {
							return (
								<Nav.Link
									as={Link}
									key={idx}
									to={item.to}
									className="list-group-item list-group-item-action"
								>
									{item.icon} &nbsp;
									{item.name}
								</Nav.Link>
							);
						}
					})}
				</div>
			</div>
		); */
        return (
            <div className="bg-light w-64  bg-white" id="sidebar-wrapper">
                <Navigation
                    activeItemId={locationId}
                    onSelect={({ itemId }) => {
                        if (itemId.indexOf("#id") == -1) {
                            history.push(itemId);
                        }
                    }}
                    items={adminNav}
                />
            </div>
        );
    };

    const vendorSideNavigation = () => {
        return (
            <div className="bg-light w-64  bg-white" id="sidebar-wrapper">
                <Navigation
                    activeItemId={locationId}
                    onSelect={({ itemId }) => {
                        if (itemId.indexOf("#id") == -1) {
                            history.push(itemId);
                        }
                    }}
                    items={vendorNav}
                />
            </div>
        );
    };
    return (
        <>
            {auth.authenticated === true ? topNavigation() : ""}
            {auth.authenticated && auth.user.role === "admin" ? adminSideNavigation() : ""}
            {auth.authenticated &&
            (auth.user.role === "president" ||
                auth.user.role === "director" ||
                auth.user.role === "clerk")
                ? vendorSideNavigation()
                : ""}
        </>
    );
};
export default Header;
