import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Modal } from "react-bootstrap";
import DataTable from "Components/UI/DataTable";
import { Input } from "Components/UI/Input";
import * as Actions from "Actions/Vendors/loanAccounts.action";

const GoldLoan = (props) => {
    const [showDialog, setDialog] = useState(false);
    const [item, setItem] = useState(null);
    const [itemValue, setItemValue] = useState(null);
    const [itemWeight, setItemWeight] = useState(null);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.loan);
    const pledgeItems = state.pledgeItems;

    const addItem = () => {
        if (!item) {
            return;
        }
        const pledgeItem = {
            id: state.pledgeItems.length + 1,
            item,
            itemValue,
            itemWeight,
        };
        dispatch(Actions.addPledgeItem(pledgeItem));
        setItem("");
        setItemValue("");
        setItemWeight("");
    };

    const removeItem = (id) => {
        dispatch(Actions.removePledgeItem({ id }));
    };

    const actionButtons = (cell, row) => {
        return (
            <Row>
                <Col md={12} className="text-center">
                    <Button
                        variant="link"
                        onClick={() => {
                            removeItem(row.id);
                        }}
                        className="text-danger"
                    >
                        <i className="fa fa-trash fa-lg  text-danger" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
    };
    const columns = [
        {
            dataField: "item",
            text: "Name",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "itemWeight",
            text: "Weight",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "itemValue",
            text: "Value",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];
    return (
        <>
            <Modal show={showDialog} onHide={setDialog} centered="true" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="4">
                            <Input
                                type="text"
                                name="itemName"
                                id="itemName"
                                placeholder="Item Name"
                                label="Item Name"
                                value={item}
                                onChange={(e) => setItem(e.target.value)}
                                controlId="formItemName"
                            />
                        </Col>
                        <Col md="4">
                            <Input
                                type="text"
                                name="itemWeight"
                                id="itemWeight"
                                placeholder="Item Weight"
                                label="Item Weight"
                                value={itemWeight}
                                onChange={(e) => setItemWeight(e.target.value)}
                                controlId="formItemWeight"
                            />
                        </Col>
                        <Col md="4">
                            <Input
                                type="text"
                                name="itemValue"
                                id="itemValue"
                                placeholder="Item Value"
                                label="Item Value"
                                value={itemValue}
                                onChange={(e) => setItemValue(e.target.value)}
                                controlId="formItemValue"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Button variant="primary" onClick={addItem}>
                                Submit
                            </Button>
                            &nbsp;
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setDialog(false);
                                }}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-7px">
                        <Col md={12}>
                            <DataTable
                                keyField="id"
                                columns={columns}
                                data={pledgeItems}
                                search={false}
                                pagination={true}
                                button={false}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Row>
                <Col md="4">
                    <Row>
                        <Col md="10" style={{ paddingRight: "0px" }}>
                            <Input
                                type="text"
                                placeholder="Pledge Items"
                                label="Pledge Items"
                                name="pledgeItems"
                                value={props.items}
                                readOnly="readOnly"
                            />
                        </Col>
                        <Col md="2" style={{ paddingLeft: "1px" }}>
                            <br />
                            <Button
                                variant="secondary"
                                className="mt-7px"
                                onClick={() => setDialog(true)}
                            >
                                ...
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col md="4">
                    <Input
                        type="text"
                        name="pledgeValue"
                        label="Pledge Value"
                        placeholder="Pledge Value"
                        value={props.pledgeValue}
                        onChange={(e) => props.setPledgeValue(e.target.value)}
                        controlId="formPledgeValue"
                    />
                </Col>
                <Col md="2">
                    <Input
                        type="text"
                        name="evaluator"
                        placeholder="Evaluator, If Any"
                        label="Evaluator, If Any"
                        value={props.evaluator}
                        onChange={(e) => props.setEvaluator(e.target.value)}
                        controlId="formEvaluator"
                    />
                </Col>
                <Col md="2">
                    <Input
                        type="text"
                        name="evaluatorCharges"
                        placeholder="Evaluator Charges"
                        label="Evaluator Charges"
                        value={props.evaluatorCharges}
                        onChange={(e) => props.setEvaluatorCharges(e.target.value)}
                        controlId="formEvaluatorCharges"
                    />
                </Col>
            </Row>
        </>
    );
};

export default GoldLoan;
