import getActions from "Actions/base.action";
import { TenantConstants } from "../constants";

const baseActions = getActions(TenantConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const migrateDB = (data) => {
    return {
        type: TenantConstants.DB_MIGRATE,
        payload: data,
        success: TenantConstants.DB_MIGRATE_SUCCESS,
    };
};

export { create, update, remove, getAll, getOne, migrateDB, failed };
