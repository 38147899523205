import { BankAccountConstants } from "../constants";

import getActions from "Actions/base.action";

const getBankAccountDependencies = () => {
    return {
        type: BankAccountConstants.GET_BANK_ACCOUNT_DEPENDENCIES,
        success: BankAccountConstants.GET_BANK_ACCOUNT_DEPENDENCIES_SUCCESS,
        payload: null,
    };
};

const getAll = (data) => {
    return {
        type: BankAccountConstants.GET_ALL,
        success: BankAccountConstants.GET_ALL_SUCCESS,
        payload: data,
    };
};

const getTransactions = (data) => {
    return {
        type: BankAccountConstants.GET_ALL_TRANSACTION,
        success: BankAccountConstants.GET_ALL_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const getLedgerTransactions = (data) => {
    return {
        type: BankAccountConstants.GET_LEDGER_TRANSACTION,
        success: BankAccountConstants.GET_LEDGER_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const newTransaction = (data) => {
    return {
        type: BankAccountConstants.CREATE_TRANSACTION,
        success: BankAccountConstants.CREATE_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const updateTransaction = (data) => {
    return {
        type: BankAccountConstants.UPDATE_TRANSACTION,
        success: BankAccountConstants.UPDATE_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const deleteTransaction = (data) => {
    return {
        type: BankAccountConstants.DELETE_TRANSACTION,
        success: BankAccountConstants.DELETE_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const deleteTempTransaction = (data) => {
    return {
        type: BankAccountConstants.BA_DELETE_TEMP_TRANSACTION,
        success: BankAccountConstants.BA_DELETE_TEMP_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const getApprovalTransactions = (data) => {
    return {
        type: BankAccountConstants.BA_GET_APPROVAL_TRANSACTIONS,
        payload: data,
        success: BankAccountConstants.BA_GET_APPROVAL_TRANSACTIONS_SUCCESS,
    };
};

const setSelectedAll = () => {
    return {
        type: BankAccountConstants.BA_SET_SELECTED_APPROVAL_TRANSACTIONS,
        payload: null,
    };
};

const setTransactionSelection = (data) => {
    return { type: BankAccountConstants.BA_SET_SELECTED_APPROVAL_TRANSACTION, payload: data };
};

const approveTransactions = (data) => {
    return {
        type: BankAccountConstants.BA_APPROVE_TRANSACTIONS,
        payload: data,
        success: BankAccountConstants.BA_APPROVE_TRANSACTIONS_SUCCESS,
    };
};

const baseActions = getActions(BankAccountConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
//const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    failed,
    getBankAccountDependencies,
    newTransaction,
    updateTransaction,
    deleteTransaction,
    getTransactions,
    getLedgerTransactions,
    getApprovalTransactions,
    setSelectedAll,
    setTransactionSelection,
    approveTransactions,
    deleteTempTransaction,
};
