import { dayBeginEndConstants } from "Actions/constants";
import * as Utils from "Helpers/utils";
const initState = {
    openingDate: Utils.now(),
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    transactions: [],
    openingBalance: 0,
    closingBalance: 0,
    closingDate: Utils.now(),
};

const dayBeginEndReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case dayBeginEndConstants.GET_CURRENT_OPENING_DATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case dayBeginEndConstants.GET_CURRENT_OPENING_DATE_SUCCESS:
            localStorage.setItem("openingDate", action.payload.openingDate);
            localStorage.setItem("openingBalance", action.payload.openingBalance);
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                openingDate: action.payload.openingDate,
                openingBalance: action.payload.openingBalance,
            };
            break;
        case dayBeginEndConstants.GET_ALL:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case dayBeginEndConstants.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                transactions: action.payload.transactions,
                openingDate: action.payload.openingDate,
                closingBalance: action.payload.closingBalance,
            };
            break;
        case dayBeginEndConstants.ERROR:
            state = {
                ...initState,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};
export default dayBeginEndReducer;
