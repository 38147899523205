import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "Components/Layout";
import * as SocietyActions from "Actions/Admin/society.action";
import { Card, Row, Col, Button } from "react-bootstrap";
import DataTable from "Components/UI/DataTable";
import { Link } from "react-router-dom";
import { StatusBadge, HeadOfficeBadge, TypeBadge } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import { formatDateInTable } from "Helpers/utils";

class SocietyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus: true,
        };
    }

    setAlertStatus = (status) => {
        this.setState({ alertStatus: status });
    };

    isHeadOffice = (cell, row) => {
        return <HeadOfficeBadge headOffice={row.headOffice} />;
    };
    showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    typeBadge = (cell, row) => {
        return <TypeBadge type={row.type} />;
    };

    removeSociety = (id, tenantId) => {
        this.props.actions.remove({ id, tenantId });
    };

    actionButtons = (cell, row) => {
        return (
            <>
                <Button variant="link" as={Link} to={`/society/${row.id}`} className="btn btn-sm">
                    <i className="fa fa-edit fa-lg" aria-hidden="true" title="Edit Society" />
                </Button>
                <Button
                    variant="link"
                    title="Deactivate Society"
                    onClick={() => {
                        this.removeSociety(row.id, row.tenantId);
                    }}
                >
                    <i className="fa fa-trash fa-lg" aria-hidden="true" />
                </Button>
                <Button
                    variant="link"
                    title="Add New User"
                    as={Link}
                    to={`/users/add/society/${row.id}/${row.tenantId}`}
                    className="btn btn-sm"
                >
                    <i className="fa fa-user fa-lg" aria-hidden="true" />
                </Button>
            </>
        );
    };

    componentDidMount() {
        this.props.actions.getAll();
    }

    render() {
        //Nav Items
        const pageNavItems = [
            {
                title: "Society",
                active: false,
                to: "/society",
            },
        ];
        const columns = [
            {
                dataField: "societyName",
                text: "Name",
                style: { textAlign: "center" },
                headerStyle: { textAlign: "center" },
            },
            {
                dataField: "address",
                text: "Address",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "headOffice",
                text: "Head Office",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.isHeadOffice,
            },
            {
                dataField: "contactPersonName",
                text: "Contact Person",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "contactPersonMobile",
                text: "Phone",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "type",
                text: "Type",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.typeBadge,
            },
            {
                dataField: "status",
                text: "Status",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.showBadge,
            },
            {
                dataField: "createdAt",
                text: "Created at",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: formatDateInTable,
            },
            {
                dataField: "#",
                text: "Action",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.actionButtons,
            },
        ];
        return (
            <Layout pageNavItems={pageNavItems} pageTitle="Society">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <DataTable
                                    keyField="id"
                                    columns={columns}
                                    data={this.props.stateObject.societies}
                                    search={true}
                                    pagination={true}
                                    button={true}
                                    buttonTitle="Create Society"
                                    buttonURI="/society/add"
                                    buttonVariant="success"
                                />
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={this.props.stateObject}
                                alertStatus={this.state.alertStatus}
                                setAlertStatus={this.setAlertStatus}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    societies: state.society.societies,
    error: state.society.error,
    stateObject: state.society,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(SocietyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocietyDetails);
