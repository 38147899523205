import React from "react";
import Layout from "Components/Layout";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const Home = (props) => {
    const auth = useSelector((state) => state.auth);
    if (!auth.authenticated) {
        return <Redirect to={`/signin`} />;
    }

    const pageNavItems = [
        {
            title: "Dashboard",
            eventName: null,
            link: true,
            to: "/",
            className: "page-title",
            icon: "",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems}>
            <Row>
                <Col md={12}>TODO</Col>
            </Row>
        </Layout>
    );
};
export default Home;
