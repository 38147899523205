import {
    BankAccountConstants as BAConstant,
    BankAccountHeadContants as BAHConstant,
    BankAccountLedgerConstants as BALConstant,
} from "Actions/constants";

const initState = {
    errors: [],
    bankAccountHeads: [],
    bankAccounts: [],
    bankAccountLedgers: [],
    bankAccountHead: null,
    bankAccount: null,
    bankAccountLedger: null,
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    transactions: [],
    transaction: null,
    approvalTransactions: [],
    balance: 0,
};

let selectedTransactions = [];

const BankAccountsReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
        balance: 0,
    };
    switch (action.type) {
        case BAConstant.CREATE:
        case BAConstant.UPDATE:
        case BAConstant.DELETE:
        case BAConstant.GET_ALL:
        case BAConstant.GET_ALL_TRANSACTION:
        case BAConstant.GET_BANK_ACCOUNT_TRANSACTION:
        case BAHConstant.CREATE:
        case BAHConstant.UPDATE:
        case BALConstant.GET_ALL:
        case BALConstant.UPDATE:
        case BALConstant.CREATE:
        case BAConstant.CREATE_TRANSACTION:
        case BAConstant.UPDATE_TRANSACTION:
        case BAConstant.BA_GET_APPROVAL_TRANSACTIONS:
        case BAConstant.BA_APPROVE_TRANSACTIONS:
        case BAConstant.BA_DELETE_TEMP_TRANSACTION:
        case BAConstant.DELETE_TRANSACTION:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;

        case BAHConstant.GET_ALL:
            state = {
                ...state,
                PROCESSING: true,
                approvalTransactions: [],
                transactions: [],
            };
            break;
        case BAConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                bankAccount: action.payload.bankAccount,
                bankAccounts: action.payload.bankAccounts,
                message: action.payload.message,
            };
            break;

        case BAConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                bankAccount: action.payload.bankAccount,
                bankAccounts: action.payload.bankAccounts,
            };
            break;

        case BAConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                transactions: action.payload.transactions,
                balance: action.payload.balance,
            };
            break;

        case BAConstant.GET:
            let bankAccount = null;
            for (const item of state.bankAccounts) {
                if (item.accountNo === action.payload.id) {
                    bankAccount = item;
                }
            }
            state = {
                ...state,
                bankAccount,
            };
            break;

        case BAConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                bankAccount: action.payload.bankAccount,
            };
            break;

        case BAConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                bankAccounts: action.payload.bankAccounts,
            };
            break;

        case BAConstant.DELETE_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
            };
            break;
        case BAConstant.GET_ALL_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                transactions: action.payload.transactions,
                balance: action.payload.balance,
            };
            break;

        case BAConstant.BA_GET_APPROVAL_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                approvalTransactions: action.payload.approvalTransactions,
                message: action.payload.message,
            };
            break;

        case BAConstant.BA_SET_SELECTED_APPROVAL_TRANSACTIONS:
            selectedTransactions = state.approvalTransactions.map((item) => {
                return {
                    ...item,
                    selected: true,
                };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case BAConstant.BA_SET_SELECTED_APPROVAL_TRANSACTION:
            selectedTransactions = state.approvalTransactions.map((item) => {
                if (item.transactionId === action.payload.id) {
                    return {
                        ...item,
                        selected: action.payload.selected,
                    };
                }
                return { ...item };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case BAConstant.BA_DELETE_TEMP_TRANSACTION_SUCCESS:
        case BAConstant.BA_APPROVE_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                approvalTransactions: action.payload.approvalTransactions,
            };
            break;
        case BAConstant.GET_BANK_ACCOUNT_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
            };
            break;

        case BAHConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                bankAccountHeads: action.payload.bankAccountHeads,
            };
            break;
        case BAHConstant.GET:
            let bankAccountHead = null;
            for (const item of state.bankAccountHeads) {
                if (item.accountNo === action.payload.id) {
                    bankAccountHead = item;
                }
            }

            state = {
                ...state,
                bankAccountHead,
            };
            break;
        case BAHConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                bankAccountHeads: action.payload.bankAccountHeads,
            };
            break;

        case BAHConstant.UPDATE_SUCCESS:
            const accountHead = action.payload.accountHead;
            let index = -1;
            for (var key in state.bankAccountHeads) {
                const tempObj = state.bankAccountHeads[key];
                if (tempObj.accountNo === accountHead.accountNo) {
                    index = key;
                    break;
                }
            }
            if (index !== -1) {
                state.bankAccountHeads[index] = accountHead;
            }
            state = {
                ...state,
                REQUEST_SUCCESS: true,
            };
            break;

        case BALConstant.UPDATE_SUCCESS:
        case BALConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                bankAccountLedger: action.payload.bankAccount,
                bankAccountLedgers: action.payload.bankAccounts,
                message: action.payload.message,
            };
            break;

        case BALConstant.GET:
            let bankAccountLedger = null;
            for (const item of state.bankAccountLedgers) {
                if (item.accountNo === action.payload.id) {
                    bankAccountLedger = item;
                }
            }
            state = {
                ...state,
                bankAccountLedger,
            };
            break;

        case BALConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                bankAccountLedgers: action.payload.ledgers,
            };
            break;

        case BAConstant.CREATE_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                transaction: action.payload.transaction,
                message: action.payload.message,
            };
            break;
        case BAHConstant.ERROR:
        case BALConstant.ERROR:
        case BAConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default BankAccountsReducer;
