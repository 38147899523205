import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { CheckBoxInput, Input, SearchableSelect, SelectInput, TextArea } from "Components/UI/Input";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/loanAccounts.action";

const NewLoanScheme = (props) => {
    const [schemeName, setSchemeName] = useState("");
    const [termType, setTermType] = useState("");
    const [loanCategoryId, setLoanCategory] = useState("");
    const [maxRepaymentDays, setMaxRepaymentDays] = useState("");
    const [interestRate, setInterestRate] = useState("");
    const [penalInterest, setPenalInterest] = useState("");
    const [depositInterestRate, setDepositInterestRate] = useState("");
    const [insuranceFees, setInsuranceFees] = useState("");
    const [legalFees, setLegalFees] = useState("");
    const [description, setDescription] = useState("");
    const [compoundInterest, setCompoundInterest] = useState(false);
    const [kisanCreditCard, setKisanCreditCard] = useState(false);
    const [finance, setFinance] = useState(false);
    const [goldLoan, setGoldLoan] = useState(false);
    const [fdLoan, setFDLoan] = useState(false);
    const [vehicleLoan, setVehicleLoan] = useState(false);
    const [status, setStatus] = useState(true);

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.loan);
    const loanCategories = stateObject.loanCategories;
    //const loanCategoryOptions = [{}];
    const loanCategoryOptions = Utils.getSearchableOptions(loanCategories, "categoryName", "id");

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const termOptions = { 1: "Short Term", 2: "Mid Term", 3: "Long Term" };

    const submitForm = () => {
        props.setAlertStatus(true);
        const data = {
            schemeName,
            termType,
            loanCategoryId,
            maxRepaymentDays: maxRepaymentDays ? maxRepaymentDays : 0,
            interestRate: interestRate ? interestRate : 0,
            penalInterest: penalInterest ? penalInterest : 0,
            depositInterestRate: depositInterestRate ? depositInterestRate : 0,
            insuranceFees: insuranceFees ? insuranceFees : 0,
            legalFees: legalFees ? legalFees : 0,
            description: description ? description : "",
            compoundInterest: compoundInterest ? compoundInterest : 0,
            kisanCreditCard: kisanCreditCard ? kisanCreditCard : 0,
            finance: finance ? finance : 0,
            goldLoan: goldLoan ? goldLoan : 0,
            fdLoan: fdLoan ? fdLoan : 0,
            vehicleLoan: vehicleLoan ? vehicleLoan : 0,
            status: status ? status : 0,
        };
        dispatch(Actions.createScheme(data));
    };

    const resetLoanTypes = () => {
        setFinance(false);
        setGoldLoan(false);
        setFDLoan(false);
        setVehicleLoan(false);
    };

    useEffect(() => {
        dispatch(Actions.getLoanCategories());
    }, [dispatch]);

    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>New Loan Scheme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="schemeName"
                            controlId="formSchemeId"
                            label="Enter Scheme Name"
                            placeholder="Enter Scheme Name"
                            value={schemeName}
                            onChange={(e) => {
                                setSchemeName(e.target.value);
                            }}
                            errMessage={errors["schemeName"]}
                        />
                    </Col>
                    <Col md={6}>
                        <SearchableSelect
                            label="Select Loan Category"
                            placeholder="Select Loan Category"
                            Options={loanCategoryOptions}
                            controlId="loanCategoryId"
                            name="loanCategoryId"
                            value={loanCategoryId}
                            onChange={(selectedOption) => {
                                if (selectedOption.length > 0) {
                                    setLoanCategory(selectedOption[0].value);
                                }
                            }}
                            errMessage={errors["loanCategoryId"]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <SelectInput
                            label="Select Term Type"
                            placeholder="Select Term Type"
                            Options={termOptions}
                            controlId="termType"
                            name="termType"
                            value={termType}
                            handleChange={(e) => setTermType(e.target.value)}
                            errMessage={errors["termType"]}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="maxRepaymentDays"
                            id="maxRepaymentDays"
                            value={maxRepaymentDays}
                            onChange={(e) => setMaxRepaymentDays(e.target.value)}
                            placeholder="Max Repyment Time (Days)"
                            label="Max Repyment Time (Days)"
                            controlId="formMaxRepaymentDays"
                            errMessage={errors["maxRepaymentDays"]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="interestRate"
                            id="interestRate"
                            value={interestRate}
                            onChange={(e) => setInterestRate(e.target.value)}
                            label="Interest Rate"
                            placeholder="Interest Rate"
                            controlId="formInterestRate"
                            errMessage={errors["interestRate"]}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="penalInterest"
                            id="penalInterest"
                            value={penalInterest}
                            onChange={(e) => setPenalInterest(e.target.value)}
                            label="Penal Interest"
                            placeholder="Penal Interest"
                            controlId="formPenalInterest"
                            errMessage={errors["penalInterest"]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="insuranceFees"
                            id="insuranceFees"
                            value={insuranceFees}
                            onChange={(e) => setInsuranceFees(e.target.value)}
                            label="Insurance Fees"
                            placeholder="Insurance Fees"
                            controlId="formInsuranceFees"
                            errMessage={errors["insuranceFees"]}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="legalFees"
                            id="legalFees"
                            value={legalFees}
                            onChange={(e) => setLegalFees(e.target.value)}
                            label="Legal Fees"
                            placeholder="Legal Fees"
                            controlId="formLegalFees"
                            errMessage={errors["legalFees"]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <TextArea
                            name="description"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            label="Description"
                            placeholder="Description"
                            controlId="formDescription"
                            errMessage={errors["description"]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="depositInterestRate"
                            id="depositInterestRate"
                            value={depositInterestRate}
                            onChange={(e) => setDepositInterestRate(e.target.value)}
                            label="Rate of Interest for Deposit"
                            placeholder="Rate of Interest for Deposit"
                            controlId="formDepositInterest"
                            errMessage={errors["depositInterestRate"]}
                        />
                    </Col>
                    <Col md={3}>
                        <label>Compound Interest</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={compoundInterest}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Yes"
                            offlabel="No"
                            onChange={(checked) => {
                                setCompoundInterest(checked);
                            }}
                        />
                    </Col>
                    <Col md={3}>
                        <label>Kisan Credit Card</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={kisanCreditCard}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Yes"
                            offlabel="No"
                            onChange={(checked) => {
                                setKisanCreditCard(checked);
                            }}
                        />
                    </Col>
                    <Col md={2}>
                        <label>Status</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={status}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Active"
                            offlabel="In-Active"
                            onChange={(checked) => {
                                setStatus(checked);
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-7px">
                    <Col md={2} className="mt-7px">
                        <CheckBoxInput
                            type="radio"
                            name="loanType"
                            id="loanType"
                            checked={finance}
                            label="Finance Loan"
                            controlId={"formFinance"}
                            onClick={(e) => {
                                resetLoanTypes();
                                setFinance(e.target.checked);
                            }}
                        />
                    </Col>
                    <Col md={2} className="mt-7px">
                        <CheckBoxInput
                            type="radio"
                            name="loanType"
                            id="loanType"
                            checked={goldLoan}
                            label="Gold Loan"
                            controlId={"formGoldLoan"}
                            onClick={(e) => {
                                resetLoanTypes();
                                setGoldLoan(e.target.checked);
                            }}
                        />
                    </Col>
                    <Col md={2} className="mt-7px">
                        <CheckBoxInput
                            type="radio"
                            name="loanType"
                            id="loanType"
                            checked={fdLoan}
                            label="FD Loan"
                            controlId={"formFDLoan"}
                            onClick={(e) => {
                                resetLoanTypes();
                                setFDLoan(e.target.checked);
                            }}
                        />
                    </Col>
                    <Col md={2} className="mt-7px">
                        <CheckBoxInput
                            type="radio"
                            name="loanType"
                            id="loanType"
                            checked={vehicleLoan}
                            label="Vehicle Loan"
                            controlId={"formVehicleLoan"}
                            onClick={(e) => {
                                resetLoanTypes();
                                setVehicleLoan(e.target.checked);
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-7px">
                    <Col md={6}>
                        <Button variant="primary" onClick={submitForm}>
                            Submit
                        </Button>
                        &nbsp;
                        <Button
                            variant="secondary"
                            onClick={() => {
                                props.setDialog(false);
                            }}
                        >
                            Close
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

const LoanScheme = (props) => {
    const [showDialog, setDialog] = useState(false);
    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.loan);
    const loanSchemes = stateObject.loanSchemes;
    const loanSchemeOptions = Utils.getSearchableOptions(loanSchemes, "schemeName", "id");

    useEffect(() => {
        /*dispatch(Actions.getLoanSchemes());*/
    }, [dispatch]);
    return (
        <>
            <NewLoanScheme
                showDialog={showDialog}
                setDialog={setDialog}
                setAlertStatus={props.setAlertStatus}
            />
            <Row>
                <Col md={10} style={{ paddingRight: "0px" }}>
                    <SearchableSelect
                        label="Select Loan Scheme"
                        Options={loanSchemeOptions}
                        controlId="loanSchemeId"
                        name="loanSchemeId"
                        value={props.loanSchemeId}
                        onChange={(selectedOption) => {
                            if (selectedOption.length > 0) {
                                props.setLoanScheme(selectedOption[0].value);
                            }
                        }}
                    />
                </Col>
                <Col md={2} style={{ paddingLeft: "1px" }}>
                    <br />
                    <Button variant="secondary" className="mt-7px" onClick={() => setDialog(true)}>
                        ...
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default LoanScheme;
