import axiosInstance from "Helpers/axios";
export const post = (path, data) => {
    return async (dispatch) => {
        const res = await axiosInstance()
            .post(path, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

export const put = (path, data) => {
    return async (dispatch) => {
        const res = await axiosInstance()
            .put(path, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

export const remove = (path, data) => {
    return async (dispatch) => {
        const res = await axiosInstance()
            .post(path, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

export const removePut = (path, data) => {
    return async (dispatch) => {
        const res = await axiosInstance()
            .put(path, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

export const get = (path) => {
    return async (dispatch) => {
        const res = await axiosInstance()
            .get(path)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });

        return res;
    };
};

export const postFormdData = (path, formData) => {
    return async (dispatch) => {
        const res = await axiosInstance()
            .post(path, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
        return res;
    };
};
