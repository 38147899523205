import { takeLatest, put, take } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import { FixedDepositConstant as ActionConstants, AccountHolderConstants } from "Actions/constants";
import {
    failed,
    getAllSchemes,
    getAll as getAccounts,
    getAllAccounts,
    deleteTransaction as deleteFdTransaction,
    getApprovalTransactions as fetchApprovalTransactions,
    getInterestPayble as fetchInterestPayble,
} from "Actions/Vendors/fixedDeposits.action";
import { getAll as getAllAcountHolders } from "Actions/Vendors/accountHolder.action";

import { watchTransactionDeleteRequest } from "./deleteTransaction.saga";

const endPoint = "/vendor/fixed-deposit";

const sagaWatchers = BaseSaga(endPoint, failed);

const getFDDependencies = function* (action) {
    yield put(getAllAcountHolders());
    yield take(AccountHolderConstants.GET_ALL_SUCCESS);
    yield put(getAllSchemes());
    yield take(ActionConstants.GET_ALL_FD_SCHEME_SUCCESS);
    yield put(getAllAccounts());
};

const getInterestPaybleDependencies = function* (action) {
    yield put(getAllSchemes());
    yield take(ActionConstants.GET_ALL_FD_SCHEME_SUCCESS);
    yield put(fetchInterestPayble({}));
};

const createAccount = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/accounts");
};

const updateAccount = function* (action) {
    yield sagaWatchers.update(action, endPoint + "/accounts/" + action.payload.id);
};

const getAllFDAccounts = function* (action) {
    yield sagaWatchers.getAll(action, endPoint + "/accounts");
};

const createScheme = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/scheme");
};

const updateScheme = function* (action) {
    yield sagaWatchers.update(action, endPoint + "/scheme");
};

const getSchemes = function* (action) {
    yield sagaWatchers.getAll(action, endPoint + "/scheme");
};

const createTransaction = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/transactions");
};

const getTransactions = function* (action) {
    yield sagaWatchers.getAll(
        action,
        endPoint + "/transactions/" + action.payload.id + "/" + action.payload.schemeId
    );
};

const getApprovalTransactions = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/get-approval-transactions");
};

const approveTransactions = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/approve-transactions");
};

const deleteApprovalTransactions = function* (action) {
    yield put(deleteFdTransaction({ ...action.payload }));
    yield take(ActionConstants.DELETE_FD_TRANSACTION_SUCCESS);
    yield put(
        fetchApprovalTransactions({
            schemeId: action.payload.objId,
            transactionDate: action.payload.transactionDate,
        })
    );
};

const calcInterestPayble = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/calc-interest-payble");
};

const updateInterestPayble = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/update-interest-payble");
};

const addInterestPayble = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/add-interest-payble");
};

const getInterestPaybles = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/get-interest-payble");
};

const getInterestPaybleDetails = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/get-interest-payble/details");
};

const undoLastInterestPayble = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/undo-last-interest-payble");
};

const getInterestDetails = function* (action) {
    yield sagaWatchers.getAll(action, endPoint + "/get-interest-details/" + action.payload.fdId);
};

const payInterest = function* (action) {
    yield sagaWatchers.post(action, endPoint + "/pay-interest");
};

const SagaActions = {
    ...sagaWatchers,
    createAccount,
    updateAccount,
    getAllFDAccounts,
    createScheme,
    updateScheme,
    createTransaction,
    getFDDependencies,
    getSchemes,
    getTransactions,
    getApprovalTransactions,
    approveTransactions,
    deleteApprovalTransactions,
    calcInterestPayble,
    updateInterestPayble,
    addInterestPayble,
    getInterestPaybleDependencies,
    getInterestPaybles,
    getInterestPaybleDetails,
    undoLastInterestPayble,
    getInterestDetails,
    payInterest,
};

export function* watchFDRequests() {
    yield takeLatest(ActionConstants.GET_FD_DEPENDEICIES, SagaActions.getFDDependencies);

    yield takeLatest(ActionConstants.CREATE_FD_ACCOUNT, SagaActions.createAccount);
    yield takeLatest(ActionConstants.UPDATE_FD_ACCOUNT, SagaActions.updateAccount);
    yield takeLatest(ActionConstants.GET_ALL_FD_ACCOUNT, SagaActions.getAllFDAccounts);

    yield takeLatest(ActionConstants.CREATE_FD_SCHEME, SagaActions.createScheme);
    yield takeLatest(ActionConstants.UPDATE_FD_SCHEME, SagaActions.updateScheme);
    yield takeLatest(ActionConstants.GET_ALL_FD_SCHEME, SagaActions.getSchemes);

    yield takeLatest(ActionConstants.CREATE_FD_TRANSACTION, SagaActions.createTransaction);
    yield takeLatest(ActionConstants.GET_FD_TRANSACTION, SagaActions.getTransactions);

    yield takeLatest(
        ActionConstants.GET_FD_APPROVAL_TRANSACTION,
        SagaActions.getApprovalTransactions
    );

    yield takeLatest(ActionConstants.APPROVE_FD_TRANSACTION, SagaActions.approveTransactions);
    yield takeLatest(
        ActionConstants.DELETE_FD_APPROVAL_TRANSACTION,
        SagaActions.deleteApprovalTransactions
    );

    yield takeLatest(ActionConstants.DELETE_FD_TRANSACTION, watchTransactionDeleteRequest);

    yield takeLatest(ActionConstants.CALCULATE_FD_INTEREST_PAYBLE, SagaActions.calcInterestPayble);

    yield takeLatest(ActionConstants.ADD_FD_INTEREST_PAYBLE, SagaActions.addInterestPayble);

    yield takeLatest(
        ActionConstants.GET_INTEREST_PAYBLE_DEFAULT,
        SagaActions.getInterestPaybleDependencies
    );
    yield takeLatest(ActionConstants.GET_INTEREST_PAYBLE, SagaActions.getInterestPaybles);

    yield takeLatest(
        ActionConstants.GET_INTEREST_PAYBLE_DETAILS,
        SagaActions.getInterestPaybleDetails
    );

    yield takeLatest(ActionConstants.UNDO_FD_INTEREST_PAYBLE, SagaActions.undoLastInterestPayble);

    yield takeLatest(ActionConstants.GET_FD_INTEREST_DETAILS, SagaActions.getInterestDetails);
    yield takeLatest(ActionConstants.PAY_FD_INTEREST, SagaActions.payInterest);
}
