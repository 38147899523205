import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/savingAccount.action";
import Scheme from "./SchemeDialog";
import AccountHolderOptions from "Views/Vendor/AccountHolders/AccountHolderOptions";
import DataTable from "Components/UI/DataTable";

const ListAccounts = () => {
    const stateObject = useSelector((state) => state.savingAccount);
    const accounts = stateObject.savingAccounts;
    const columns = [
        {
            dataField: "id",
            text: "Account No",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "scheme.schemeName",
            text: "Scheme",
            style: { textAlign: "center", paddingTop: "4px" },
            headerStyle: { textAlign: "center", paddingTop: "4px" },
        },
        {
            dataField: "accountHolder.name",
            text: "Name",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.mobile",
            text: "Mobile",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.nomineeName",
            text: "Nominee",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.nomineeRelation",
            text: "Relation",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.nomineeAge",
            text: "Age",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
    ];
    return (
        <Row>
            <Col md="12">
                <br />
                {accounts ? (
                    <DataTable
                        keyField="id"
                        columns={columns}
                        data={accounts}
                        search={true}
                        pagination={true}
                        button={false}
                        className="transaction-table"
                    />
                ) : (
                    ""
                )}
            </Col>
        </Row>
    );
};

const SavingAccount = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [schemeId, setSchemeId] = useState("");
    const [accountHolderId, setAccountHolderId] = useState("");

    const stateObject = useSelector((state) => state.savingAccount);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Actions.getSBDependencies());
    }, [dispatch]);

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        dispatch(Actions.create({ schemeId, accountHolderId }));
    };

    const resetForm = () => {
        setAlertStatus(true);
        setSchemeId("");
        setAccountHolderId("");
    };

    const pageNavItems = [
        {
            title: "New Account",
            active: false,
            to: "#",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Saving Bank Account">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={4}>
                                <Scheme
                                    schemeId={schemeId}
                                    setSchemeId={setSchemeId}
                                    setAlertStatus={setAlertStatus}
                                    errMessage={errors["schemeId"]}
                                />
                            </Col>
                            <Col md={4}>
                                <AccountHolderOptions
                                    accountHolderId={accountHolderId}
                                    setAccountHolderId={setAccountHolderId}
                                    setAlertStatus={setAlertStatus}
                                    errMessage={errors["accountHolderId"]}
                                />
                            </Col>
                            <Col md="4">
                                <br />
                                <Button variant="primary" type="Submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={resetForm} type="Button">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col md={12}>
                            <ListAccounts />
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default SavingAccount;
