export const adminNav = [
    {
        title: "Dashboard",
        itemId: "/",
        elemBefore: () => <i className="fa fa-tachometer"></i>,
    },
    {
        title: "Databases",
        itemId: "/tenants",
        elemBefore: () => <i className="fa fa-database" aria-hidden="true"></i>,
    },
    {
        title: "Society",
        itemId: "/society",
        elemBefore: () => <i className="fa fa-bank" aria-hidden="true"></i>,
    },
    {
        title: "Users",
        itemId: "/admin/users",
        elemBefore: () => <i className="fa fa-user" aria-hidden="true"></i>,
    },

    {
        title: "Reports",
        itemId: "/units",
        elemBefore: () => <i className="fa fa-tasks" aria-hidden="true"></i>,
    },
];

export const vendorNav = [
    {
        title: "Dashboard",
        itemId: "/",
        elemBefore: () => <i className="fa fa-tachometer"></i>,
    },
    {
        title: "Day Begin / End",
        itemId: "/day-begin-end",
        elemBefore: () => <i className="fa fa-tachometer"></i>,
    },
    {
        title: "Users",
        itemId: "/users",
        elemBefore: () => <i className="fa fa-user" aria-hidden="true"></i>,
    },
    {
        title: "Account Holder",
        itemId: "/account-holders",
        elemBefore: () => <i className="fa fa-user-plus" aria-hidden="true"></i>,
    },
    {
        title: "Approve Deleted",
        itemId: "/deleted-transaction-approval",
        elemBefore: () => <i className="fa fa-check" aria-hidden="true"></i>,
    },
    {
        title: "Bank Accounts",
        itemId: "#id_bankAccounts",
        elemBefore: () => <i className="fa fa-bank" aria-hidden="true"></i>,
        subNav: [
            {
                title: "Transaction",
                itemId: "/bank-accounts/transaction",
                elemBefore: () => <i className="fa fa-exchange" aria-hidden="true"></i>,
            },
            {
                title: "Approve Transaction",
                itemId: "/bank-accounts/approve-transactions",
                elemBefore: () => <i className="fa fa-check" aria-hidden="true"></i>,
            },
        ],
    },
    {
        title: "Pigmy Accounts",
        itemId: "#id_pigmy-account",
        elemBefore: () => <i className="fa fa-credit-card" aria-hidden="true"></i>,
        subNav: [
            {
                title: "New Account",
                itemId: "/pigmy-account/add",
                elemBefore: () => <i className="fa fa-address-card-o" aria-hidden="true"></i>,
            },
            {
                title: "View Accounts",
                itemId: "/pigmy-account/details",
                elemBefore: () => <i className="fa fa-list-alt" aria-hidden="true"></i>,
            },
            {
                title: "Transaction",
                itemId: "/pigmy-account/transactions",
                elemBefore: () => <i className="fa fa-exchange" aria-hidden="true"></i>,
            },
            {
                title: "Approve Transaction",
                itemId: "/pigmy-account/approve-transactions",
                elemBefore: () => <i className="fa fa-check" aria-hidden="true"></i>,
            },
        ],
    },
    {
        title: "Saving Accounts",
        itemId: "/#id_sb-account",
        elemBefore: () => <i className="fa fa-credit-card" aria-hidden="true"></i>,
        subNav: [
            {
                title: "New Account",
                itemId: "/sb-account",
                elemBefore: () => <i className="fa fa-address-card-o" aria-hidden="true"></i>,
            },
            {
                title: "Transaction",
                itemId: "/sb-account/transactions",
                elemBefore: () => <i className="fa fa-exchange" aria-hidden="true"></i>,
            },
            {
                title: "Approve Transaction",
                itemId: "/sb-account/transactions/approve",
                elemBefore: () => <i className="fa fa-check" aria-hidden="true"></i>,
            },
            {
                title: "Interest Details",
                itemId: "/sb-transactions/interest-details",
                elemBefore: () => <i className="fa fa-adjust" aria-hidden="true"></i>,
            },
        ],
    },
    {
        title: "Current Accounts",
        itemId: "/#id_current-account",
        elemBefore: () => <i className="fa fa-credit-card" aria-hidden="true"></i>,
        subNav: [
            {
                title: "New Account",
                itemId: "/current-account",
                elemBefore: () => <i className="fa fa-address-card-o" aria-hidden="true"></i>,
            },
            {
                title: "Transaction",
                itemId: "/current-account/transactions",
                elemBefore: () => <i className="fa fa-exchange" aria-hidden="true"></i>,
            },
            {
                title: "Approve Transaction",
                itemId: "/current-account/transactions/approve",
                elemBefore: () => <i className="fa fa-check" aria-hidden="true"></i>,
            },
        ],
    },
    {
        title: "Fixed Deposits",
        itemId: "/#id_fixed-deposits",
        elemBefore: () => <i className="fa fa-credit-card" aria-hidden="true"></i>,
        subNav: [
            {
                title: "Accounts",
                itemId: "/fixed-deposits/accounts",
                elemBefore: () => <i className="fa fa-address-card-o" aria-hidden="true"></i>,
            },
            {
                title: "Transaction",
                itemId: "/fixed-deposits/transactions",
                elemBefore: () => <i className="fa fa-exchange" aria-hidden="true"></i>,
            },
            {
                title: "Approve Transaction",
                itemId: "/fixed-deposits/approve-transactions",
                elemBefore: () => <i className="fa fa-check" aria-hidden="true"></i>,
            },
            {
                title: "Interest Payble",
                itemId: "/fixed-deposits/interest-details",
                elemBefore: () => <i className="fa fa-adjust" aria-hidden="true"></i>,
            },
        ],
    },
    {
        title: "Loan Accounts",
        itemId: "/loan-accounts/create",
        elemBefore: () => <i className="fa fa-money" aria-hidden="true"></i>,
    },
];
