import { FixedDepositConstant as ActionConstant } from "Actions/constants";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    message: "",
    schemes: [],
    scheme: null,
    transactions: [],
    transaction: null,
    approvalTransactions: [],
    balance: 0,
    accounts: [],
    account: [],
    lastInterestPaid: null,
    interestDetails: [],
    interestTransactions: [],
    interestPaybles: [],
    interestPaybleDetails: [],
    totalInterest: 0,
    totalInterestPayble: 0,
    interestPaidDetails: [],
};
let selectedTransactions = [];

const FixedDepositReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };

    switch (action.type) {
        case ActionConstant.GET_ALL_FD_ACCOUNT:
            state = {
                ...state,
                PROCESSING: true,
                transactions: [],
                transaction: null,
                balance: 0,
                account: [],
            };
            break;

        case ActionConstant.CREATE_FD_ACCOUNT:
        case ActionConstant.UPDATE_FD_ACCOUNT:
        case ActionConstant.GET_FD_ACCOUNT:
        case ActionConstant.GET_ALL_FD_ACCOUNT:
        case ActionConstant.CREATE_FD_SCHEME:
        case ActionConstant.UPDATE_FD_SCHEME:
        case ActionConstant.GET_FD_SCHEME:
        case ActionConstant.GET_ALL_FD_SCHEME:
        case ActionConstant.CREATE_FD_TRANSACTION:
        case ActionConstant.GET_FD_APPROVAL_TRANSACTION:
        case ActionConstant.DELETE_FD_APPROVAL_TRANSACTION:
        case ActionConstant.DELETE_FD_TRANSACTION:
            state = {
                ...state,
                PROCESSING: true,
                transactions: [],
                transaction: null,
                balance: 0,
                account: [],
            };
            break;

        case ActionConstant.GET_FD_INTEREST_DETAILS:
        case ActionConstant.CALCULATE_FD_INTEREST_PAYBLE:
            state = {
                ...state,
                PROCESSING: true,
                interestPaidDetails: [],
                lastInterestPaid: null,
                interestPayble: 0,
            };
            break;

        case ActionConstant.CALCULATE_FD_INTEREST_PAYBLE:
            state = {
                ...state,
                PROCESSING: true,
                interestPaybles: [],
                totalInterest: 0,
                totalInterestPayble: 0,
            };
            break;

        case ActionConstant.CREATE_FD_SCHEME_SUCCESS:
        case ActionConstant.UPDATE_FD_SCHEME_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                schemes: action.payload.schemes,
                message: action.payload.message,
            };
            break;

        case ActionConstant.GET_ALL_FD_SCHEME_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                schemes: action.payload.schemes,
            };
            break;

        case ActionConstant.CREATE_FD_ACCOUNT_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                account: action.payload.account,
                accounts: action.payload.accounts,
            };
            break;

        case ActionConstant.GET_ALL_FD_ACCOUNT_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                accounts: action.payload.accounts,
            };
            break;

        case ActionConstant.CREATE_FD_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                transaction: action.payload.transaction,
                transactions: action.payload.transactions,
            };
            break;

        case ActionConstant.GET_FD_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                account: action.payload.account,
                transactions: action.payload.transactions,
                balance: action.payload.balance,
            };
            break;

        case ActionConstant.GET_FD_APPROVAL_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                approvalTransactions: action.payload.approvalTransactions,
            };
            break;

        case ActionConstant.APPROVE_FD_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                approvalTransactions: action.payload.approvalTransactions,
                message: action.payload.message,
            };
            break;

        case ActionConstant.DELETE_FD_APPROVAL_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;

        case ActionConstant.DELETE_FD_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                transactions: action.payload.transactions,
                balance: action.payload.balance,
            };
            break;

        case ActionConstant.SET_SELECTED_FD_TRANSACTIONS:
            selectedTransactions = state.approvalTransactions.map((item) => {
                return {
                    ...item,
                    selected: true,
                };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case ActionConstant.SET_SELECTED_FD_TRANSACTION:
            selectedTransactions = state.approvalTransactions.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        selected: action.payload.selected,
                    };
                }
                return { ...item };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case ActionConstant.GET_INTEREST_PAYBLE:
        case ActionConstant.UNDO_FD_INTEREST_PAYBLE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;

        case ActionConstant.GET_INTEREST_PAYBLE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                interestDetails: action.payload.interestDetails
                    ? action.payload.interestDetails
                    : [],
            };
            break;

        case ActionConstant.GET_INTEREST_PAYBLE_DETAILS:
            state = {
                ...state,
                PROCESSING: true,
                interestPaybleDetails: [],
            };
            break;

        case ActionConstant.GET_INTEREST_PAYBLE_DETAILS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                interestPaybleDetails: action.payload.interestPaybles,
            };
            break;

        case ActionConstant.UNDO_FD_INTEREST_PAYBLE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                interestDetails: action.payload.interestDetails
                    ? action.payload.interestDetails
                    : [],
                message: action.payload.message,
            };
            break;

        case ActionConstant.CALCULATE_FD_INTEREST_PAYBLE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                interestPaybles: action.payload.interestDetails,
                totalInterest: action.payload.totalInterest,
                totalInterestPayble: action.payload.totalInterestPayble,
                interestDate: action.payload.interestDate,
            };
            break;

        case ActionConstant.ADD_FD_INTEREST_PAYBLE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;

        case ActionConstant.ADD_FD_INTEREST_PAYBLE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                interestDetails: action.payload.interestDetails
                    ? action.payload.interestDetails
                    : [],
            };
            break;

        case ActionConstant.GET_FD_INTEREST_DETAILS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                interestPaidDetails: action.payload.interestDetails,
                lastInterestPaid: action.payload.lastInterestPaid,
                interestPayble: action.payload.interestPayble,
            };
            break;

        case ActionConstant.PAY_FD_INTEREST_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                interestPaidDetails: action.payload.interestDetails,
                message: action.payload.message,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;

        default:
            return state;
    }
    return state;
};

export default FixedDepositReducer;
