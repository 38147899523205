import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthenticatedUser } from "Actions/auth";
const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
    role: state.auth.user.role,
});

//HOC is used for routes that require login
// rest  --> Rest of the properties
const AdminRoutes = ({ authenticated, role, component: Component, ...rest }) => {
    const user = getAuthenticatedUser();
    return (
        <Route
            {...rest}
            component={(props) => {
                if (!user) {
                    return <Redirect to={`/signin`} />;
                } else if (user.role === "admin") {
                    //if logged in return component
                    return <Component {...props} />;
                } else {
                    return <Redirect to={`/forbidden`} />;
                }
            }}
        />
    );
};
export default connect(mapStateToProps)(AdminRoutes);
