import { ApproveDeletedConstants as ActionConstant } from "Actions/constants";

const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    transactions: {},
    message: "",
};
let selectedTransactions = [];

const DeletedTransactionReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.GET_DELETED_TRANSACTIONS:
            state = {
                ...state,
                transactions: {},
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_DELETED_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                transactions: action.payload.transactions,
                REQUEST_SUCCESS: true,
            };
            break;
        case ActionConstant.SET_SELECTED_APPROVAL_TRANSACTIONS:
            selectedTransactions = state.transactions.map((item) => {
                return {
                    ...item,
                    selected: true,
                };
            });
            state = {
                ...state,
                transactions: selectedTransactions,
            };
            break;
        case ActionConstant.SET_SELECTED_APPROVAL_TRANSACTION:
            selectedTransactions = state.transactions.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        selected: action.payload.selected,
                    };
                }
                return { ...item };
            });
            state = {
                ...state,
                transactions: selectedTransactions,
            };
            break;
        case ActionConstant.DELETE_TRANSACTION:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.DELETE_TRANSACTION_SUCCESS:
            state = {
                ...state,
                transactions: action.payload.transactions,
                REQUEST_SUCCESS: true,
                message: action.payload.mess,
            };
            break;
        case ActionConstant.APPROVE_DELETED_TRANSACTIONS:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.APPROVE_DELETED_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                transactions: action.payload.transactions,
            };
            break;
        case ActionConstant.DELETE_TRANSACTION_ERROR:
            console.log(action.message);
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default DeletedTransactionReducer;
