import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "Components/Layout";
import * as UserActions from "Actions/Admin/user.action";
import { Card, Row, Col, Button } from "react-bootstrap";
import DataTable from "Components/UI/DataTable";
import { Link } from "react-router-dom";
import { StatusBadge, HeadOfficeBadge } from "Components/UI/Input";
import { formatDateInTable } from "Helpers/utils";
import RequestStatus from "Helpers/RequestStatus";

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus: true,
        };
    }

    setAlertStatus = (status) => {
        this.setState({ alertStatus: status });
    };

    formatDate = (cell, row) => {
        return this.props.formatDate(row.createdAt);
    };

    isHeadOffice = (cell, row) => {
        return <HeadOfficeBadge headOffice={row.headOffice} />;
    };

    showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    removeUser = (id, tenantId, uuid) => {
        this.props.actions.remove({ id, tenantId, uuid });
    };

    actionButtons = (cell, row) => {
        return (
            <>
                <Button variant="link" as={Link} to={`/users/${row.id}`} className="btn btn-sm">
                    <i className="fa fa-edit fa-lg" aria-hidden="true" />
                </Button>
                <Button
                    variant="link"
                    onClick={() => {
                        this.removeUser(row.id, row.tenantId, row.uuid);
                    }}
                >
                    <i className="fa fa-trash fa-lg" aria-hidden="true" />
                </Button>
            </>
        );
    };

    componentDidMount() {
        this.props.actions.getAll();
    }

    render() {
        //Nav Items
        const pageNavItems = [
            {
                title: "User",
                active: false,
                to: "/users",
            },
        ];
        const columns = [
            {
                dataField: "name",
                text: "Name",
                style: { textAlign: "center" },
                headerStyle: { textAlign: "center" },
            },
            {
                dataField: "mobile",
                text: "Mobile",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "role",
                text: "Role",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "email",
                text: "Email",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "tenantId",
                text: "DB ID",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "status",
                text: "Status",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.showBadge,
            },
            {
                dataField: "createdAt",
                text: "Created at",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: formatDateInTable,
            },
            {
                dataField: "#",
                text: "Action",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.actionButtons,
            },
        ];
        return (
            <Layout pageNavItems={pageNavItems} pageTitle="User">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <DataTable
                                    keyField="id"
                                    columns={columns}
                                    data={this.props.stateObject.users}
                                    search={true}
                                    pagination={true}
                                    button={true}
                                    buttonTitle="Create User"
                                    buttonURI="/users/add"
                                    buttonVariant="success"
                                />
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={this.props.stateObject}
                                alertStatus={this.state.alertStatus}
                                setAlertStatus={this.setAlertStatus}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.user.users,
    error: state.user.error,
    stateObject: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
