import { takeLatest, call, put } from "redux-saga/effects";
import * as REST from "Services/rest.service";
import { logout } from "Actions/auth";
import { TOKEN_EXPIRED } from "Actions/constants";
import BaseSaga from "../base.saga";
import { TenantConstants } from "Actions/constants";
import { failed } from "Actions/Admin/tenant.action";

const endPoint = "/admin/tenants";

const sagaWatchers = BaseSaga(endPoint, failed);
const TenantSaga = {
    ...sagaWatchers,
};
function* migrateDB(action) {
    try {
        let response = yield call(REST.post("/admin/migrate/" + action.payload.id, action.payload));
        if (!response) {
            let err = new Error("Request Failed");
            err.status = 500;
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        console.log(err);
        yield put(failed({ err }));
    }
}

export function* watchTenantRequests() {
    yield takeLatest(TenantConstants.CREATE, TenantSaga.create);
    yield takeLatest(TenantConstants.UPDATE, TenantSaga.update);
    yield takeLatest(TenantConstants.DELETE, TenantSaga.remove);
    yield takeLatest(TenantConstants.GET_ALL, TenantSaga.getAll);
    yield takeLatest(TenantConstants.GET, TenantSaga.getOne);
    yield takeLatest(TenantConstants.DB_MIGRATE, migrateDB);
}
