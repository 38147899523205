import { CurrentAccountConstants as ActionConstant } from "Actions/constants";
const initState = {
    errors: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    accountTransaction: {},
    approvalTransactions: [],
    message: "",
    schemes: [],
    scheme: null,
    transactions: [],
    transaction: null,
    approvalTransactions: [],
    balance: 0,
    currentAccount: {},
    currentAccounts: [],
};
let selectedTransactions = [];

const CurrentAccountReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
        balance: 0,
    };

    switch (action.type) {
        case ActionConstant.CREATE:
        case ActionConstant.UPDATE:
        case ActionConstant.GET:
        case ActionConstant.GET_ALL:
        case ActionConstant.CREATE_CURRENT_ACCOUNT_SCHEME:
        case ActionConstant.CREATE_CURRENT_ACCOUNT_TRANSACTION:
        case ActionConstant.DELETE_CURRENT_ACCOUNT_TRANSACTION:
        case ActionConstant.GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION:
        case ActionConstant.UPDATE_CURRENT_ACCOUNT_SCHEME:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;

        case ActionConstant.GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS:
        case ActionConstant.GET_ALL_CURRENT_ACCOUNT_SCHEMES:
            state = {
                ...state,
                PROCESSING: true,
                transactions: [],
                transaction: null,
                approvalTransactions: [],
                balance: 0,
                currentAccount: {},
            };
            break;

        case ActionConstant.CREATE_CURRENT_ACCOUNT_SCHEME_SUCCESS:
        case ActionConstant.UPDATE_CURRENT_ACCOUNT_SCHEME_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                schemes: action.payload.schemes,
                message: action.payload.message,
            };
            break;

        case ActionConstant.GET_ALL_CURRENT_ACCOUNT_SCHEMES_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                schemes: action.payload.schemes,
            };
            break;

        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                currentAccount: action.payload.account,
                currentAccounts: action.payload.accounts,
            };
            break;

        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                currentAccounts: action.payload.accounts,
            };
            break;

        case ActionConstant.CREATE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                transaction: action.payload.transaction,
                transactions: action.payload.transactions,
                message: action.payload.message,
            };
            break;

        case ActionConstant.GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                currentAccount: action.payload.account,
                transactions: action.payload.account.transactions,
                balance: action.payload.account.balance,
            };
            break;

        case ActionConstant.DELETE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                transactions: action.payload.transactions,
            };
            break;

        case ActionConstant.GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                approvalTransactions: action.payload.approvalTransactions,
                message: action.payload.message,
            };
            break;
        case ActionConstant.APPROVE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                approvalTransactions: action.payload.approvalTransactions,
                message: action.payload.message,
            };
            break;

        case ActionConstant.SET_SELECTED_CURRENT_ACCOUNT_TRANSACTIONS:
            selectedTransactions = state.approvalTransactions.map((item) => {
                return {
                    ...item,
                    selected: true,
                };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case ActionConstant.SET_SELECTED_CURRENT_ACCOUNT_TRANSACTION:
            selectedTransactions = state.approvalTransactions.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        selected: action.payload.selected,
                    };
                }
                return { ...item };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default CurrentAccountReducer;
