import getActions from "Actions/base.action";
import { LoanAccountConstants } from "../constants";

const baseActions = getActions(LoanAccountConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getLoanCategories = () => {
    return {
        type: LoanAccountConstants.GET_LOAN_ACCOUNT_CATEGORY,
        success: LoanAccountConstants.GET_LOAN_ACCOUNT_CATEGORY_SUCCESS,
        payload: null,
    };
};

const createScheme = (data) => {
    return {
        type: LoanAccountConstants.CREATE_LOAN_SCHEME,
        success: LoanAccountConstants.CREATE_LOAN_SCHEME_SUCCESS,
        payload: data,
    };
};

const getLoanSchemes = () => {
    return {
        type: LoanAccountConstants.GET_LOAN_SCHEMES,
        success: LoanAccountConstants.GET_LOAN_SCHEMES_SUCCESS,
        payload: null,
    };
};

const getLoanAccountNumber = () => {
    return {
        type: LoanAccountConstants.GET_LOAN_ACCOUNT_NUMBER,
        success: LoanAccountConstants.GET_LOAN_ACCOUNT_NUMBER_SUCCESS,
        payload: null,
    };
};

const getLoanAccountDependencies = () => {
    return {
        type: LoanAccountConstants.GET_LOAN_ACCOUNT_DEPENDENCIES,
        success: LoanAccountConstants.GET_LOAN_ACCOUNT_DEPENDENCIES_SUCCESS,
        payload: null,
    };
};

const addPledgeItem = (data) => {
    return {
        type: LoanAccountConstants.ADD_PLEDGE_ITEM,
        payload: data,
    };
};
const removePledgeItem = (data) => {
    return {
        type: LoanAccountConstants.REMOVE_PLEDGE_ITEM,
        payload: data,
    };
};

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    failed,
    getLoanCategories,
    createScheme,
    getLoanSchemes,
    getLoanAccountDependencies,
    getLoanAccountNumber,
    addPledgeItem,
    removePledgeItem,
};
