import React from "react";
import moment from "moment";
import { Row, Col, Modal } from "react-bootstrap";
import * as Utils from "Helpers/utils";
import DataTable from "Components/UI/DataTable";

const calculateCompundInterestEMI = (props) => {
    if (!props.issueDate || !props.installments || !props.amountSanctioned) {
        return [];
    }
    let dataObj = [];
    let date = props.issueDate;
    const installments = props.installments;
    const amountSanctioned = props.amountSanctioned;
    let balance = amountSanctioned;
    let emi = Math.round(amountSanctioned / installments);

    const totalDays = Utils.getNoOfDays(date, installments);

    const compoundInterest = Math.round(
        (((amountSanctioned * 12) / 100 / 365) * totalDays) / installments
    );
    props.setInstallmentAmount(Math.round(compoundInterest + emi));
    for (let i = 1; i <= installments; i++) {
        date = moment(date).add(1, "month").calendar();
        date = moment(date).format("YYYY-MM-DD");
        //console.log(date + "\t\t" + moment(date, "YYYY-MM-DD").daysInMonth());
        const noOfDays = moment(date, "YYYY-MM-DD").daysInMonth();

        balance -= emi;
        let total = Math.round(compoundInterest + emi);

        dataObj = [
            ...dataObj,
            {
                id: i,
                date: moment(date).format("YYYY-MM-DD"),
                days: noOfDays,
                emi,
                interest: compoundInterest,
                total,
                balance,
            },
        ];
    }
    return dataObj;
};

const caluclateEMI = (props) => {
    if (!props.scheme || !props.issueDate || !props.installments || !props.amountSanctioned) {
        return [];
    }
    let dataObj = [];

    let date = props.issueDate;
    const installments = props.installments;

    const amountSanctioned = props.amountSanctioned;
    let balance = amountSanctioned;

    const interestRate = props.scheme.interestRate;
    const monthlyInterestRatio = interestRate / 100 / 12;
    const top = Math.pow(1 + monthlyInterestRatio, installments);
    const bottom = top - 1;
    const sp = top / bottom;
    let emi = Math.round(amountSanctioned * monthlyInterestRatio * sp);
    //let full = installments * emi;
    props.setInstallmentAmount(emi);
    let int_dd = 0;
    let pre_dd = 0;
    let end_dd = 0;
    for (let j = 1; j <= installments; j++) {
        date = moment(date).add(1, "months").calendar();
        int_dd = balance * (interestRate / 100 / 12);
        pre_dd = Math.round(emi) - Math.round(int_dd);
        end_dd = Math.abs(balance - pre_dd);

        dataObj = [
            ...dataObj,
            {
                id: j,
                date: moment(date).format("YYYY-MM-DD"),
                beginingBalance: balance,
                emi,
                principal: pre_dd,
                interest: int_dd,
                endingBalance: end_dd,
            },
        ];
        balance -= pre_dd;
    }
    return dataObj;
};

const compoundInterestColoumns = () => {
    return [
        {
            dataField: "date",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "days",
            text: "Days",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "emi",
            text: "EMI",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },

        {
            dataField: "interest",
            text: "Interest",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "total",
            text: "Total",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },

        {
            dataField: "balance",
            text: "Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
    ];
};

const regulatInterestColoumns = () => {
    return [
        {
            dataField: "date",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "beginingBalance",
            text: "Begining Balance",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "emi",
            text: "EMI",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "principle",
            text: "Principle",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "interest",
            text: "Interest",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "endingBalance",
            text: "Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
    ];
};
const EMIDialog = (props) => {
    const data =
        props.scheme && props.scheme.compoundInterest
            ? calculateCompundInterestEMI(props)
            : caluclateEMI(props);
    let columns =
        props.scheme && props.scheme.compoundInterest
            ? compoundInterestColoumns()
            : regulatInterestColoumns();
    return (
        <Modal show={props.emiDialog} onHide={props.setEMIDialog} top="true" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>EMI</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        {data ? (
                            <DataTable
                                keyField="id"
                                columns={columns}
                                data={data}
                                search={false}
                                pagination={false}
                                button={false}
                            />
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default EMIDialog;
