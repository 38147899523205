import { all } from "redux-saga/effects";
import { watchAuthRequests } from "./auth.saga";

//Admin Sagas
import { watchTenantRequests } from "./Admin/tenant.saga";
import { watchSocietyRequests } from "./Admin/society.saga";
import { watchUserRequests } from "./Admin/user.saga";

//Vendor Sagas
import { watchAccountHolderRequests } from "./Vendors/accountHolder.saga";
import { watchPigmyAccountRequests } from "./Vendors/pigmyAccount.saga";
import { watchApproveDeletedRequest } from "./Vendors/deleteTransaction.saga";
import { watchLoanAccountsRequests } from "./Vendors/loanAccount.saga";
import { watchBankAccountRequest } from "./Vendors/bankAccounts.saga";
import { watchDayBeginEndRequests } from "./Vendors/dayBeginEnd.saga";
import { watchSavingAccountRequests } from "./Vendors/savingAccount.saga";
import { watchCurrentAccountRequests } from "./Vendors/currentAccount.saga";
import { watchFDRequests } from "./Vendors/fixedDeposits.saga";

export default function* rootSaga() {
    yield all([
        //Auth
        watchAuthRequests(),
        //Tenant Request (Vendors)
        watchTenantRequests(),
        //Societies
        watchSocietyRequests(),
        //Society Users
        watchUserRequests(),
        //Account Holders
        watchAccountHolderRequests(),
        //Pigmy Accounts
        watchPigmyAccountRequests(),
        //Generic Delete functionality
        watchApproveDeletedRequest(),
        //Loans
        watchLoanAccountsRequests(),
        //Bank Accounts
        watchBankAccountRequest(),
        //Day Begin/End Requests
        watchDayBeginEndRequests(),
        //Saving Account Requests
        watchSavingAccountRequests(),
        //Current Accounts Requests
        watchCurrentAccountRequests(),
        //Fixed Deposit Requests
        watchFDRequests(),
    ]);
}
