import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "Components/Layout";
import * as TenantAction from "Actions/Admin/tenant.action";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import { Input } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

class Tenant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            dbName: "",
            dbHost: "",
            dbUsername: "",
            dbPassword: "",
            dbPort: "",
            status: false,
            alertStatus: true,
        };
    }

    setAlertStatus = (status) => {
        this.setState({ alertStatus: status });
    };

    handleCheckboxChange = (event) => {
        const setStatus = !this.state.status;
        this.setState({ status: setStatus });
    };

    handleInputChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
        });
    };

    getStateprops = () => {
        return {
            dbName: this.state.dbName,
            dbHost: this.state.dbHost,
            dbUsername: this.state.dbUsername,
            dbPassword: this.state.dbPassword,
            dbPort: this.state.dbPort,
            status: this.state.status ? 1 : 0,
        };
    };

    create = (e) => {
        e.preventDefault();
        this.setState({ alertStatus: true });
        this.props.actions.create(this.getStateprops());
    };

    update = (e) => {
        e.preventDefault();
        const stateProps = this.getStateprops();
        this.props.actions.update({ id: this.state.id, ...stateProps });
    };

    componentDidMount() {
        let id = this.props.match.params.id;
        if (!id) {
            id = 0;
        }
        if (id !== 0) {
            this.props.actions.getOne({ id });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.stateObject.tenant && nextProps.stateObject.tenant.id !== this.state.id) {
            this.setState({
                ...nextProps.stateObject.tenant,
                status: nextProps.stateObject.tenant.status === 1 ? true : false,
            });
        }
    }

    resetform = () => {
        this.setState({
            id: 0,
            dbName: "",
            dbHost: "",
            dbUsername: "",
            dbPassword: "",
            dbPort: "",
            status: 0,
            alertStatus: true,
        });
    };

    render() {
        //Nav Items
        const pageNavItems = [
            {
                title: "Databases",
                active: true,
                to: "/tenants",
            },
            {
                title: "Create",
                active: false,
                to: "",
            },
        ];

        return (
            <Layout pageNavItems={pageNavItems} pageTitle="Create Database">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <Form onSubmit={this.state.id !== 0 ? this.update : this.create}>
                                    <Row>
                                        <Col md="4">
                                            <Input
                                                label="Database Name"
                                                name="dbName"
                                                value={this.state.dbName}
                                                placeholder="Enter database name"
                                                errMessage={this.props.errors["dbName"]}
                                                type="text"
                                                controlId="formdbName"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input
                                                label="Database Host"
                                                name="dbHost"
                                                value={this.state.dbHost}
                                                placeholder="Enter database host name"
                                                errMessage={this.props.errors["dbHost"]}
                                                type="text"
                                                controlId="fromdbHost"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input
                                                label="Database Username"
                                                name="dbUsername"
                                                value={this.state.dbUsername}
                                                placeholder="Enter database username"
                                                errMessage={this.props.errors["dbUsername"]}
                                                type="text"
                                                controlId="formdbUsername"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Input
                                                label="Database Password"
                                                name="dbPassword"
                                                value={this.state.dbPassword}
                                                placeholder="Enter database password"
                                                errMessage={this.props.errors["dbPassword"]}
                                                type="password"
                                                controlId="formdbPassword"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Input
                                                label="Database Port"
                                                name="dbPort"
                                                value={this.state.dbPort}
                                                placeholder="Enter database port"
                                                errMessage={this.props.errors["dbPort"]}
                                                type="text"
                                                controlId="formdbPort"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md="2" className="mt-5px">
                                            <br />

                                            <BootstrapSwitchButton
                                                checked={this.state.status}
                                                onstyle="outline-success"
                                                offstyle="outline-danger"
                                                onlabel="Active"
                                                offlabel="InActive"
                                                onChange={(checked) => {
                                                    this.setState({ status: checked });
                                                }}
                                            />
                                        </Col>
                                        <Col md="4" className="mt-5px">
                                            <br />
                                            <Button variant="primary" type="Submit">
                                                Submit
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                variant="secondary"
                                                onClick={this.resetform}
                                                type="Button"
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-5">
                                        <RequestStatus
                                            stateObj={this.props.stateObject}
                                            alertStatus={this.state.alertStatus}
                                            setAlertStatus={this.setAlertStatus}
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps = (state, props) => {
    let errorObj = [];
    if (typeof state.tenants.errors !== "undefined") {
        state.tenants.errors.map((item) => (errorObj[item.param] = item.msg));
    }
    return { errors: errorObj, stateObject: state.tenants, tenant: null };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(TenantAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tenant);
