//Admin Imports
import { Society, SocietyDetails } from "Views/Admin/Society";
import { Tenant, TenantDetails } from "Views/Admin/Tenant";
import { User, UserDetails } from "Views/Admin/User";

//Vendor Imports
import {
    AccountHolder,
    AccountHolderDetails,
    AccountHolderDetail,
} from "Views/Vendor/AccountHolders";
import ApproveDeletedTransaction from "Views/Vendor/ApproveDeletedTransaction";
import { LoanAccount } from "Views/Vendor/Loan";

import {
    PigmyAccount,
    PigmyAccountDetails,
    PigmyTransaction,
    PigmyApproveTransaction,
} from "Views/Vendor/Pigmy";

import { BankTransaction, ApproveBankTransaction } from "Views/Vendor/BankAccounts";

import DayBeginEnd from "Views/Vendor/DayBeginEnd";

import {
    SavingAccount,
    Transaction as SBTransaction,
    ApproveTransaction as SBTransactionApprove,
} from "Views/Vendor/SavingAccount";

import {
    CurrentAccount,
    Transaction as CATransaction,
    ApproveTransaction as CATransactionApprove,
} from "Views/Vendor/CurrentAccount";
import {
    FDAccount,
    FDTransaction,
    FDApproveTransactions,
    FDInterestDetails,
} from "Views/Vendor/FixedDeposit";

export const adminRoutes = [
    {
        path: "/tenants",
        exact: "exact",
        component: TenantDetails,
    },
    {
        path: "/tenants/add",
        exact: "exact",
        component: Tenant,
    },
    {
        path: "/tenants/:id",
        exact: "exact",
        component: Tenant,
    },
    {
        path: "/society",
        exact: "exact",
        component: SocietyDetails,
    },
    {
        path: "/society/add",
        exact: "exact",
        component: Society,
    },
    {
        path: "/society/:id",
        exact: "exact",
        component: Society,
    },
    {
        path: "/admin/users",
        exact: "exact",
        component: UserDetails,
    },
    {
        path: "/users/add",
        exact: "exact",
        component: User,
    },
    {
        path: "/users/add/society/:societyId/:tenantId",
        exact: "exact",
        component: User,
    },
    {
        path: "/users/:id",
        exact: "exact",
        component: User,
    },
];

export const managerRoutes = [
    {
        path: "/day-begin-end",
        exact: "exact",
        component: DayBeginEnd,
    },
    {
        path: "/users",
        exact: "exact",
        component: UserDetails,
    },
    {
        path: "/users/add",
        exact: "exact",
        component: User,
    },
    {
        path: "/users/:id",
        exact: "exact",
        component: User,
    },
    {
        path: "/account-holders",
        exact: "exact",
        component: AccountHolderDetails,
    },
    {
        path: "/account-holders/add",
        exact: "exact",
        component: AccountHolder,
    },
    {
        path: "/account-holders/:id",
        exact: "exact",
        component: AccountHolder,
    },
    {
        path: "/account-holders/:id/details",
        exact: "exact",
        component: AccountHolderDetail,
    },
    {
        path: "/pigmy-account",
        exact: "exact",
        component: PigmyAccountDetails,
    },
    //Pigmy Accounts Route
    {
        path: "/pigmy-account/transactions",
        exact: "exact",
        component: PigmyTransaction,
    },
    {
        path: "/pigmy-account/approve-transactions",
        exact: "exact",
        component: PigmyApproveTransaction,
    },
    {
        path: "/pigmy-account/details",
        exact: "exact",
        component: PigmyAccountDetails,
    },
    {
        path: "/pigmy-account/add",
        exact: "exact",
        component: PigmyAccount,
    },
    {
        path: "/pigmy-account/:id",
        exact: "exact",
        component: PigmyAccount,
    },
    {
        path: "/deleted-transaction-approval",
        exact: "exact",
        component: ApproveDeletedTransaction,
    },
    //Loan Account Routes
    {
        path: "/loan-accounts/create",
        exact: "exact",
        component: LoanAccount,
    },

    //Bank Accounts
    {
        path: "/bank-accounts",
        exact: "exact",
        component: BankTransaction,
    },
    {
        path: "/bank-accounts/transaction",
        exact: "exact",
        component: BankTransaction,
    },
    {
        path: "/bank-accounts/approve-transactions",
        exact: "exact",
        component: ApproveBankTransaction,
    },

    //Saving Accounts
    {
        path: "/sb-account",
        exact: "exact",
        component: SavingAccount,
    },
    {
        path: "/sb-account/transactions",
        exact: "exact",
        component: SBTransaction,
    },

    {
        path: "/sb-account/transactions/approve",
        exact: "exact",
        component: SBTransactionApprove,
    },

    //Current Accounts
    {
        path: "/current-account",
        exact: "exact",
        component: CurrentAccount,
    },
    {
        path: "/current-account/transactions",
        exact: "exact",
        component: CATransaction,
    },

    {
        path: "/current-account/transactions/approve",
        exact: "exact",
        component: CATransactionApprove,
    },

    //Fixed Deposits
    {
        path: "/fixed-deposits/accounts",
        exact: "exact",
        component: FDAccount,
    },
    {
        path: "/fixed-deposits/transactions",
        exact: "exact",
        component: FDTransaction,
    },
    {
        path: "/fixed-deposits/approve-transactions",
        exact: "exact",
        component: FDApproveTransactions,
    },
    {
        path: "/fixed-deposits/interest-details",
        exact: "exact",
        component: FDInterestDetails,
    },
];
