import { FixedDepositConstant } from "Actions/constants";

const getFDDependencies = () => {
    return {
        type: FixedDepositConstant.GET_FD_DEPENDEICIES,
        success: FixedDepositConstant.GET_FD_DEPENDEICIES_SUCCESS,
        payload: null,
    };
};

const createScheme = (data) => {
    return {
        type: FixedDepositConstant.CREATE_FD_SCHEME,
        success: FixedDepositConstant.CREATE_FD_SCHEME_SUCCESS,
        payload: data,
    };
};

const updateScheme = (data) => {
    return {
        type: FixedDepositConstant.UPDATE_FD_SCHEME,
        success: FixedDepositConstant.UPDATE_FD_SCHEME_SUCCESS,
        payload: data,
    };
};

const getScheme = (data) => {
    return {
        type: FixedDepositConstant.GET_FD_SCHEME,
        success: FixedDepositConstant.GET_FD_SCHEME_SUCCESS,
        payload: data,
    };
};

const getAllSchemes = () => {
    return {
        type: FixedDepositConstant.GET_ALL_FD_SCHEME,
        success: FixedDepositConstant.GET_ALL_FD_SCHEME_SUCCESS,
        payload: null,
    };
};

const createAccount = (data) => {
    return {
        type: FixedDepositConstant.CREATE_FD_ACCOUNT,
        success: FixedDepositConstant.CREATE_FD_ACCOUNT_SUCCESS,
        payload: data,
    };
};

const updateAccount = (data) => {
    return {
        type: FixedDepositConstant.UPDATE_FD_ACCOUNT,
        success: FixedDepositConstant.UPDATE_FD_ACCOUNT_SUCCESS,
        payload: data,
    };
};

const getAccount = (data) => {
    return {
        type: FixedDepositConstant.GET_FD_ACCOUNT,
        success: FixedDepositConstant.GET_FD_ACCOUNT_SUCCESS,
        payload: data,
    };
};

const getAllAccounts = () => {
    return {
        type: FixedDepositConstant.GET_ALL_FD_ACCOUNT,
        success: FixedDepositConstant.GET_ALL_FD_ACCOUNT_SUCCESS,
        payload: null,
    };
};

const createTransaction = (data) => {
    return {
        type: FixedDepositConstant.CREATE_FD_TRANSACTION,
        success: FixedDepositConstant.CREATE_FD_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const deleteTransaction = (data) => {
    return {
        type: FixedDepositConstant.DELETE_FD_TRANSACTION,
        success: FixedDepositConstant.DELETE_FD_TRANSACTION_SUCCESS,
        payload: data,
        failed: FixedDepositConstant.ERROR,
    };
};

const getTransactions = (data) => {
    return {
        type: FixedDepositConstant.GET_FD_TRANSACTION,
        success: FixedDepositConstant.GET_FD_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const deleteApprovalTransaction = (data) => {
    return {
        type: FixedDepositConstant.DELETE_FD_APPROVAL_TRANSACTION,
        success: FixedDepositConstant.DELETE_FD_APPROVAL_TRANSACTION_SUCCESS,
        payload: data,
        failed: FixedDepositConstant.ERROR,
    };
};

const getApprovalTransactions = (data) => {
    return {
        type: FixedDepositConstant.GET_FD_APPROVAL_TRANSACTION,
        success: FixedDepositConstant.GET_FD_APPROVAL_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const approveTransactions = (data) => {
    return {
        type: FixedDepositConstant.APPROVE_FD_TRANSACTION,
        success: FixedDepositConstant.APPROVE_FD_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const setSelectedAll = () => {
    return {
        type: FixedDepositConstant.SET_SELECTED_FD_TRANSACTIONS,
        payload: null,
    };
};

const setSelectedOne = (data) => {
    return { type: FixedDepositConstant.SET_SELECTED_FD_TRANSACTION, payload: data };
};

const calcInterestPayble = (data) => {
    return {
        type: FixedDepositConstant.CALCULATE_FD_INTEREST_PAYBLE,
        success: FixedDepositConstant.CALCULATE_FD_INTEREST_PAYBLE_SUCCESS,
        payload: data,
    };
};

const addInterestPayble = (data) => {
    return {
        type: FixedDepositConstant.ADD_FD_INTEREST_PAYBLE,
        success: FixedDepositConstant.ADD_FD_INTEREST_PAYBLE_SUCCESS,
        payload: data,
    };
};

const getInterestPaybleDependencies = () => {
    return {
        type: FixedDepositConstant.GET_INTEREST_PAYBLE_DEFAULT,
        success: FixedDepositConstant.GET_INTEREST_PAYBLE_DEFAULT_SUCCESS,
        payload: null,
    };
};

const getInterestPayble = (data) => {
    return {
        type: FixedDepositConstant.GET_INTEREST_PAYBLE,
        success: FixedDepositConstant.GET_INTEREST_PAYBLE_SUCCESS,
        payload: data,
    };
};

const getInterestPaybleDetails = (data) => {
    return {
        type: FixedDepositConstant.GET_INTEREST_PAYBLE_DETAILS,
        success: FixedDepositConstant.GET_INTEREST_PAYBLE_DETAILS_SUCCESS,
        payload: data,
    };
};

const undoLastInterestPayble = (data) => {
    return {
        type: FixedDepositConstant.UNDO_FD_INTEREST_PAYBLE,
        success: FixedDepositConstant.UNDO_FD_INTEREST_PAYBLE_SUCCESS,
        payload: data,
    };
};

const getFDInterestDetails = (data) => {
    return {
        type: FixedDepositConstant.GET_FD_INTEREST_DETAILS,
        success: FixedDepositConstant.GET_FD_INTEREST_DETAILS_SUCCESS,
        payload: data,
    };
};

const payFDInterest = (data) => {
    return {
        type: FixedDepositConstant.PAY_FD_INTEREST,
        success: FixedDepositConstant.PAY_FD_INTEREST_SUCCESS,
        payload: data,
    };
};

const failed = (data) => {
    return {
        type: FixedDepositConstant.ERROR,
        payload: data,
    };
};

export {
    getFDDependencies,
    createScheme,
    updateScheme,
    getScheme,
    getAllSchemes,
    createAccount,
    updateAccount,
    getAccount,
    getAllAccounts,
    createTransaction,
    deleteTransaction,
    getTransactions,
    approveTransactions,
    deleteApprovalTransaction,
    getApprovalTransactions,
    setSelectedAll,
    setSelectedOne,
    calcInterestPayble,
    addInterestPayble,
    getInterestPayble,
    getInterestPaybleDetails,
    getInterestPaybleDependencies,
    undoLastInterestPayble,
    getFDInterestDetails,
    payFDInterest,
    failed,
};
