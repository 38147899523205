import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxInput } from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";
import DataTable from "Components/UI/DataTable";

//Actions
import * as AccountHeadActions from "Actions/Vendors/bankAccountHeads.action";
import * as BAAction from "Actions/Vendors/bankAccounts.action";

import AccountHead from "./AccountHead";
import Accounts from "./Accounts";
import Ledger from "./Ledger";

const ApproveBankTransaction = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [accountHeadId, setAccountHeadId] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [ledgerId, setLedgerId] = useState(null);

    const [state, setState] = useState({
        transactionDate: new Date(),
        approveDate: new Date(),
        loadTransactions: false,
    });

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.bankAccounts);

    const approvalTransactions = stateObject.approvalTransactions;

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    useEffect(() => {
        dispatch(AccountHeadActions.getAll());
    }, [dispatch]);

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        let selectedTxns = approvalTransactions.map((item) =>
            item.selected ? item.transactionId : 0
        );

        let selectedIds = selectedTxns.filter((item) => {
            if (item) {
                return item;
            }
        });
        dispatch(
            BAAction.approveTransactions({
                transactionIds: selectedIds,
                accountHeadId,
                accountId,
                ledgerId,
                transactionDate: Utils.formatDate(state.transactionDate),
                approveDate: Utils.formatDate(state.approveDate),
            })
        );
    };

    const deleteTransaction = (transactionId) => {
        setAlertStatus(true);
        const data = {
            transactionId,
            accountHeadId,
            accountId,
            ledgerId,
        };
        dispatch(BAAction.deleteTempTransaction(data));
    };

    const getTransactions = () => {
        setAlertStatus(true);
        dispatch(
            BAAction.getApprovalTransactions({
                accountHeadId,
                accountId,
                ledgerId,
            })
        );
    };
    const setTransactionSelection = (id, selected) => {
        dispatch(BAAction.setTransactionSelection({ id, selected }));
    };
    const selectAll = () => {
        dispatch(BAAction.setSelectedAll());
    };

    const actionButtons = (cell, row) => {
        //if (row.status == 1) {
        return (
            <Row>
                <Col md={12} className="text-center">
                    <Button
                        variant="link"
                        onClick={() => {
                            deleteTransaction(row.transactionId);
                        }}
                    >
                        <i className="fa fa-trash fa-lg text-danger" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
        /*  } else {
            return (
                <Row>
                    <Col md={12} className="text-center">
                        <Button variant="link" disabled="true">
                            <span class="fa-stack fa-lg">
                                <i class="fa fa-trash fa-stack-1x"></i>
                                <i class="fa fa-ban fa-stack-2x text-danger"></i>
                            </span>
                        </Button>
                    </Col>
                </Row>
            );
        } */
    };

    const formatSelectedOptions = (cell, row) => {
        if (row.selected) {
            return (
                <CheckBoxInput
                    checked={true}
                    controlId={"formCheck" + row.transactionId}
                    onClick={(e) => {
                        setTransactionSelection(row.transactionId, e.target.checked);
                    }}
                />
            );
        } else {
            return (
                <CheckBoxInput
                    checked={false}
                    controlId={"formCheck" + row.transactionId}
                    onClick={(e) => {
                        setTransactionSelection(row.transactionId, e.target.checked);
                    }}
                />
            );
        }
    };

    const currencyFormat = (cell, row) => {
        if (row.withdraw !== 0) {
            return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(
                row.withdraw
            );
        } else {
            return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(
                row.deposit
            );
        }
    };

    const columns = [
        {
            dataField: "selected",
            text: "Select",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatSelectedOptions,
        },
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "particulars",
            text: "Particulars",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "voucherNo",
            text: "Voucher No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "transactionType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.getTransactionTypeStr,
        },
        {
            dataField: "transactionAmount",
            text: "Amount",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: currencyFormat,
        },

        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const pageNavItems = [
        {
            title: "Bank Account",
            active: true,
            to: "/bank-accounts",
        },
        {
            title: "Approve Transaction",
            active: false,
            to: "/bank-accounts/approve",
        },
    ];
    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Approve Bank Account Transactions">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={3}>
                                <AccountHead
                                    handleChange={setAccountHeadId}
                                    accountHeadId={accountHeadId}
                                    setAlertStatus={setAlertStatus}
                                    errors={errors}
                                    setAccountId={setAccountId}
                                />
                            </Col>
                            <Col md={3}>
                                <Accounts
                                    handleChange={setAccountId}
                                    accountId={accountId}
                                    accountHeadId={accountHeadId}
                                    ledgerId={ledgerId}
                                    setAlertStatus={setAlertStatus}
                                    errors={errors}
                                    setLedgerId={setLedgerId}
                                />
                            </Col>
                            <Col md={3}>
                                <Ledger
                                    handleChange={onChangeHandler}
                                    accountId={state.accountId}
                                    accountHeadId={state.accountHeadId}
                                    ledgerId={state.ledgerId}
                                    setAlertStatus={setAlertStatus}
                                    errors={errors}
                                />
                            </Col>
                            <Col md={3}>
                                <br />
                                <Button
                                    variant="primary"
                                    type="Button"
                                    className="mt-6px"
                                    onClick={getTransactions}
                                >
                                    Load Transaction
                                </Button>
                            </Col>
                        </Row>

                        {approvalTransactions && Object.keys(approvalTransactions).length !== 0 ? (
                            <>
                                <Row>
                                    <Col md="12">
                                        <DataTable
                                            keyField="id"
                                            columns={columns}
                                            data={approvalTransactions}
                                            search={false}
                                            pagination={false}
                                            button={false}
                                            className="transaction-table"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <br />
                                        <Button
                                            variant="secondary"
                                            onClick={selectAll}
                                            type="Button"
                                        >
                                            Select All
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button variant="primary" type="Submit">
                                            Approve
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            ""
                        )}

                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default ApproveBankTransaction;
