import { UserConstant } from "Actions/constants";
const initState = {
    errors: [],
    users: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    user: null,
    message: "",
};

const UserReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case UserConstant.CREATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case UserConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case UserConstant.UPDATE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case UserConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case UserConstant.DELETE:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case UserConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                users: action.payload.objects,
            };
            break;
        case UserConstant.GET:
            state = {
                ...state,
                user: null,
                PROCESSING: true,
            };
            break;
        case UserConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                user: action.payload.object,
            };
            break;
        case UserConstant.GET_ALL:
            state = {
                ...state,
                users: [],
                user: null,
                PROCESSING: true,
            };
            break;
        case UserConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                user: null,
                users: action.payload.objects,
            };
            break;
        case UserConstant.ERROR:
            console.log(action);
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default UserReducer;
