import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "Actions/auth";
import Home from "Views/home";
import SignIn from "Views/signIn";
import { Forbidden } from "Views/forbidden";
import AdminRoutes from "Components/HOC/AdminRoutes";
import ManagerRoutes from "Components/HOC/ManagerRoutes";
import { adminRoutes, managerRoutes } from "Routes/routes";
import NotFound from "Components/UI/NotFound";
function App() {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        //If User is not authenticated it will check for data in local storage
        //if it's already true condition will eval to false and fucntion will not execute
        if (!auth.authenticated) dispatch(isAuthenticated());
    }, [dispatch]);
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/signin" exact component={SignIn} />
                    <Route path="/forbidden" exact component={Forbidden} />
                    <Route path="/" exact component={Home} />

                    {managerRoutes.map((route, idx) =>
                        route.exact !== "" ? (
                            <ManagerRoutes path={route.path} exact component={route.component} />
                        ) : (
                            <ManagerRoutes path={route.path} component={route.component} />
                        )
                    )}

                    {adminRoutes.map((route, idx) =>
                        route.exact !== "" ? (
                            <AdminRoutes path={route.path} exact component={route.component} />
                        ) : (
                            <AdminRoutes path={route.path} component={route.component} />
                        )
                    )}
                    <Route component={NotFound} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
