import { takeLatest, call, put } from "redux-saga/effects";
import { logout } from "Actions/auth";
import * as REST from "Services/rest.service";
import {
    TOKEN_EXPIRED,
    TRANSACTION_DELETE_END_POINT,
    ApproveDeletedConstants as ActionConstant,
} from "Actions/constants";

import { failed } from "Actions/Vendors/approveDeleted.action";

const message = "Request Failed!";
let err = new Error(message);
err.status = 500;

const endPoint = "/vendor/deleted-transactions";

function* watchGetTransactionRequest(action) {
    try {
        let response = yield call(REST.get(endPoint));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchApproveDeleteRequest(action) {
    try {
        let response = yield call(REST.post(endPoint + "/delete-approvals", action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchTransactionApproveRequest(action) {
    try {
        let response = yield call(REST.post(endPoint + "/approve", action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

export function* watchTransactionDeleteRequest(action) {
    try {
        let response = yield call(REST.post(TRANSACTION_DELETE_END_POINT, action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put({ type: action.failed, payload: response.data });
        }
    } catch (err) {
        console.log(err);
        yield put({ type: action.failed, payload: err });
    }
}

export function* watchApproveDeletedRequest() {
    yield takeLatest(ActionConstant.GET_DELETED_TRANSACTIONS, watchGetTransactionRequest);
    yield takeLatest(ActionConstant.DELETE_TRANSACTION, watchApproveDeleteRequest);
    yield takeLatest(ActionConstant.APPROVE_DELETED_TRANSACTIONS, watchTransactionApproveRequest);
}
