import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Input, CheckBoxInput, DatePickerInput } from "Components/UI/Input";
import * as Utils from "Helpers/utils";
import moment from "moment";

import { failed } from "Actions/Vendors/fixedDeposits.action";

import ContraTransfer from "../Contra/Transfer";

const InterestPay = (props) => {
    const [interestPayble, setInterestPayble] = useState(0);
    const [interestDate, setInterestDate] = useState(new Date());
    const [interest, setInterest] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    const [totalInterestPaid, setTotalInterestPaid] = useState(0);
    const [showTransfer, setTransfer] = useState(false);
    const [state, setState] = useState({
        accountType: null,
        schemeAgentId: null,
        accountNo: null,
    });

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.fixedDeposits);

    const errors = [];

    const calculateInterest = (selectedDate) => {
        setInterestDate(selectedDate);

        let lastInterestDate = props.depositDate;
        if (stateObject.lastInterestDate) {
            lastInterestDate = stateObject.lastInterestDate;
        }

        const depositAmount = props.deposit;

        const date1 = moment(lastInterestDate, "YYYY-MM-DD");
        const date2 = moment(selectedDate, "YYYY-MM-DD");
        console.log(props.depositDate);
        const days = date2.diff(date1, "days");

        if (days <= 0) {
            props.setAlertStatus(true);
            dispatch(
                failed({
                    message:
                        "Already interest paid for selected period or \r\n Select Interest Date greater than Deposit Date / Last Interest Paid Date.",
                })
            );
            return;
        }

        const interest = Math.round(((depositAmount * props.interestRate) / 100 / 365) * days);
        setInterest(interest);
        setTotalInterest(interest + stateObject.interestPayble);
    };

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    useEffect(() => {
        setInterestPayble(stateObject.interestPayble);
    }, [stateObject]);

    const submitForm = (e) => {
        e.preventDefault();

        const data = {
            interestDate: Utils.formatDate(interestDate),
            interestPayble,
            totalInterestPaid,
            interest,
            accountType: state.accountType,
            schemeAgentId: state.schemeAgentId,
            accountNo: state.accountNo,
            contraTransfer: showTransfer ? 1 : 0,
            totalInterest,
        };
        console.log(data);
    };

    return (
        <Modal
            show={props.showInterestDialog}
            onHide={props.setInterestDialog}
            centered="true"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Pay Interest</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={4}>
                            <DatePickerInput
                                errMessage={errors["transactionDate"]}
                                icon="fa-calendar"
                                label="Interest Date"
                                dateFormat="yyyy-MM-dd"
                                selected={interestDate}
                                onChange={(date) => {
                                    calculateInterest(date);
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="interestRate"
                                errMessage={errors["interestRate"]}
                                value={props.interestRate}
                                label="Interest Rate"
                                placeholder="Interest Rate"
                                controlId="interestRate"
                                readOnly="true"
                                onChange={(e) => {}}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="depositAmount"
                                id="depositAmount"
                                value={props.deposit}
                                label="Deposit Amount"
                                controlId="depositAmount"
                                readOnly="true"
                                onChange={(e) => {}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="interestPayble"
                                id="interestPayble"
                                value={interestPayble}
                                placeholder="Interest Payble"
                                label="Interest Payble"
                                controlId="interestPayble"
                                readOnly="true"
                                onChange={(e) => {}}
                            />
                        </Col>

                        <Col md={4}>
                            <Input
                                type="text"
                                name="totalInterest"
                                id="totalInterest"
                                value={totalInterestPaid}
                                placeholder="Total Interest Paid"
                                label="Total Interest Paid"
                                controlId="totalInterest"
                                onChange={(e) => setTotalInterestPaid(e.target.value)}
                                readOnly={true}
                            />
                        </Col>

                        <Col md={4}>
                            <Input
                                type="text"
                                name="interest"
                                id="interest"
                                value={interest}
                                placeholder="Interest"
                                label="Interest"
                                controlId="interest"
                                onChange={(e) => setInterest(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="totalInterest"
                                id="totalInterest"
                                value={totalInterest}
                                placeholder="Total Interest"
                                label="Total Interest"
                                controlId="totalInterest"
                                onChange={(e) => setTotalInterest(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-10px mb-10px">
                        <Col md={12}>
                            <CheckBoxInput
                                type="checkbox"
                                label="Show Transfer"
                                name="showTransfer"
                                id="showTransfer"
                                value="1"
                                onClick={(e) => {
                                    setTransfer(e.target.checked);
                                }}
                            />
                        </Col>
                    </Row>

                    {showTransfer ? (
                        <ContraTransfer
                            errors={errors}
                            accountType={state.accountType}
                            schemeAgentId={state.schemeAgentId}
                            accountNo={state.accountNo}
                            onChangeHandler={onChangeHandler}
                            transferAmount={interest}
                        />
                    ) : (
                        ""
                    )}
                    <Row>
                        <Col md="12">
                            <Button
                                variant="primary"
                                type="Button"
                                onClick={(e) => calculateInterest(interestDate)}
                            >
                                Calculate Interest
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" type="Submit">
                                Submit
                            </Button>
                            &nbsp;&nbsp;
                            {/*  <Button
                                variant="secondary"
                                onClick={() => {
                                    props.setInterestDialog(false);
                                }}
                                type="Button"
                            >
                                Close
                            </Button> */}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default InterestPay;
