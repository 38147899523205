import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import Scheme from "./Scheme";
import AccountHolderOptions from "Views/Vendor/AccountHolders/AccountHolderOptions";
import { CheckBoxInput, Input, SelectInput, TextArea } from "Components/UI/Input";
import * as Utils from "Helpers/utils";
import { createAccount } from "Actions/Vendors/fixedDeposits.action";

const NewAccountDialog = (props) => {
    const [schemeId, setSchemeId] = useState("");
    const [accountHolderId, setAccountHolderId] = useState("");
    const [state, setState] = useState({
        isMinor: false,
        nomineeName: null,
        nomineeRelation: null,
        nomineeAge: null,
        guardianName: null,
        guardianRelation: null,
        guardianAge: null,
        jointHolders: null,
        ledgerNo: null,
        folioNo: null,
        notifyMaturity: false,
        createdDate: new Date(),
    });

    let nomineeRelationOption = Utils.getNomineeRelationOptions();

    const stateObject = useSelector((state) => state.savingAccount);
    const accountHolderState = useSelector((state) => state.accountHolder);

    const dispatch = useDispatch();

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const handleAccountHolderChange = (accountHolderId) => {
        setAccountHolderId(accountHolderId);
        const accountHolder = Utils.getNomineeDetails(
            accountHolderId,
            accountHolderState.accountHolders
        );
        if (accountHolder) {
            setState({
                ...state,
                nomineeName: accountHolder.nomineeName,
                nomineeRelation: accountHolder.nomineeRelation,
                nomineeAge: accountHolder.nomineeAge,
            });
        }
    };

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        props.setAlertStatus(true);
        const data = { ...state, schemeId, accountHolderId };
        dispatch(createAccount(data));
    };

    const resetForm = (e) => {};

    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>New FD Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col md={4}>
                            <Scheme
                                schemeId={schemeId}
                                setSchemeId={setSchemeId}
                                setAlertStatus={props.setAlertStatus}
                                errMessage={errors["schemeId"]}
                            />
                        </Col>
                        <Col md={4}>
                            <AccountHolderOptions
                                accountHolderId={accountHolderId}
                                setAccountHolderId={handleAccountHolderChange}
                                setAlertStatus={props.setAlertStatus}
                                errMessage={errors["accountHolderId"]}
                            />
                        </Col>
                        <Col md={4}>
                            <br />
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.isMinor}
                                    label="Is Minor"
                                    onClick={(e) => onChangeHandler("isMinor", e.target.checked)}
                                />
                            </span>
                            <span style={{ display: "inline-block", float: "left", margin: "5px" }}>
                                <CheckBoxInput
                                    value={state.notifyMaturity}
                                    label="Notify Maturity"
                                    onClick={(e) =>
                                        onChangeHandler("notifyMaturity", e.target.checked)
                                    }
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="nomineeName"
                                value={state.nomineeName}
                                label="Nominee Name"
                                id="nomineeName"
                                onChange={(e) => onChangeHandler("nomineeName", e.target.value)}
                                placeholder="Nominee Name"
                                errMessage={errors["nomineeName"]}
                            />
                        </Col>
                        <Col md="4">
                            <SelectInput
                                id="nomineeRelation"
                                name="nomineeRelation"
                                label="Select Nominee Relation"
                                errMessage={errors["nomineeRelation"]}
                                handleChange={(e) => setState("nomineeRelation", e.target.value)}
                                Options={nomineeRelationOption}
                                value={state.nomineeRelation}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="nomineeAge"
                                value={state.nomineeAge}
                                label="Nominee Age"
                                id="nomineeAge"
                                onChange={(e) => onChangeHandler("nomineeAge", e.target.value)}
                                placeholder="Nominee Age"
                                errMessage={errors["nomineeAge"]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="guardianName"
                                id="guardianName"
                                value={state.guardianName}
                                label="Guardian Name (If Minor)"
                                placeholder="Guardian Name"
                                onChange={(e) => onChangeHandler("guardianName", e.target.value)}
                            />
                        </Col>
                        <Col md="4">
                            <SelectInput
                                id="guardianRelation"
                                name="guardianRelation"
                                label="Select Guardian Relation"
                                errMessage={errors["guardianRelation"]}
                                handleChange={(e) => setState("guardianRelation", e.target.value)}
                                Options={nomineeRelationOption}
                                value={state.guardianRelation}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="guardianAge"
                                value={state.guardianAge}
                                label="Guardian Age"
                                id="guardianAge"
                                onChange={(e) => onChangeHandler("guardianAge", e.target.value)}
                                placeholder="Guardian Age"
                                errMessage={errors["guardianAge"]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                name="jointHolders"
                                id="jointHolders"
                                label="Joint Holders (If Any)"
                                placeholder="Joint Holders"
                                value={state.jointHolders}
                                onChange={(e) => onChangeHandler("jointHolders", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="ledgerNo"
                                id="ledgerNo"
                                label="Ledger Number"
                                placeholder="Ledger Number"
                                value={state.ledgerNo}
                                onChange={(e) => onChangeHandler("ledgerNo", e.target.value)}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="folioNo"
                                id="folioNo"
                                label="Folio Number"
                                placeholder="Folio Number"
                                value={state.folioNo}
                                onChange={(e) => onChangeHandler("folioNo", e.target.value)}
                            />
                        </Col>
                        <Col md="4" className="mt-5px">
                            <br />
                            <Button variant="primary" type="Submit">
                                Create Account
                            </Button>
                            &nbsp;&nbsp;
                            <Button variant="secondary" onClick={resetForm} type="Button">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NewAccountDialog;
