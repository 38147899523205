import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
const AlertDialog = (props) => {
    return (
        <Modal
            show={props.showDialog}
            onHide={() => props.onChangeHandler("showDialog", false)}
            centered="true"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <br />
                <p>Are you sure, you want to close the day?</p>
                <br />
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => props.onChangeHandler("showDialog", false)}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={props.closeDay}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
};
export default AlertDialog;
