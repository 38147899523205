import Layout from "Components/Layout";
import React from "react";
import { Card } from "react-bootstrap";

const NotFound = () => {
    return (
        <Layout pageTitle="">
            <div
                className="page-wrap d-flex flex-row align-items-center"
                style={{ minHeight: "50vh" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block text-danger">404</span>
                            <div className="mb-4 lead">
                                The page you are looking for was not found.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotFound;
