import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Input, SearchableSelect } from "Components/UI/Input";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/bankAccounts.action";
import * as LedgerActions from "Actions/Vendors/bankAccountLedger.action";

const LedgerDialog = (props) => {
    const [state, setState] = useState({
        parentId: props.parentId,
        ledgerName: "",
        interestRate: 0,
        printOrder: 0,
        status: true,
        type: 3,
        accountType: true,
    });

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.bankAccounts);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const bankAccountLedger = stateObject.bankAccountLedger;

    useEffect(() => {
        if (props.ledgerId) dispatch(LedgerActions.getOne({ id: props.ledgerId }));
    }, [dispatch, props.ledgerId]);

    const setProps = () => {
        setState({
            ...state,
            interestRate: bankAccountLedger.interestRate,
            accountType: bankAccountLedger.accountType === 1 ? true : false,
            ledgerName: bankAccountLedger.displayName,
            printOrder: bankAccountLedger.printOrder,
            status: bankAccountLedger.status === 1 ? true : false,
        });
    };

    useEffect(() => {
        if (bankAccountLedger) {
            setProps();
        }
    }, [bankAccountLedger]);

    const resetForm = () => {
        setState({
            parentId: props.parentId,
            ledgerName: "",
            interestRate: 0,
            printOrder: 0,
            status: true,
            type: 3,
            accountType: true,
        });
        if (props.ledgerId) {
            setProps();
        }
    };

    const submitForm = () => {
        props.setAlertStatus(true);
        let data = {
            parentId: props.parentId,
            name: state.ledgerName,
            printOrder: state.printOrder,
            status: state.status ? 1 : 0,
            interestRate: state.interestRate,
            type: state.type,
            accountType: state.accountType ? 1 : 2,
        };
        if (props.ledgerId) {
            data = {
                ...data,
                id: props.ledgerId,
            };
            dispatch(LedgerActions.update(data));
        } else {
            dispatch(LedgerActions.create(data));
        }
        //resetForm();
    };

    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>New Ledger</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="ledgerName"
                            controlid="formledgerName"
                            label="Enter Ledger Name"
                            placeholder="Enter Ledger Name"
                            value={state.ledgerName}
                            onChange={(e) => {
                                onChangeHandler("ledgerName", e.target.value);
                            }}
                            errMessage={errors["name"]}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="interestRate"
                            controlid="forminterestRate"
                            label="Interest Rate"
                            value={state.interestRate}
                            onChange={(e) => {
                                onChangeHandler("interestRate", e.target.value);
                            }}
                            errMessage={errors["interestRate"]}
                        />
                    </Col>
                    <Col md={4}>
                        <label>Account Type</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={state.accountType}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Asset"
                            offlabel="Liability"
                            onChange={(checked) => {
                                onChangeHandler("accountType", checked);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <label>Status</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={state.status}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Active"
                            offlabel="In-Active"
                            onChange={(checked) => {
                                onChangeHandler("status", checked);
                            }}
                        />
                    </Col>

                    <Col md={4} className="mt-5px">
                        <br />
                        <Button variant="primary" onClick={submitForm}>
                            Submit
                        </Button>
                        &nbsp;
                        <Button variant="secondary" onClick={resetForm}>
                            Reset
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>{errors["parentId"]}</Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

const Ledger = (props) => {
    const [showDialog, setDialog] = useState(false);

    const stateObject = useSelector((state) => state.bankAccounts);

    const accountOptions = Utils.getSearchableOptions(
        stateObject.bankAccountLedgers,
        "displayName",
        "accountNo"
    );

    const dispatch = useDispatch();

    const getTransactions = (id) => {
        if (props.loadTransactions)
            dispatch(
                Actions.getTransactions({
                    ledgerId: id,
                    accountHeadId: props.accountHeadId,
                    accountNo: props.accountId,
                })
            );
    };

    return (
        <>
            <LedgerDialog
                showDialog={showDialog}
                setDialog={setDialog}
                setAlertStatus={props.setAlertStatus}
                ledgerId={props.ledgerId}
                parentId={props.accountId}
            />
            <Row>
                <Col md={10} style={{ paddingRight: "0px" }}>
                    <SearchableSelect
                        label="Select Ledger"
                        Options={accountOptions}
                        controlid="formLedger"
                        name="ledgerId"
                        value={props.ledgerId}
                        errMessage={props.errors["ledgerId"]}
                        onChange={(selectedOption) => {
                            if (selectedOption.length > 0) {
                                props.handleChange(selectedOption[0].value);
                                getTransactions(selectedOption[0].value);
                            }
                        }}
                    />
                </Col>
                <Col md={2} style={{ paddingLeft: "1px" }}>
                    <br />
                    <Button
                        variant="secondary"
                        className="mt-7px"
                        onClick={() => setDialog(props.accountId ? true : false)}
                    >
                        ...
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default Ledger;
