import { takeLatest, put, take } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import { LoanAccountConstants as ActionConstant } from "Actions/constants";
import { getAll as getAccountHolders } from "Actions/Vendors/accountHolder.action";
import { failed, getLoanAccountNumber, getLoanSchemes } from "Actions/Vendors/loanAccounts.action";

const endPoint = "/vendor/loan-accounts";

const sagaWatchers = BaseSaga(endPoint, failed);

const SagaActions = {
    ...sagaWatchers,
};

const getFormData = (data) => {
    let formData = new FormData();

    formData.set("loanSchemeId", data.loanSchemeId);
    formData.set("accountHolderId", data.accountHolderId);
    formData.set("processingFees", data.processingFees);
    formData.set("insuranceFees", data.insuranceFees);
    formData.set("legalFees", data.legalFees);
    formData.set("items", data.items);
    formData.set("pledgeValue", data.pledgeValue);
    formData.set("evaluator", data.evaluator);
    formData.set("evaluatorCharges", data.evaluatorCharges);
    formData.set("installments", data.installments);
    formData.set("limitSanctioned", data.limitSanctioned);
    formData.set("amountSanctioned", data.amountSanctioned);
    formData.set("installmentAmount", data.installmentAmount);
    formData.set("interestRate", data.interestRate);
    formData.set("penalInterest", data.penalInterest);
    formData.set("issueDate", data.issueDate);
    formData.set("dueDate", data.dueDate);
    formData.set("interestDate", data.interestDate);
    formData.set("interestAmount", data.interestAmount);
    formData.set("guarantor1", data.guarantor1);
    formData.set("guarantor2", data.guarantor2);
    formData.set("remarks", data.remarks);
    formData.set("interestBalance", data.interestBalance);
    formData.set("penalInterestBalance", data.penalInterestBalance);
    formData.set("vehicleType", data.vehicleType);
    formData.set("vehicle", data.vehicle);
    formData.set("vehicleMake", data.vehicleMake);
    formData.set("vehicleModel", data.vehicleModel);
    formData.set("vehicleNo", data.vehicleNo);
    formData.set("vehicleChassiNo", data.vehicleChassiNo);
    formData.set("vehicleEngineNo", data.vehicleEngineNo);
    formData.set("hypothecationDetails", data.hypothecationDetails);

    for (const key of Object.keys(data.hypothecationDocuments)) {
        formData.append("hypothecationDocuments", data.hypothecationDocuments[key]);
    }

    formData.set("insuranceCompany", data.insuranceCompany);
    formData.set("insurancePolicyNo", data.insurancePolicyNo);
    formData.set("insuranceDate", data.insuranceDate);
    formData.set("insuranceValidity", data.insuranceValidity);
    formData.set("pledgeItems", JSON.stringify(data.pledgeItems));
    return formData;
};

function* watchLoanAccountCreateRequest(action) {
    yield sagaWatchers.postFormData(action, getFormData(action.payload), endPoint);
}

function* watchLoanAccountNumberGetRequest(action) {
    yield sagaWatchers.getRequest(action, endPoint + "/loan-account-number");
}

function* watchLoanCategoryRequest(action) {
    yield sagaWatchers.getRequest(action, endPoint + "/categories");
}

function* watchLoanSchemeCreateRequest(action) {
    yield sagaWatchers.create(action, endPoint + "/create-scheme");
}

function* watchLoanSchemeGetRequest(action) {
    yield sagaWatchers.getRequest(action, endPoint + "/get-schemes");
}

function* watchLoanAccountDependencies() {
    yield put(getLoanAccountNumber());
    yield take(ActionConstant.GET_LOAN_ACCOUNT_NUMBER_SUCCESS);
    yield put(getLoanSchemes());
    yield take(ActionConstant.GET_LOAN_SCHEMES_SUCCESS);
    yield put(getAccountHolders());
}

export function* watchLoanAccountsRequests() {
    yield takeLatest(ActionConstant.CREATE, watchLoanAccountCreateRequest);
    yield takeLatest(ActionConstant.GET_ALL, SagaActions.getAll);
    yield takeLatest(ActionConstant.GET, SagaActions.getOne);
    yield takeLatest(ActionConstant.GET_LOAN_ACCOUNT_CATEGORY, watchLoanCategoryRequest);
    yield takeLatest(ActionConstant.CREATE_LOAN_SCHEME, watchLoanSchemeCreateRequest);
    yield takeLatest(ActionConstant.GET_LOAN_SCHEMES, watchLoanSchemeGetRequest);
    yield takeLatest(ActionConstant.GET_LOAN_ACCOUNT_DEPENDENCIES, watchLoanAccountDependencies);
    yield takeLatest(ActionConstant.GET_LOAN_ACCOUNT_NUMBER, watchLoanAccountNumberGetRequest);
}
