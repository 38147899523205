import { dayBeginEndConstants } from "Actions/constants";
import getActions from "Actions/base.action";

const baseActions = getActions(dayBeginEndConstants);
export const failed = baseActions.failed;

export const getAll = () => {
    return {
        type: dayBeginEndConstants.GET_ALL,
        success: dayBeginEndConstants.GET_ALL_SUCCESS,
        payload: null,
    };
};

export const getOpeningDate = () => {
    return {
        type: dayBeginEndConstants.GET_CURRENT_OPENING_DATE,
        success: dayBeginEndConstants.GET_CURRENT_OPENING_DATE_SUCCESS,
        payload: null,
    };
};

export const getOpeningDateSuccess = (data) => {
    return {
        type: dayBeginEndConstants.GET_CURRENT_OPENING_DATE_SUCCESS,
        payload: data,
    };
};

export const closeDay = (data) => {
    return {
        type: dayBeginEndConstants.CLOSE_DAY,
        sucess: dayBeginEndConstants.CLOSE_DAY_SUCCESS,
        payload: data,
    };
};
