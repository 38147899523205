import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "Components/Layout";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import { Input, SelectInput, TextArea } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Utils from "Helpers/utils";

import * as Actions from "Actions/Admin/user.action";
import * as TenantActions from "Actions/Admin/tenant.action";
import * as SocietyActions from "Actions/Admin/society.action";

class User extends Component {
    constructor(props) {
        super(props);
        this.session = Utils.getSession();
        this.state = {
            id: 0,
            name: "",
            mobile: "",
            address: "",
            role: "",
            email: "",
            password: "",
            oldPassword: "",
            tenantId: this.session.dbId,
            societyId: this.session.societyId,
            uuid: "",
            status: false,
            alertStatus: true,
            session: this.session,
        };
    }

    setAlertStatus = (status) => {
        this.setState({ alertStatus: status });
    };

    handleCheckboxChange = (event) => {
        this.setState({ status: 0 });
        if (event.target.checked) {
            this.setState({ status: 1 });
        }
    };

    handleInputChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
        });
    };

    handleSociety = (event) => {
        const name = event.target.name;
        const societyId = parseInt(event.target.value);
        this.setState({
            [name]: societyId,
        });
        const societies = this.props.societyStateObject.societies;
        const selectedSociety = societies.filter((society) => society.id === societyId);
        if (selectedSociety && selectedSociety.length > 0) {
            this.setState({ tenantId: selectedSociety[0].tenantId });
        }
    };

    getStateprops = () => {
        return {
            name: this.state.name,
            mobile: this.state.mobile,
            address: this.state.address,
            role: this.state.role,
            email: this.state.email,
            societyId: this.state.societyId,
            tenantId: this.state.tenantId,
            status: this.state.status ? 1 : 0,
        };
    };

    create = (e) => {
        e.preventDefault();
        this.setState({ alertStatus: true });
        const data = {
            ...this.getStateprops(),
            password: this.state.password,
        };
        this.props.actions.create(data);
    };

    update = (e) => {
        e.preventDefault();
        let stateProps = this.getStateprops();
        if (this.state.password !== this.state.oldPassword) {
            stateProps = {
                ...stateProps,
                password: this.state.password,
            };
        }
        this.props.actions.update({ id: this.state.id, uuid: this.state.uuid, ...stateProps });
    };

    componentDidMount() {
        let societyId = this.props.match.params.societyId;
        let tenantId = this.props.match.params.tenantId;

        let id = this.props.match.params.id;
        if (!id) {
            id = 0;
        }
        if (id !== 0) {
            this.props.actions.getOne({ id });
        }

        this.setState({ societyId });
        this.setState({ tenantId });
    }

    UNSAFE_componentWillMount() {
        //Get all tenants
        this.props.tenantAction.getAll();
        //Get all societies
        this.props.societyAction.getAll();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.stateObject.user && nextProps.stateObject.user.id !== this.state.id) {
            this.setState({
                ...nextProps.stateObject.user,
                status: nextProps.stateObject.user.status === 1 ? true : false,
                oldPassword: nextProps.stateObject.user.password,
                uuid: nextProps.stateObject.user.uuid,
            });
        }
    }

    resetform = () => {
        this.setState({
            name: "",
            mobile: "",
            role: "",
            email: "",
            password: "",
            tenantId: 0,
            societyId: 0,
            uuid: "",
            status: false,
            alertStatus: true,
        });
    };

    render() {
        //Nav Items
        const pageNavItems = [
            {
                title: "Users",
                active: true,
                to: "/users",
            },
            {
                title: "Create",
                active: false,
                to: "",
            },
        ];

        const tenantOptions = Utils.getOptions(
            this.props.tenantStateObject.details,
            "dbName",
            "id"
        );

        /*
        const selectedDatabase = tenantOptions.filter(
            (option) => option.value === this.state.tenantId
        );
        
        let selectedDBName = "";
        if (selectedDatabase.length > 0) {
            selectedDBName = selectedDatabase[0].label;
        }*/

        const societyOptions = Utils.getOptions(
            this.props.societyStateObject.societies,
            "societyName",
            "id"
        );

        let rolesOption = [];
        rolesOption["president"] = "President";
        rolesOption["manager"] = "Manager";
        rolesOption["clerk"] = "Clerk";
        rolesOption["pigmyAgent"] = "Pigmy Agent";
        // if (this.session.role === "admin") rolesOption["user"] = "User";
        return (
            <Layout pageNavItems={pageNavItems} pageTitle="Create Users">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <Form onSubmit={this.state.id !== 0 ? this.update : this.create}>
                                    <Row>
                                        <Col md="4">
                                            <Input
                                                label="Name"
                                                name="name"
                                                value={this.state.name}
                                                placeholder="Enter user name"
                                                errMessage={this.props.errors["name"]}
                                                type="text"
                                                controlId="formName"
                                                onChange={this.handleInputChange}
                                                icon="fa fa-user"
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input
                                                label="Mobile"
                                                name="mobile"
                                                value={this.state.mobile}
                                                placeholder="Enter mobile number"
                                                errMessage={this.props.errors["mobile"]}
                                                type="text"
                                                icon="fa fa-phone"
                                                controlId="formMobile"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input
                                                label="Email"
                                                name="email"
                                                value={this.state.email}
                                                placeholder="Enter email address"
                                                errMessage={this.props.errors["email"]}
                                                type="text"
                                                controlId="formemail"
                                                onChange={this.handleInputChange}
                                                icon="fa fa-envelope"
                                            />
                                        </Col>
                                    </Row>
                                    <br clear="all" />
                                    <Row>
                                        <Col md="4">
                                            <Input
                                                label="Password"
                                                name="password"
                                                value={this.state.password}
                                                placeholder="Enter password"
                                                errMessage={this.props.errors["password"]}
                                                type="password"
                                                controlId="formpassword"
                                                onChange={this.handleInputChange}
                                                icon="fa fa-lock"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <SelectInput
                                                id="role"
                                                name="role"
                                                label="Select Role"
                                                errMessage={this.props.errors["role"]}
                                                handleChange={this.handleInputChange}
                                                Options={rolesOption}
                                                value={this.state.role}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <SelectInput
                                                id="societyId"
                                                name="societyId"
                                                label="Select Society"
                                                errMessage={this.props.errors["societyId"]}
                                                handleChange={this.handleSociety}
                                                Options={societyOptions}
                                                value={this.state.societyId}
                                            />
                                        </Col>
                                    </Row>
                                    <br clear="all" />
                                    <Row>
                                        {this.state.session.role === "admin" ? (
                                            <Col md="4">
                                                <SelectInput
                                                    id="tenantId"
                                                    name="tenantId"
                                                    label="Select Database"
                                                    errMessage={this.props.errors["tenantId"]}
                                                    handleChange={this.handleInputChange}
                                                    Options={tenantOptions}
                                                    value={this.state.tenantId}
                                                />
                                            </Col>
                                        ) : (
                                            ""
                                        )}

                                        <Col md="2" className="mt-5px">
                                            <label>Status</label>
                                            <br />
                                            <BootstrapSwitchButton
                                                checked={this.state.status}
                                                onstyle="outline-success"
                                                offstyle="outline-danger"
                                                onlabel="Active"
                                                offlabel="In-Active"
                                                onChange={(checked) => {
                                                    this.setState({ status: checked });
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <br clear="all" />
                                    <Row>
                                        <Col md={12}>
                                            <TextArea
                                                errMessage={this.props.errors["address"]}
                                                label="Address"
                                                name="address"
                                                onChange={this.handleInputChange}
                                                id="address"
                                                value={this.state.address}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" className="mt-5px">
                                            <br />
                                            <Button variant="primary" type="Submit">
                                                Submit
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                variant="secondary"
                                                onClick={this.resetform}
                                                type="Button"
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-5">
                                        <RequestStatus
                                            stateObj={this.props.stateObject}
                                            alertStatus={this.state.alertStatus}
                                            setAlertStatus={this.setAlertStatus}
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps = (state, props) => {
    let errorObj = [];
    if (typeof state.user.errors !== "undefined") {
        state.user.errors.map((item) => (errorObj[item.param] = item.msg));
    }
    return {
        errors: errorObj,
        stateObject: state.user,
        tenantStateObject: state.tenants,
        societyStateObject: state.society,
        Utils,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    tenantAction: bindActionCreators(TenantActions, dispatch),
    societyAction: bindActionCreators(SocietyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
