import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "Components/Layout";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import {
    Input,
    SelectInput,
    DatePickerInput,
    TextArea,
    SearchableSelect,
} from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Utils from "Helpers/utils";

import * as TenantActions from "Actions/Admin/tenant.action";
import * as Actions from "Actions/Admin/society.action";

class Society extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            tenantId: 0,
            societyName: "",
            address: "",
            headOffice: false,
            contactPersonName: "",
            contactPersonMobile: "",
            email: "",
            subscription: "",
            type: true,
            expiryDate: new Date(),
            status: false,
            alertStatus: true,
        };
    }

    setAlertStatus = (status) => {
        this.setState({ alertStatus: status });
    };

    handleCheckboxChange = (event) => {
        this.setState({ status: 0 });
        if (event.target.checked) {
            this.setState({ status: 1 });
        }
    };

    handleInputChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
        });
    };

    getStateprops = () => {
        return {
            tenantId: this.state.tenantId,
            societyName: this.state.societyName,
            address: this.state.address,
            headOffice: this.state.headOffice ? 1 : 0,
            contactPersonName: this.state.contactPersonName,
            contactPersonMobile: this.state.contactPersonMobile,
            email: this.state.email,
            subscription: this.state.subscription,
            type: this.state.type ? 1 : 2,
            expiryDate: this.props.Utils.formatDate(this.state.expiryDate),
            status: this.state.status ? 1 : 0,
        };
    };

    create = (e) => {
        e.preventDefault();
        this.setState({ alertStatus: true });
        this.props.actions.create(this.getStateprops());
    };

    update = (e) => {
        e.preventDefault();
        const stateProps = this.getStateprops();
        this.props.actions.update({ id: this.state.id, ...stateProps });
    };

    componentDidMount() {
        let id = this.props.match.params.id;
        if (!id) {
            id = 0;
        }
        if (id !== 0) {
            this.props.actions.getOne({ id });
        }

        //Get all tenants
        this.props.tenantAction.getAll();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.stateObject.society && nextProps.stateObject.society.id !== this.state.id) {
            this.setState({
                ...nextProps.stateObject.society,
                expiryDate: new Date(nextProps.stateObject.society.expiryDate),
                headOffice: nextProps.stateObject.society.headOffice === 1 ? true : false,
                status: nextProps.stateObject.society.status === 1 ? true : false,
                type: nextProps.stateObject.society.type === 1 ? true : false,
            });
        }
    }

    resetform = () => {
        this.setState({
            societyName: "",
            address: "",
            headOffice: false,
            contactPersonName: "",
            contactPersonMobile: "",
            email: "",
            subscription: "",
            expiryDate: "",
            type: 1,
            tenantId: 0,
            status: false,
            alertStatus: true,
        });
    };

    render() {
        //Nav Items
        const pageNavItems = [
            {
                title: "Society",
                active: true,
                to: "/society",
            },
            {
                title: "Create",
                active: false,
                to: "",
            },
        ];

        const tenantOptions = Utils.getSearchableOptions(
            this.props.tenantStateObject.details,
            "dbName",
            "id"
        );

        let subscriptionOption = [];
        subscriptionOption["yearly"] = "Yearly";
        subscriptionOption["halfYearly"] = "Half-Yearly";
        subscriptionOption["quaterly"] = "Quaterly";
        subscriptionOption["monthly"] = "Monthly";

        const selectedDatabase = tenantOptions.filter(
            (option) => option.value === this.state.tenantId
        );
        let selectedDBName = "";
        if (selectedDatabase.length > 0) {
            selectedDBName = selectedDatabase[0].label;
        }
        return (
            <Layout pageNavItems={pageNavItems} pageTitle="Create Society">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <Form onSubmit={this.state.id !== 0 ? this.update : this.create}>
                                    <Row>
                                        <Col md="4">
                                            <Input
                                                label="Society Name"
                                                name="societyName"
                                                value={this.state.societyName}
                                                placeholder="Enter society name"
                                                errMessage={this.props.errors["societyName"]}
                                                type="text"
                                                controlId="formsocietyName"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input
                                                label="Contact Person Name"
                                                name="contactPersonName"
                                                value={this.state.contactPersonName}
                                                placeholder="Enter contact person name"
                                                errMessage={this.props.errors["contactPersonName"]}
                                                type="text"
                                                controlId="fromcontactPersonName"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input
                                                label="Contact Person Mobile"
                                                name="contactPersonMobile"
                                                value={this.state.contactPersonMobile}
                                                placeholder="Enter contact person mobile"
                                                errMessage={
                                                    this.props.errors["contactPersonMobile"]
                                                }
                                                type="text"
                                                controlId="formcontactPersonMobile"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Input
                                                label="Email"
                                                name="email"
                                                value={this.state.email}
                                                placeholder="Enter email address"
                                                errMessage={this.props.errors["email"]}
                                                type="text"
                                                controlId="formemail"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            {this.state.tenantId === 0 || this.state.id === 0 ? (
                                                <SearchableSelect
                                                    label="Select Database"
                                                    name="tenantId"
                                                    value={this.state.tenantId}
                                                    placeholder="Select Database"
                                                    errMessage={this.props.errors["tenantId"]}
                                                    controlId="formTenantId"
                                                    onChange={(selectedOption) => {
                                                        this.setState({
                                                            tenantId: selectedOption[0].value,
                                                        });
                                                    }}
                                                    Options={tenantOptions}
                                                />
                                            ) : (
                                                <Input
                                                    type="text"
                                                    label="Select Database"
                                                    value={selectedDBName}
                                                    disabled="true"
                                                />
                                            )}
                                        </Col>
                                        <Col md="4">
                                            <SelectInput
                                                id="subscription"
                                                name="subscription"
                                                label="Select subscription type"
                                                errMessage={this.props.errors["subscription"]}
                                                handleChange={this.handleInputChange}
                                                Options={subscriptionOption}
                                                value={this.state.subscription}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <DatePickerInput
                                                icon="fa-calendar"
                                                label="Expiry Date"
                                                dateFormat="yyyy-MM-dd"
                                                selected={this.state.expiryDate}
                                                onChange={(date) =>
                                                    this.setState({ expiryDate: date })
                                                }
                                            />
                                        </Col>
                                        <Col md="2" className="mt-5px">
                                            <label>Head Office</label>
                                            <br />
                                            <BootstrapSwitchButton
                                                checked={this.state.headOffice}
                                                onstyle="outline-success"
                                                offstyle="outline-danger"
                                                onlabel="Yes"
                                                offlabel="No"
                                                onChange={(checked) => {
                                                    this.setState({ headOffice: checked });
                                                }}
                                            />
                                        </Col>
                                        <Col md="2" className="mt-5px">
                                            <label>Status</label>
                                            <br />
                                            <BootstrapSwitchButton
                                                checked={this.state.status}
                                                onstyle="outline-success"
                                                offstyle="outline-danger"
                                                onlabel="Active"
                                                offlabel="In-Active"
                                                onChange={(checked) => {
                                                    this.setState({ status: checked });
                                                }}
                                            />
                                        </Col>
                                        <Col md="2" className="mt-5px">
                                            <label>Type</label>
                                            <br />
                                            <BootstrapSwitchButton
                                                checked={this.state.type}
                                                onstyle="outline-success"
                                                offstyle="outline-danger"
                                                onlabel="Society"
                                                offlabel="Finance"
                                                onChange={(checked) => {
                                                    this.setState({ type: checked });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <TextArea
                                                errMessage={this.props.errors["address"]}
                                                label="Address"
                                                name="address"
                                                onChange={this.handleInputChange}
                                                id="address"
                                                value={this.state.address}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" className="mt-5px">
                                            <br />
                                            <Button variant="primary" type="Submit">
                                                Submit
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                variant="secondary"
                                                onClick={this.resetform}
                                                type="Button"
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div className="text-center mt-5">
                                        <RequestStatus
                                            stateObj={this.props.stateObject}
                                            alertStatus={this.state.alertStatus}
                                            setAlertStatus={this.setAlertStatus}
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps = (state, props) => {
    let errorObj = [];
    if (typeof state.society.errors !== "undefined") {
        state.society.errors.map((item) => (errorObj[item.param] = item.msg));
    }
    return {
        errors: errorObj,
        stateObject: state.society,
        tenantStateObject: state.tenants,
        Utils,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    tenantAction: bindActionCreators(TenantActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Society);
