import axios from "axios";
import jwt_decode from "jwt-decode";

const axiosObj = () => {
    const api = "http://localhost:4040/api";

    axios.defaults.withCredentials = true;

    let headers = {};
    const access_token = localStorage.getItem("access_token");

    headers.authorization = "Bearer " + access_token;

    const axiosInstance = axios.create({
        baseURL: api,
        headers,
    });
    let isRefreshing = false;

    axiosInstance.interceptors.response.use(
        function (response) {
            return response;
        },
        (error) => {
            const {
                config,
                response: { status },
            } = error;
            const originalRequest = config;
            if (error.response.status !== 401) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            // Logout user if token refresh didn't work or user is disabled
            if (
                error.config.url === "/auth/refresh-token" ||
                error.response.message === "refresh token expired"
            ) {
                localStorage.clear();
                return new Promise((resolve, reject) => {
                    resolve(error.response);
                });
            }

            if (!isRefreshing) {
                if (status === 401) {
                    return axios
                        .post(api + "/auth/refresh-token", {
                            refresh_token: localStorage.getItem("refresh_token"),
                        })
                        .then((response) => {
                            isRefreshing = false;
                            const decoded_info = jwt_decode(response.data.access_token);
                            localStorage.setItem("access_token", response.data.access_token);
                            localStorage.setItem("user", JSON.stringify(decoded_info.user));
                            localStorage.setItem("refresh_token", response.data.refresh_token);

                            originalRequest.headers["authorization"] =
                                "Bearer " + response.data.access_token;
                            // return axios(originalRequest)

                            return new Promise((resolve, reject) => {
                                axios(originalRequest)
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((err) => {
                                        resolve(err.response);
                                    });
                            });
                        })
                        .catch((err) => {
                            isRefreshing = false;
                            localStorage.clear();
                            return Promise.resolve(err.response);
                        });
                }
            }
        }
    );
    return axiosInstance;
};

export default axiosObj;
