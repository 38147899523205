import { takeLatest, put, take } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import { BankAccountHeadContants as BAHConstant } from "Actions/constants";
import { BankAccountConstants as BAConstant } from "Actions/constants";
import { BankAccountLedgerConstants as BALConstant } from "Actions/constants";
import { failed as BARequestFail, getTransactions } from "Actions/Vendors/bankAccounts.action";
import { getAll as getLedgers } from "Actions/Vendors/bankAccountLedger.action";

const BAendPoint = "/vendor/bank-accounts/accounts";
const BAHendPoint = "/vendor/bank-accounts/account-head";
const BALendPoint = "/vendor/bank-accounts/ledger";

const BATransactionEndPoint = "/vendor/bank-accounts/transactions";
const approvalEndPoint = "/vendor/bank-accounts/approval-transactions";

const BASagaWatchers = BaseSaga(BAendPoint, BARequestFail);
const BAHSagaWatchers = BaseSaga(BAHendPoint, BARequestFail);
const BALSagaWatchers = BaseSaga(BALendPoint, BARequestFail);

const getAllBankAccount = function* (action) {
    yield BASagaWatchers.getRequest(action, BAendPoint + "/" + action.payload.id);
};

const getLedgerTransaction = function* (action) {
    yield put(getLedgers({ id: action.payload.id }));
    yield take(BALConstant.GET_ALL_SUCCESS);

    yield put(
        getTransactions({
            accountNo: action.payload.accountNo,
            accountHeadId: action.payload.accountHeadId,
            ledgerId: action.payload.ledgerId,
        })
    );
};

const getAllLedgers = function* (action) {
    yield BASagaWatchers.getRequest(action, BALendPoint + "/" + action.payload.id);
};

const createTransaction = function* (action) {
    yield BASagaWatchers.create(action, BATransactionEndPoint);
};

const getAllTransactions = function* (action) {
    let endPoint =
        BATransactionEndPoint + "/" + action.payload.accountHeadId + "/" + action.payload.accountNo;
    if (action.payload.ledgerId) {
        endPoint += "/" + action.payload.ledgerId;
    }
    yield BASagaWatchers.getRequest(action, endPoint);
};

const getApprovalTransactions = function* (action) {
    yield BASagaWatchers.create(action, approvalEndPoint + "/filter");
};

const approveTransactions = function* (action) {
    yield BASagaWatchers.create(action, approvalEndPoint);
};

const deleteTempTransaction = function* (action) {
    yield BASagaWatchers.create(action, approvalEndPoint + "/delete");
};

const deleteTransaction = function* (action) {
    yield BASagaWatchers.create(action, BATransactionEndPoint + "/delete");
};

const SagaActions = {
    createBankAccountHead: BAHSagaWatchers.create,
    getAllBankAccountHead: BAHSagaWatchers.getAll,
    getBankAccountHead: BAHSagaWatchers.getOne,
    updateBankAccountHead: BAHSagaWatchers.update,
    removeBankAccountHead: BAHSagaWatchers.removePut,

    createBankAccount: BASagaWatchers.create,
    getAllBankAccount: getAllBankAccount,
    getBankAccount: BASagaWatchers.getOne,
    updateBankAccount: BASagaWatchers.update,
    removeBankAccount: BASagaWatchers.removePut,

    createLedger: BALSagaWatchers.create,
    getAllLedgers: getAllLedgers,
    getLedger: BALSagaWatchers.getOne,
    updateLedger: BALSagaWatchers.update,
    removeLedger: BALSagaWatchers.removePut,
    createTransaction,
    getAllTransactions,
    getLedgerTransaction,
    getApprovalTransactions,
    approveTransactions,
    deleteTempTransaction,
    deleteTransaction,
};

export function* watchBankAccountRequest() {
    yield takeLatest(BAHConstant.CREATE, SagaActions.createBankAccountHead);
    yield takeLatest(BAHConstant.UPDATE, SagaActions.updateBankAccountHead);
    yield takeLatest(BAHConstant.GET_ALL, SagaActions.getAllBankAccountHead);
    //  yield takeLatest(BAHConstant.GET, SagaActions.getBankAccountHead);
    yield takeLatest(BAHConstant.DELETE, SagaActions.removeBankAccountHead);

    yield takeLatest(BAConstant.CREATE, SagaActions.createBankAccount);
    yield takeLatest(BAConstant.UPDATE, SagaActions.updateBankAccount);
    yield takeLatest(BAConstant.GET_ALL, SagaActions.getAllBankAccount);
    //yield takeLatest(BAConstant.GET, SagaActions.getBankAccount);
    yield takeLatest(BAConstant.DELETE, SagaActions.removeBankAccount);

    yield takeLatest(BAConstant.GET_LEDGER_TRANSACTION, SagaActions.getLedgerTransaction);

    yield takeLatest(BALConstant.CREATE, SagaActions.createLedger);
    yield takeLatest(BALConstant.UPDATE, SagaActions.updateLedger);
    yield takeLatest(BALConstant.GET_ALL, SagaActions.getAllLedgers);
    //yield takeLatest(BAConstant.GET, SagaActions.getLedger);
    yield takeLatest(BALConstant.DELETE, SagaActions.removeLedger);

    //Transaction
    yield takeLatest(BAConstant.GET_ALL_TRANSACTION, SagaActions.getAllTransactions);
    yield takeLatest(BAConstant.CREATE_TRANSACTION, SagaActions.createTransaction);
    yield takeLatest(BAConstant.DELETE_TRANSACTION, SagaActions.deleteTransaction);

    yield takeLatest(BAConstant.BA_GET_APPROVAL_TRANSACTIONS, SagaActions.getApprovalTransactions);
    yield takeLatest(BAConstant.BA_APPROVE_TRANSACTIONS, SagaActions.approveTransactions);

    yield takeLatest(BAConstant.BA_DELETE_TEMP_TRANSACTION, SagaActions.deleteTempTransaction);
}
