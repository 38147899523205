import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    Input,
    DatePickerInput,
    TransactionStatusBadge,
    TransactionType,
} from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/fixedDeposits.action";
import * as Utils from "Helpers/utils";
import { FD_TRANSACTIONS, TRANSACTION_DEPOSIT } from "Actions/constants";

import DataTable from "Components/UI/DataTable";
import Scheme from "./Scheme";
import InterestPay from "./InterestPay";

const Transactions = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [schemeId, setSchemeId] = useState("");
    const [showInterestDialog, setInterestDialog] = useState(false);

    const [state, setState] = useState({
        accountNo: "",
        accountHolderName: "",
        depositDate: null,
        balance: 0,
        transactionDate: new Date(),
        maturityDate: new Date(),
        amount: 0,
        maturityAmount: 0,
        interestRate: 0,
        noOfDays: 0,
        transactionType: TRANSACTION_DEPOSIT,
        certificateNo: "",
        voucherNo: "",
        particulars: "",
        name: "",
        fdId: null,
    });

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.fixedDeposits);
    const account = stateObject.account;
    const transactions = stateObject.transactions;
    const balance = stateObject.balance;

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const handleChange = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const calcMaturityAmount = (
        scheme,
        interestRate,
        depositAmount,
        days,
        transactionDate,
        maturityDate
    ) => {
        const tenurePeriodVal = 365;
        let maturityAmount = parseFloat(depositAmount);
        if (scheme.isDouble === 1) {
            let date1 = new Date(transactionDate);
            const date2 = new Date(maturityDate);

            while (date1 <= date2) {
                const date = moment(date1).format("YYYY-MM-DD");
                const noOfDays = parseInt(moment(date, "YYYY-MM").daysInMonth());
                const interest =
                    (parseFloat(interestRate) * parseFloat(maturityAmount)) / 100 / 365;
                maturityAmount += noOfDays * interest;
                date1 = moment(date1).add(1, "month").calendar();
                date1 = new Date(date1);
            }
        } else {
            maturityAmount =
                parseFloat(depositAmount) +
                ((parseFloat(depositAmount) * parseFloat(interestRate)) /
                    100 /
                    parseInt(tenurePeriodVal)) *
                    parseInt(days);
        }
        if (isNaN(maturityAmount)) {
            maturityAmount = 0;
        }

        return Math.round(maturityAmount);
    };

    const getInterest = (field, value) => {
        let transactionDate = state.transactionDate;
        let maturityDate = state.maturityDate;
        let amount = state.amount;
        const scheme = stateObject.schemes.find((item) => schemeId === item.schemeId);
        if (!scheme) {
            return;
        }
        const data = JSON.parse(scheme.data);
        switch (field) {
            case "transactionDate":
                transactionDate = value;
                break;
            case "amount":
                amount = parseFloat(value);
                break;
            case "maturityDate":
                maturityDate = value;
                break;
            default:
        }
        const days = Utils.dateDiff(transactionDate, maturityDate) + 1;
        const interestObj = Utils.getInterestRate(data, days);
        const maturityAmount = calcMaturityAmount(
            scheme,
            parseFloat(interestObj.depositInterest),
            parseFloat(amount),
            parseInt(days),
            transactionDate,
            maturityDate
        );
        setState({
            ...state,
            noOfDays: days,
            [field]: value,
            interestRate: interestObj.depositInterest,
            maturityAmount,
        });
    };

    useEffect(() => {
        if (account) {
            setState({ ...state, name: account.name });
        }
    }, [account]);

    useEffect(() => {
        dispatch(Actions.getAllSchemes());
    }, [dispatch]);

    const getTransactions = () => {
        setAlertStatus(true);
        dispatch(Actions.getTransactions({ id: state.accountNo, schemeId: schemeId }));
    };

    const submitForm = (e) => {
        setAlertStatus(true);
        e.preventDefault();
        const data = {
            schemeId,
            accountNo: state.accountNo,
            certificateNo: state.certificateNo,
            voucherNo: state.voucherNo,
            transactionDate: Utils.formatDate(state.transactionDate),
            maturityDate: Utils.formatDate(state.maturityDate),
            interestRate: state.interestRate,
            transactionType: state.transactionType,
            amount: state.amount,
            maturityAmount: state.maturityAmount,
            particulars: state.particulars,
        };
        dispatch(Actions.createTransaction(data));
    };

    const resetform = () => {};

    const deleteTransaction = (transactionId) => {
        setAlertStatus(true);
        const data = {
            objId: schemeId,
            objType: FD_TRANSACTIONS,
            accountNo: state.accountNo,
            transactionId,
        };
        dispatch(Actions.deleteTransaction(data));
    };

    const showBadge = (cell, row) => {
        return <TransactionStatusBadge status={row.status} row={row} />;
    };

    const getInterestPaid = (data) => {
        dispatch(Actions.getFDInterestDetails({ ...data }));
    };

    const actionButtons = (cell, row) => {
        const showButtons = () => {
            return (
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            variant="danger"
                            onClick={() => {
                                deleteTransaction(row.id);
                            }}
                            className="btn btn-sm"
                            title="Delete Transaction"
                        >
                            <i className="fa fa-trash fa-lg" aria-hidden="true" />
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                console.log(row.transactionDate);
                                setState({
                                    ...state,
                                    fdId: row.id,
                                    depositDate: row.transactionDate,
                                    interestRate: row.interestRate,
                                    deposit: row.deposit,
                                });
                                getInterestPaid({
                                    accountNo: state.accountNo,
                                    schemeId,
                                    fdId: row.id,
                                });
                                setInterestDialog(true);
                            }}
                            className="btn btn-sm ml-5px"
                        >
                            Pay Interest
                        </Button>
                    </Col>
                </Row>
            );
        };
        return row.status === 1 ? showButtons() : "---";
    };

    const formatTransactionType = (cell, row) => {
        const typeStr = Utils.getTransactionTypeStr(cell);
        return <TransactionType type={cell} typeStr={typeStr} />;
    };

    const columns = [
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "maturityDate",
            text: "Maturity Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "particulars",
            text: "Particulars",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "certificateNo",
            text: "Certificate No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "transactionType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatTransactionType,
        },
        {
            dataField: "deposit",
            text: "Deposit",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "withdraw",
            text: "Withdraw",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const pageNavItems = [
        {
            title: "Fixed Deposit",
            active: true,
            to: "/fixed-deposits/accounts",
        },
        {
            title: "Transaction",
            active: false,
            to: "/fixed-deposits/transaction",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Fixed Deposit Transactions">
            <Card>
                <Card.Body>
                    <InterestPay
                        showInterestDialog={showInterestDialog}
                        setInterestDialog={setInterestDialog}
                        interestRate={state.interestRate}
                        deposit={state.deposit}
                        fdId={state.fdId}
                        accountNo={state.accountNo}
                        schemeId={schemeId}
                        depositDate={state.depositDate}
                        setAlertStatus={setAlertStatus}
                    />
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <Col md={6}>
                                        <Scheme
                                            schemeId={schemeId}
                                            setSchemeId={setSchemeId}
                                            setAlertStatus={setAlertStatus}
                                            errMessage={errors["schemeId"]}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={6}>
                                                <Input
                                                    type="text"
                                                    label="Enter Account Number"
                                                    errMessage={errors["accountNo"]}
                                                    name="accountNo"
                                                    value={state.accountNo}
                                                    onChange={(e) => {
                                                        handleChange("accountNo", e.target.value);
                                                    }}
                                                    controlId="AccountNo"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <br />
                                                <Button
                                                    size="lg"
                                                    className="mt-6px"
                                                    variant="primary"
                                                    onClick={getTransactions}
                                                    type="Button"
                                                >
                                                    Load Account
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={state.name}
                                            label="Account Holder Name"
                                            placeholder="Account Holder Name"
                                            readOnly={true}
                                            onChange={(e) => {}}
                                            controlId="Name"
                                        />
                                    </Col>

                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="certificateNo"
                                            value={state.certificateNo}
                                            label="Enter Certificate Number"
                                            placeholder="Enter Certificate Number"
                                            onChange={(e) => {
                                                handleChange("certificateNo", e.target.value);
                                            }}
                                            controlId="certificateNo"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="voucherNo"
                                            value={state.voucherNo}
                                            label="Enter Voucher Number"
                                            placeholder="Enter Voucher Number"
                                            onChange={(e) => {
                                                handleChange("voucherNo", e.target.value);
                                            }}
                                            controlId="voucherNo"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <DatePickerInput
                                            errMessage={errors["transactionDate"]}
                                            icon="fa-calendar"
                                            label="Transaction Date"
                                            dateFormat="yyyy-MM-dd"
                                            selected={state.transactionDate}
                                            onChange={(date) => {
                                                //handleChange("transactionDate", date);
                                                getInterest("transactionDate", date);
                                            }}
                                            onBlur={(e) =>
                                                getInterest("maturityDate", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <DatePickerInput
                                            errMessage={errors["maturityDate"]}
                                            icon="fa-calendar"
                                            label="Maturity Date"
                                            dateFormat="yyyy-MM-dd"
                                            selected={state.maturityDate}
                                            onChange={(date) => {
                                                //handleChange("maturityDate", date);
                                                getInterest("maturityDate", date);
                                            }}
                                            onBlur={(e) =>
                                                getInterest("maturityDate", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="noOfDays"
                                            errMessage={errors["noOfDays"]}
                                            value={state.noOfDays}
                                            label="No Of Days"
                                            placeholder="No Of Days"
                                            onChange={(e) =>
                                                handleChange("noOfDays", e.target.value)
                                            }
                                            controlId="noOfDays"
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="interestRate"
                                            errMessage={errors["interestRate"]}
                                            value={state.interestRate}
                                            label="Interest Rate"
                                            placeholder="Interest Rate"
                                            onChange={(e) =>
                                                handleChange("interestRate", e.target.value)
                                            }
                                            controlId="interestRate"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="amount"
                                            errMessage={errors["amount"]}
                                            value={state.amount}
                                            label="Enter Deposit Amount"
                                            placeholder="Enter Deposit Amount"
                                            onChange={(e) => {
                                                //handleChange("amount", e.target.value);
                                                getInterest("amount", e.target.value);
                                            }}
                                            onBlur={(e) =>
                                                //handleChange("amount", e.target.value);
                                                getInterest("amount", e.target.value)
                                            }
                                            controlId="Amount"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="maturityAmount"
                                            errMessage={errors["maturityAmount"]}
                                            value={state.maturityAmount}
                                            label="Maturity Amount"
                                            placeholder="Maturity Amount"
                                            onChange={(e) =>
                                                getInterest("maturityAmount", e.target.value)
                                            }
                                            controlId="maturityAmount"
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            type="text"
                                            name="particulars"
                                            value={state.particulars}
                                            label="Particulars"
                                            placeholder="Particulars"
                                            onChange={(e) => {
                                                handleChange("particulars", e.target.value);
                                            }}
                                            controlId="Particulars"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>{Utils.getAccountDetails({ ...account, balance })}</Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Button variant="primary" type="Submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={resetform} type="Button">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <div className="text-center mt-4">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                        <Row>
                            <Col md="12">
                                {transactions ? (
                                    <DataTable
                                        keyField="id"
                                        columns={columns}
                                        data={transactions}
                                        search={true}
                                        pagination={true}
                                        button={false}
                                        className="transaction-table"
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default Transactions;
