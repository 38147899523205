import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Input, SearchableSelect } from "Components/UI/Input";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/bankAccounts.action";
import * as LedgerActions from "Actions/Vendors/bankAccountLedger.action";

const AccountsDialog = (props) => {
    const [state, setState] = useState({
        parentId: props.parentId,
        accountName: "",
        interestRate: 0,
        printOrder: 0,
        status: true,
        type: 2,
        transactionReverse: false,
    });

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.bankAccounts);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const bankAccount = stateObject.bankAccount;

    useEffect(() => {
        if (props.accountId) dispatch(Actions.getOne({ id: props.accountId }));
    }, [dispatch, props.accountId]);

    const setProps = () => {
        setState({
            ...state,
            interestRate: bankAccount.interestRate,
            transactionReverse: bankAccount.transactionReverse === 1 ? true : false,
            accountName: bankAccount.displayName,
            printOrder: bankAccount.printOrder,
            status: bankAccount.status === 1 ? true : false,
        });
    };

    useEffect(() => {
        if (bankAccount && props.accountId) {
            setProps();
        } else {
            setState({
                accountName: "",
                interestRate: 0,
                printOrder: 0,
                status: true,
                type: 2,
                transactionReverse: false,
            });
        }
    }, [bankAccount, props.accountId]);

    const resetForm = () => {
        setState({
            parentId: props.parentId,
            accountName: "",
            interestRate: 0,
            printOrder: 0,
            status: true,
            type: 2,
            transactionReverse: false,
        });
        if (props.accountId) {
            setProps();
        }
    };

    const submitForm = () => {
        props.setAlertStatus(true);
        let data = {
            parentId: props.parentId,
            name: state.accountName,
            printOrder: state.printOrder,
            status: state.status ? 1 : 0,
            interestRate: state.interestRate,
            type: state.type,
            transactionReverse: state.transactionReverse ? 1 : 0,
        };
        if (props.accountId) {
            data = {
                ...data,
                id: props.accountId,
            };
            dispatch(Actions.update(data));
        } else {
            dispatch(Actions.create(data));
        }
        //resetForm();
    };

    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>New Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="accountName"
                            controlId="formAccountName"
                            label="Enter Account Name"
                            placeholder="Enter Account Name"
                            value={state.accountName}
                            onChange={(e) => {
                                onChangeHandler("accountName", e.target.value);
                            }}
                            errMessage={errors["name"]}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="interestRate"
                            controlId="forminterestRate"
                            label="Interest Rate"
                            value={state.interestRate}
                            onChange={(e) => {
                                onChangeHandler("interestRate", e.target.value);
                            }}
                            errMessage={errors["interestRate"]}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="printOrder"
                            controlId="formprintOrder"
                            label="Print Order"
                            value={state.printOrder}
                            onChange={(e) => {
                                onChangeHandler("printOrder", e.target.value);
                            }}
                            errMessage={errors["printOrder"]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <label>Reverse Transaction</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={state.transactionReverse}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Yes"
                            offlabel="No"
                            onChange={(checked) => {
                                onChangeHandler("transactionReverse", checked);
                            }}
                        />
                    </Col>

                    <Col md={4}>
                        <label>Status</label>
                        <br />
                        <BootstrapSwitchButton
                            checked={state.status}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            onlabel="Active"
                            offlabel="In-Active"
                            onChange={(checked) => {
                                onChangeHandler("status", checked);
                            }}
                        />
                    </Col>

                    <Col md={4} className="mt-5px">
                        <br />
                        <Button variant="primary" onClick={submitForm}>
                            Submit
                        </Button>
                        &nbsp;
                        <Button variant="secondary" onClick={resetForm}>
                            Reset
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>{errors["parentId"]}</Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

const Accounts = (props) => {
    const [accountId, setAccountId] = useState(props.accountId);
    const [showDialog, setDialog] = useState(false);
    const stateObject = useSelector((state) => state.bankAccounts);

    const accountOptions = Utils.getSearchableOptions(
        stateObject.bankAccounts,
        "displayName",
        "accountNo"
    );

    const dispatch = useDispatch();

    const getLedgers = (id) => {
        if (id) {
            if (props.loadTransactions) {
                dispatch(
                    Actions.getLedgerTransactions({
                        id,
                        accountNo: id,
                        accountHeadId: props.accountHeadId,
                        ledgerId: props.ledgerId,
                    })
                );
            } else {
                dispatch(LedgerActions.getAll({ id }));
            }
        }
    };

    return (
        <>
            <AccountsDialog
                showDialog={showDialog}
                setDialog={setDialog}
                setAlertStatus={props.setAlertStatus}
                accountId={props.accountId}
                parentId={props.accountHeadId}
            />
            <Row>
                <Col md={10} style={{ paddingRight: "0px" }}>
                    <SearchableSelect
                        label="Select Account"
                        Options={accountOptions}
                        controlId="formAccount"
                        name="account"
                        value={accountId}
                        onChange={(selectedOption) => {
                            if (selectedOption.length > 0) {
                                if (props.setLedgerId) props.setLedgerId("");
                                setAccountId(selectedOption[0].value);
                                props.handleChange(selectedOption[0].value);
                                getLedgers(selectedOption[0].value);
                            }
                        }}
                    />
                </Col>
                <Col md={2} style={{ paddingLeft: "1px" }}>
                    <br />
                    <Button
                        variant="secondary"
                        className="mt-7px"
                        onClick={() => {
                            setDialog(props.accountHeadId ? true : false);
                        }}
                    >
                        ...
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default Accounts;
