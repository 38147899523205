import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Button, Row, Col, Modal } from "react-bootstrap";
import DataTable from "Components/UI/DataTable";
import { StatusBadge, ButtonLink, Input, SearchableSelect, FileInput } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/pigmyAccount.action";

const PigmyFileUpload = (props) => {
    return (
        <Modal show={props.showUpload} onHide={props.setShowUpload} centered="true">
            <Modal.Header closeButton>
                <Modal.Title>Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <FileInput
                            name="dataFile"
                            label="Upload Pigmy File"
                            value={props.dataFile}
                            placeholder="Upload Pigmy File"
                            accept=".dat"
                            errMessage=""
                            controlId="formDataFile"
                            onChange={(e) => props.setDataFile(e.target.files[0])}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        props.setShowUpload(false);
                    }}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={props.uploadFile}>
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const PigmyFileDownload = (props) => {
    return (
        <Modal show={props.showDownload} onHide={props.setShowDownload} centered="true">
            <Modal.Header closeButton>
                <Modal.Title>Download File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={8}>
                        <SearchableSelect
                            label="Select Pigmy Agent"
                            Options={props.agentOptions}
                            value={props.agentId}
                            controlId="AgentId"
                            name="formAgentId"
                            onChange={(selectedOption) => {
                                if (selectedOption.length > 0) {
                                    props.setAgentId(selectedOption[0].value);
                                }
                            }}
                        />
                    </Col>
                    <Col md={4}>
                        <br />
                        <Button
                            className="mt-5px"
                            variant="primary"
                            onClick={props.generatePigmyFile}
                        >
                            Generate File
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

const TransactionDialog = (props) => {
    const columns = [
        {
            dataField: "id",
            text: "Account No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "name",
            text: "Name",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "amount",
            text: "Amount",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "balance",
            text: "Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "createdAt",
            text: "Created at",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
    ];
    const BoxView = () => {};
    return (
        <Modal
            show={props.transactionDialog}
            onHide={props.setTransactionDialog}
            size="lg"
            top="true"
        >
            <Modal.Header closeButton>
                <Modal.Title>Transactions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTable
                    keyField="id"
                    columns={columns}
                    data={props.transactions}
                    search={true}
                    pagination={true}
                    button={false}
                    boxView={BoxView}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        props.setTransactionDialog(false);
                    }}
                >
                    Close
                </Button>
                {props.downloadButton ? Utils.getDataFile(props.filePath) : ""}
            </Modal.Footer>
        </Modal>
    );
};

const PigmyAccountCardView = (props) => {
    const [accountHolders, setAccountHolders] = useState([]);
    const [search, setSearch] = useState("");
    useEffect(() => {
        setAccountHolders(props.accountHolders);
    }, [props.accountHolders]);
    const filterAccountHolders = (array, keyword) => {
        const searchTerm = keyword.toLowerCase();
        return array.filter((value) => {
            return (
                value.name.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.address.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.mobile.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.email.toLowerCase().match(new RegExp(searchTerm, "g"))
            );
        });
    };

    const handleSearch = async (value) => {
        if (value.length > 2) {
            let result = await filterAccountHolders(props.accountHolders, value);
            setAccountHolders(result);
        } else {
            setAccountHolders(props.accountHolders);
        }
    };
    const CardViewHeader = () => {
        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="search"
                                value={search}
                                placeholder="Search"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    handleSearch(e.target.value);
                                }}
                            />
                        </Col>
                        <Col md={8} className="text-right">
                            <Button
                                size="lg"
                                variant="outline-secondary"
                                className="ml-5px mr-5px "
                                title="Upload File"
                                onClick={() => {
                                    props.setShowUpload(true);
                                }}
                            >
                                <i className="fa fa-upload fa-lg" aria-hidden="true"></i> Upload
                            </Button>
                            <Button
                                size="lg"
                                variant="outline-secondary"
                                className="ml-5px mr-5px "
                                title="Download File"
                                onClick={() => {
                                    props.setShowDownload(true);
                                }}
                            >
                                <i className="fa fa-download fa-lg" aria-hidden="true"></i> Download
                            </Button>
                            <Button
                                className="ml-5px mr-5px"
                                variant="outline-secondary"
                                onClick={() => {
                                    props.setAlertStatus(false);
                                    props.setCardView(false);
                                }}
                            >
                                <i className="fa fa-table fa-lg" aria-hidden="true" />
                            </Button>

                            <ButtonLink
                                variant="success"
                                to="/pigmy-account/add"
                                type="button"
                                label="Create Pigmy Account"
                                icon="fa fa-plus"
                                size="lg"
                                onClick={() => {}}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };
    return (
        <>
            {CardViewHeader()}
            <Row className="CardView">
                {accountHolders ? (
                    accountHolders.map((item, idx) => {
                        return (
                            <Col md={4} key={"cardView" + idx}>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={4}>
                                                {Utils.getProfileImage(item.profileImage)}
                                            </Col>
                                            <Col md={8}>
                                                <div className="RowItem">
                                                    <label>Account No </label>
                                                    {item.id}
                                                </div>
                                                <div className="RowItem">
                                                    <label>Agent</label>
                                                    {item.agentName}
                                                </div>
                                                <div className="RowItem">
                                                    <label>Name </label>{" "}
                                                    <Button
                                                        variant="link"
                                                        as={Link}
                                                        to={`/pigmy-account/${item.id}`}
                                                        className="text-left"
                                                    >
                                                        {item.name}
                                                    </Button>
                                                </div>
                                                <div className="RowItem">
                                                    <label>Mobile </label> {item.mobile}
                                                </div>
                                                <div className="RowItem">
                                                    <label>Email </label>
                                                    {item.email ? item.email : "NA"}
                                                </div>

                                                <div className="RowItem">
                                                    <label>Status </label>
                                                    {props.showBadge(item.status, item)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="RowItem mt-10px ht-50px">
                                            <Col md={4}>
                                                <label>Address</label>
                                            </Col>
                                            <Col md={8}>{item.address}</Col>
                                        </Row>

                                        <br />
                                        <Row className="action-buttons">
                                            <Col md={4}>
                                                <Button
                                                    className="text-primary"
                                                    variant="link"
                                                    as={Link}
                                                    to={`/pigmy-account/${item.id}`}
                                                >
                                                    <i
                                                        className="fa fa-edit fa-lg"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            </Col>
                                            <Col md={4}>
                                                <Button
                                                    variant="link"
                                                    as={Link}
                                                    to={`/pigmy-account/${item.id}`}
                                                >
                                                    <i
                                                        className="fa fa-list fa-lg"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            </Col>
                                            <Col md={4}>
                                                <Button
                                                    variant="link"
                                                    onClick={() => {
                                                        props.removeUser(item.id);
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-trash fa-lg"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })
                ) : (
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={12} className="text-center">
                                    There are no pigmy accounts created yet!
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                )}{" "}
            </Row>
        </>
    );
};

const PigmyAccountTableView = (props) => {
    const BoxView = () => {
        return (
            <>
                <Button
                    size="lg"
                    variant="outline-secondary"
                    className="ml-5px mr-5px "
                    title="Upload File"
                    onClick={() => {
                        props.setShowUpload(true);
                    }}
                >
                    <i className="fa fa-upload fa-lg" aria-hidden="true"></i> Upload
                </Button>
                <Button
                    size="lg"
                    variant="outline-secondary"
                    className="ml-5px mr-5px "
                    title="Download File"
                    onClick={() => {
                        console.log("HERE");
                        props.setShowDownload(true);
                    }}
                >
                    <i className="fa fa-download fa-lg" aria-hidden="true"></i> Download
                </Button>
                <Button
                    size="lg"
                    variant="outline-secondary"
                    onClick={() => {
                        props.setCardView(true);
                    }}
                    title="Card View"
                >
                    <i className="fa fa-th-large fa-lg" aria-hidden="true"></i>
                </Button>
            </>
        );
    };

    const columns = [
        {
            dataField: "agentName",
            text: "Agent",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "mobile",
            text: "Mobile",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },

        {
            dataField: "address",
            text: "Address",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "maturityDate",
            text: "Maturity Date",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: props.showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created at",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: props.actionButtons,
        },
    ];
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <DataTable
                            keyField="id"
                            columns={columns}
                            data={props.accountHolders}
                            search={true}
                            pagination={true}
                            button={true}
                            buttonTitle="Create Pigmy Account"
                            buttonURI="/pigmy-account/add"
                            buttonVariant="success"
                            boxView={BoxView}
                        />
                    </Col>
                </Row>
                <div className="text-center mt-5">
                    <RequestStatus
                        stateObj={props.stateObject}
                        alertStatus={props.alertStatus}
                        setAlertStatus={props.setAlertStatus}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

const PigmyAccountDetails = () => {
    const [alertStatus, setAlertStatus] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [downloadButton, setDownloadButton] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [cardView, setCardView] = useState(false);
    const [agentId, setAgentId] = useState("");
    const [dataFile, setDataFile] = useState(null);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [filePath, setFilePath] = useState(null);

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.pigmyAccount);

    const agents = stateObject.agents;
    const agentOptions = Utils.getSearchableOptions(agents, "name", "id");

    const stateAccountHolders = stateObject.accountHolders;

    const downloadTransactions = stateObject.downloadTransactions;
    const uploadTransactions = stateObject.uploadTransactions;

    useEffect(() => {
        if (Array.isArray(downloadTransactions) && downloadTransactions.length) {
            setFilePath(stateObject.filePath);
            setTransactionDialog(true);
            setTransactions(downloadTransactions);
            setDownloadButton(true);
            setShowDownload(false);
        }
    }, [downloadTransactions]);

    useEffect(() => {
        if (Array.isArray(uploadTransactions) && uploadTransactions.length) {
            setTransactionDialog(true);
            setTransactions(uploadTransactions);
            setDownloadButton(false);
            setShowUpload(false);
        }
    }, [uploadTransactions]);

    const showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    useEffect(() => {
        dispatch(Actions.pigmyAccountDetailsDependency());
    }, [dispatch]);

    const removeAccount = (id) => {
        dispatch(Actions.remove({ id }));
    };

    const generatePigmyFile = () => {
        setAlertStatus(true);
        dispatch(Actions.downloadTransaction({ agentId }));
    };

    const uploadFile = () => {
        setAlertStatus(true);
        const payload = {
            dataFile,
        };
        dispatch(Actions.uploadFile(payload));
    };

    const actionButtons = (cell, row) => {
        return (
            <Row>
                <Col md={3}>
                    <Button variant="link" as={Link} to={`/pigmy-account/${row.id}`}>
                        <i className="fa fa-edit fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
                <Col md={3}>
                    <Button variant="link" as={Link} to={`/pigmy-account/${row.id}`}>
                        <i className="fa fa-list fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
                <Col md={3}>
                    <Button
                        variant="link"
                        onClick={() => {
                            removeAccount(row.id);
                        }}
                    >
                        <i className="fa fa-trash fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
    };

    const pageNavItems = [
        {
            title: "Pigmy Account",
            active: true,
            to: "/pigmy-account",
        },
        {
            title: "Details",
            active: false,
            to: "/pigmy-account/",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Pigmy Account">
            <TransactionDialog
                transactions={transactions}
                transactionDialog={transactionDialog}
                setTransactionDialog={setTransactionDialog}
                downloadButton={downloadButton}
                filePath={filePath}
            />
            <PigmyFileUpload
                showUpload={showUpload}
                setShowUpload={setShowUpload}
                agentOptions={agentOptions}
                agentId={agentId}
                setAgentId={setAgentId}
                dataFile={dataFile}
                setDataFile={setDataFile}
                uploadFile={uploadFile}
            />
            <PigmyFileDownload
                showDownload={showDownload}
                setShowDownload={setShowDownload}
                agentOptions={agentOptions}
                agentId={agentId}
                setAgentId={setAgentId}
                generatePigmyFile={generatePigmyFile}
            />
            {cardView ? (
                <PigmyAccountCardView
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                    showBadge={showBadge}
                    removeAccount={removeAccount}
                    accountHolders={stateAccountHolders}
                    stateObject={stateObject}
                    setCardView={setCardView}
                    showUpload={showUpload}
                    setShowUpload={setShowUpload}
                    showDownload={showDownload}
                    setShowDownload={setShowDownload}
                />
            ) : (
                <PigmyAccountTableView
                    alertStatus={alertStatus}
                    setAlertStatus={setAlertStatus}
                    actionButtons={actionButtons}
                    showBadge={showBadge}
                    removeAccount={removeAccount}
                    accountHolders={stateAccountHolders}
                    stateObject={stateObject}
                    setCardView={setCardView}
                    showUpload={showUpload}
                    setShowUpload={setShowUpload}
                    showDownload={showDownload}
                    setShowDownload={setShowDownload}
                />
            )}
        </Layout>
    );
};

export default PigmyAccountDetails;
