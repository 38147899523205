import { takeLatest, put, take } from "redux-saga/effects";
import { failed } from "Actions/Vendors/dayBeginEnd.actions";
import { dayBeginEndConstants } from "Actions/constants";
import BaseSaga from "../base.saga";

const endPoint = "/vendor/day-begin-end";

const BASagaWatchers = BaseSaga(endPoint, failed);

const getOpeningDate = function* (action) {
    yield BASagaWatchers.getRequest(action, endPoint + "/get-opening-date");
};
const SagaActions = {
    getAll: BASagaWatchers.getAll,
    getOpeningDate,
};

export function* watchDayBeginEndRequests() {
    yield takeLatest(dayBeginEndConstants.GET_CURRENT_OPENING_DATE, SagaActions.getOpeningDate);
    yield takeLatest(dayBeginEndConstants.GET_ALL, SagaActions.getAll);
}
