import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxInput, StatusBadge, ApproveStatusBadge } from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/approveDeleted.action";
import * as Utils from "Helpers/utils";

import DataTable from "Components/UI/DataTable";

const ApproveDeletedTransaction = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [approveDate, setApproveDate] = useState(new Date());

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.deletedTransactions);
    const transactions = stateObject.transactions;

    useEffect(() => {
        dispatch(Actions.getTransactions());
    }, [dispatch]);

    const pageNavItems = [
        {
            title: "Approve Deleted Transactions",
            active: false,
            to: "/deleted-transaction-approval",
        },
    ];

    const actionButtons = (cell, row) => {
        return (
            <Row>
                <Col md={12} className="text-center">
                    <Button
                        variant="link"
                        onClick={() => {
                            deleteTransaction(row);
                        }}
                    >
                        <i className="fa fa-trash fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
    };

    const showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    const showApproveBadge = (cell, row) => {
        return <ApproveStatusBadge status={row.approveStatus} />;
    };

    const deleteTransaction = (params) => {
        setAlertStatus(true);
        const data = {
            id: params.id,
            transactionId: params.transactionId,
            accountNo: params.accountNo,
            objType: params.objType,
            objId: params.objId,
            approveDate: Utils.formatDate(approveDate),
        };
        dispatch(Actions.deleteTransaction(data));
    };

    const setTransactionSelection = (id, selected) => {
        dispatch(Actions.setTransactionSelection({ id, selected }));
    };

    const selectAll = () => {
        dispatch(Actions.setSelectedAll());
    };

    const formatSelectedOptions = (cell, row) => {
        if (row.selected) {
            return (
                <CheckBoxInput
                    checked={true}
                    controlId={"formCheck" + row.id}
                    onClick={(e) => {
                        setTransactionSelection(row.id, e.target.checked);
                    }}
                />
            );
        } else {
            return (
                <CheckBoxInput
                    checked={false}
                    controlId={"formCheck" + row.id}
                    onClick={(e) => {
                        setTransactionSelection(row.id, e.target.checked);
                    }}
                />
            );
        }
    };

    const formatType = (cell, row) => {
        return cell.toUpperCase();
    };

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        let selectedTxns = transactions.map((item) => (item.selected ? item : null));

        let finalTxns = selectedTxns.filter((item) => {
            if (item) {
                return item;
            }
        });

        dispatch(
            Actions.approveTransactions({
                transactions: finalTxns,
                approveDate: Utils.formatDate(approveDate),
            })
        );
    };

    const columns = [
        {
            dataField: "selected",
            text: "Select",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatSelectedOptions,
        },
        {
            dataField: "objType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatType,
        },
        {
            dataField: "accountNo",
            text: "Account No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "name",
            text: "Created By",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "approvedBy",
            text: "Approved By",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: (cell, row) => {
                if (!cell) {
                    return "---";
                }
                return cell;
            },
        },
        {
            dataField: "approveStatus",
            text: "Approve Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showApproveBadge,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "createdAt",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Approve Deleted Transactions">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        {transactions && Object.keys(transactions).length !== 0 ? (
                            <>
                                <Row>
                                    <Col md="12">
                                        <DataTable
                                            keyField="id"
                                            columns={columns}
                                            data={transactions}
                                            search={false}
                                            pagination={false}
                                            button={false}
                                            className="transaction-table"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <br />
                                        <Button
                                            variant="secondary"
                                            onClick={selectAll}
                                            type="Button"
                                        >
                                            Select All
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button variant="primary" type="Submit">
                                            Approve
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <Row>
                                <Col md={12} className="text-center bold">
                                    <h4>There are no transactions available</h4>
                                </Col>
                            </Row>
                        )}

                        <div className="text-center">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default ApproveDeletedTransaction;
