import { takeLatest, put, take } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import {
    SavingAccountConstants as ActionConstants,
    AccountHolderConstants,
} from "Actions/constants";
import {
    failed,
    getAllSchemes,
    getAll as getAccounts,
    getApprovalTransactions as fetchApprovalTransactions,
    deleteTransaction as deleteSBTransaction,
} from "Actions/Vendors/savingAccount.action";
import { getAll as getAllAcountHolders } from "Actions/Vendors/accountHolder.action";

import { watchTransactionDeleteRequest } from "./deleteTransaction.saga";

const endPoint = "/vendor/saving-account";

const sagaWatchers = BaseSaga(endPoint, failed);

const getSBDependencies = function* (action) {
    yield put(getAllAcountHolders());
    yield take(AccountHolderConstants.GET_ALL_SUCCESS);
    yield put(getAllSchemes());
    yield take(ActionConstants.GET_ALL_SAVING_ACCOUNT_SCHEMES_SUCCESS);
    yield put(getAccounts());
    yield put({ type: ActionConstants.GET_SAVING_ACCOUNT_DEPENDENCIES_SUCCESS });
};

const createScheme = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/scheme");
};

const updateScheme = function* (action) {
    yield sagaWatchers.update(action, endPoint + "/scheme");
};

const getSchemes = function* (action) {
    yield sagaWatchers.getAll(action, endPoint + "/scheme");
};

const createTransaction = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/transaction");
};

const getTransactions = function* (action) {
    yield sagaWatchers.getAll(
        action,
        endPoint + "/" + action.payload.id + "/" + action.payload.schemeId
    );
};

const getApprovalTransactions = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/get-approval-transactions");
};

const approveTransactions = function* (action) {
    yield sagaWatchers.create(action, endPoint + "/approve-transactions");
};

const deleteApprovalTransaction = function* (action) {
    yield put(deleteSBTransaction({ ...action.payload }));
    yield take(ActionConstants.DELETE_SAVING_ACCOUNT_TRANSACTION_SUCCESS);
    yield put(
        fetchApprovalTransactions({
            schemeId: action.payload.objId,
            transactionDate: action.payload.transactionDate,
        })
    );
};

function* watchGetAccountRequest(action) {
    yield sagaWatchers.getAll(
        action,
        endPoint + "/" + action.payload.accountNo + "/" + action.payload.schemeId
    );
}

const SagaActions = {
    ...sagaWatchers,
    createScheme,
    updateScheme,
    createTransaction,
    getSBDependencies,
    getSchemes,
    getTransactions,
    getApprovalTransactions,
    approveTransactions,
};

export function* watchSavingAccountRequests() {
    yield takeLatest(
        ActionConstants.GET_SAVING_ACCOUNT_DEPENDENCIES,
        SagaActions.getSBDependencies
    );

    yield takeLatest(ActionConstants.CREATE, SagaActions.create);
    yield takeLatest(ActionConstants.UPDATE, SagaActions.update);
    yield takeLatest(ActionConstants.GET_ALL, SagaActions.getAll);
    yield takeLatest(ActionConstants.GET, watchGetAccountRequest);
    yield takeLatest(ActionConstants.DELETE, SagaActions.remove);

    yield takeLatest(ActionConstants.CREATE_SAVING_ACCOUNT_SCHEME, SagaActions.createScheme);
    yield takeLatest(ActionConstants.UPDATE_SAVING_ACCOUNT_SCHEME, SagaActions.updateScheme);
    yield takeLatest(ActionConstants.GET_ALL_SAVING_ACCOUNT_SCHEMES, SagaActions.getSchemes);

    yield takeLatest(
        ActionConstants.CREATE_SAVING_ACCOUNT_TRANSACTION,
        SagaActions.createTransaction
    );
    yield takeLatest(
        ActionConstants.GET_ALL_SAVING_ACCOUNT_TRANSACTIONS,
        SagaActions.getTransactions
    );
    yield takeLatest(
        ActionConstants.DELETE_SAVING_ACCOUNT_TRANSACTION,
        watchTransactionDeleteRequest
    );
    yield takeLatest(
        ActionConstants.DELETE_SAVING_ACCOUNT_APPROVAL_TRANSACTION,
        deleteApprovalTransaction
    );
    yield takeLatest(
        ActionConstants.GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION,
        SagaActions.getApprovalTransactions
    );

    yield takeLatest(
        ActionConstants.APPROVE_SAVING_ACCOUNT_TRANSACTION,
        SagaActions.approveTransactions
    );
}
