import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { SearchableSelect } from "Components/UI/Input";
import * as Actions from "Actions/Vendors/loanAccounts.action";

const FDLoan = (props) => {
    const [fdSchemeId, setFDSchemeId] = useState(null);
    const [fdAccountNo, setFDAccountNo] = useState(null);
    const FdAccountOptions = [];
    const FDSchemeOptions = [];
    return (
        <Row>
            <Col md="4">
                <SearchableSelect
                    name="fdSchemeId"
                    controlId="formsetFDSchemeId"
                    label="Select Scheme Holder"
                    Options={FDSchemeOptions}
                    errMessage={props.errors["fdSchemeId"]}
                    value={fdSchemeId}
                    onChange={(selectedOption) => {
                        if (selectedOption.length > 0) {
                            setFDSchemeId(selectedOption[0].value);
                        }
                    }}
                />
            </Col>
            <Col md="4">
                <SearchableSelect
                    name="fdAccountNo"
                    controlId="formfdAccountNo"
                    label="Select FD Account Certificate"
                    Options={FdAccountOptions}
                    errMessage={props.errors["fdAccountNo"]}
                    value={fdAccountNo}
                    onChange={(selectedOption) => {
                        if (selectedOption.length > 0) {
                            setFDSchemeId(selectedOption[0].value);
                        }
                    }}
                />
            </Col>
        </Row>
    );
};

export default FDLoan;
