import { ApproveDeletedConstants as ActionConstant } from "../constants";

const getTransactions = () => {
    return {
        type: ActionConstant.GET_DELETED_TRANSACTIONS,
        success: ActionConstant.GET_DELETED_TRANSACTIONS_SUCCESS,
        payload: null,
    };
};

const setSelectedAll = () => {
    return {
        type: ActionConstant.SET_SELECTED_APPROVAL_TRANSACTIONS,
        payload: null,
    };
};

const setTransactionSelection = (data) => {
    return {
        type: ActionConstant.SET_SELECTED_APPROVAL_TRANSACTION,
        payload: data,
    };
};

const deleteTransaction = (data) => {
    return {
        type: ActionConstant.DELETE_TRANSACTION,
        payload: data,
        success: ActionConstant.DELETE_TRANSACTION_SUCCESS,
    };
};

const approveTransactions = (data) => {
    return {
        type: ActionConstant.APPROVE_DELETED_TRANSACTIONS,
        success: ActionConstant.APPROVE_DELETED_TRANSACTIONS_SUCCESS,
        payload: data,
    };
};

const failed = (data) => {
    return {
        type: ActionConstant.DELETE_TRANSACTION_ERROR,
        payload: data,
    };
};

export {
    failed,
    getTransactions,
    deleteTransaction,
    setTransactionSelection,
    setSelectedAll,
    approveTransactions,
};
