import { takeLatest } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import { SocietyConstant } from "Actions/constants";
import { failed } from "Actions/Admin/society.action";

const endPoint = "/admin/society";

const sagaWatchers = BaseSaga(endPoint, failed);
const SagaActions = {
    ...sagaWatchers,
};

export function* watchSocietyRequests() {
    yield takeLatest(SocietyConstant.CREATE, SagaActions.create);
    yield takeLatest(SocietyConstant.UPDATE, SagaActions.update);
    yield takeLatest(SocietyConstant.DELETE, SagaActions.remove);
    yield takeLatest(SocietyConstant.GET_ALL, SagaActions.getAll);
    yield takeLatest(SocietyConstant.GET, SagaActions.getOne);
}
