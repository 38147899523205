import { authConstants } from "./constants";

export const login = (data) => {
	return {
		type: authConstants.LOGIN_REQUEST,
		payload: data,
	};
};

export const loginSuccess = (user) => {
	return {
		type: authConstants.LOGIN_SUCCESS,
		payload: user,
	};
};

export const loginError = (error) => {
	return {
		type: authConstants.LOGIN_ERROR,
		payload: error,
	};
};

export const logout = () => {
	return {
		type: authConstants.LOGOUT_REQUEST,
		payload: null,
	};
};
export const logoutSuccess = (data) => {
	return {
		type: authConstants.LOGOUT_SUCCESS,
		payload: data,
	};
};

export const refreshTokenRequest = (data) => {
	return {
		type: authConstants.TOKEN_REFRESH_REQUEST,
		payload: data,
	};
};

export const refreshTokenSuccess = (data) => {
	return {
		type: authConstants.TOKEN_REFRESH_SUCCESS,
		payload: data,
	};
};
export const isAuthenticated = () => {
	return {
		type: authConstants.IS_AUTHENTICATED,
		payload: null,
	};
};

export const getAuthenticatedUser = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	return user;
};
