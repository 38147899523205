import getActions from "Actions/base.action";
import { data } from "jquery";
import { PigmyAccountConstants } from "../constants";

const baseActions = getActions(PigmyAccountConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getAgents = () => {
    return {
        type: PigmyAccountConstants.GET_PIGMY_AGENTS,
        payload: null,
        success: PigmyAccountConstants.GET_PIGMY_AGENTS_SUCCESS,
    };
};

const getPigmyAccount = (data) => {
    return {
        type: PigmyAccountConstants.GET_PIGMY_ACCOUNT,
        payload: data,
        success: PigmyAccountConstants.GET_PIGMY_ACCOUNT_SUCCESS,
    };
};

const getPigmyTransaction = (data) => {
    return {
        type: PigmyAccountConstants.GET_PIGMY_ACCOUNT_TRANSACTION,
        payload: data,
        success: PigmyAccountConstants.GET_PIGMY_ACCOUNT_TRANSACTION_SUCCESS,
    };
};

const pigmyTransaction = (data) => {
    return {
        type: PigmyAccountConstants.PIGMY_ACCOUNT_TRANSACTION,
        payload: data,
        success: PigmyAccountConstants.PIGMY_ACCOUNT_TRANSACTION_SUCCESS,
    };
};

const deleteApproveTransaction = (data) => {
    return {
        type: PigmyAccountConstants.PIGMY_DELETE_APPROVAL_TRANSACTION,
        success: PigmyAccountConstants.PIGMY_DELETE_APPROVAL_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const getApprovalTransactions = (data) => {
    return {
        type: PigmyAccountConstants.GET_PIGMY_APPROVAL_TRANSACTIONS,
        payload: data,
        success: PigmyAccountConstants.GET_PIGMY_APPROVAL_TRANSACTIONS_SUCCESS,
    };
};

const setSelectedAll = () => {
    return {
        type: PigmyAccountConstants.SET_SELECTED_APPROVAL_TRANSACTIONS,
        payload: null,
    };
};

const setTransactionSelection = (data) => {
    return { type: PigmyAccountConstants.SET_SELECTED_APPROVAL_TRANSACTION, payload: data };
};

const approveTransactions = (data) => {
    return {
        type: PigmyAccountConstants.POST_PIGMY_ACCOUNT_APPROVAL,
        payload: data,
        success: PigmyAccountConstants.POST_PIGMY_ACCOUNT_APPROVAL_SUCCESS,
    };
};

const pigmyDependencies = (data) => {
    return {
        type: PigmyAccountConstants.PIGMY_ACCOUNT_DEPENDENCIES,
        payload: data,
        success: PigmyAccountConstants.PIGMY_ACCOUNT_DEPENDENCIES_SUCCESS,
    };
};

const pigmyAccountDetailsDependency = () => {
    return {
        type: PigmyAccountConstants.PIGMY_ACCOUNT_DETAILS_DEPENDENCY,
        success: PigmyAccountConstants.PIGMY_ACCOUNT_DETAILS_DEPENDENCY_SUCCESS,
    };
};

const downloadTransaction = (data) => {
    return {
        type: PigmyAccountConstants.PIGMY_DOWNLOAD_TRANSACTIONS,
        success: PigmyAccountConstants.PIGMY_DOWNLOAD_TRANSACTIONS_SUCCESS,
        payload: data,
    };
};

const uploadFile = (data) => {
    return {
        type: PigmyAccountConstants.PIGMY_UPLOAD_TRANSACTIONS,
        success: PigmyAccountConstants.PIGMY_UPLOAD_TRANSACTIONS_SUCCESS,
        payload: data,
    };
};

const deleteTransaction = (data) => {
    return {
        type: PigmyAccountConstants.PIGMY_TRANSACTION_DELETE,
        success: PigmyAccountConstants.PIGMY_TRANSACTION_DELETE_SUCCESS,
        failed: PigmyAccountConstants["ERROR"],
        payload: data,
    };
};

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    failed,
    getPigmyAccount,
    pigmyDependencies,
    getAgents,
    getPigmyTransaction,
    pigmyTransaction,
    getApprovalTransactions,
    setSelectedAll,
    setTransactionSelection,
    approveTransactions,
    pigmyAccountDetailsDependency,
    downloadTransaction,
    uploadFile,
    deleteApproveTransaction,
    deleteTransaction,
};
