import React, { useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import { Input, Preloader } from "Components/UI/Input";
import { useDispatch, useSelector } from "react-redux";
import { login } from "Actions/auth";
import { Redirect } from "react-router-dom";
import logo from "Assets/Img/logo.svg";

const SignIn = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const auth = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const userSignIn = (e) => {
        e.preventDefault();
        const user = {
            username,
            password,
        };
        dispatch(login(user));
    };
    if (auth.authenticated) {
        return <Redirect to={`/`} />;
    }
    const getMessage = () => {
        if (auth.authenticating) {
            return <Preloader />;
        }
        if (auth.authFailure && auth.message) {
            return <Alert variant="danger">{auth.message}</Alert>;
        }
        if (auth.authenticated) {
            return <Alert variant="success">{auth.message}</Alert>;
        }
    };

    return (
        <Row className="no-margins min-vh-100 login-register">
            <Col md="6" className="no-margins">
                <div className="hero-wrap d-flex align-items-center h-100">
                    <div className="hero-mask opacity-8 bg-primary"></div>
                    <div className="hero-bg hero-bg-scroll"></div>
                    <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                        <div className="row no-gutters">
                            <div className="col-10 col-lg-10 mx-auto">
                                <div className="text-white mt-5 mb-5 mb-md-0">
                                    <img src={logo} alt="coreFin" width="250px" />
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters my-auto">
                            <div className="col-10 col-lg-9 mx-auto">
                                <h1 className="text-11 text-white mb-4">Welcome back!</h1>
                                <p className="text-4 text-white line-height-4 mb-5">
                                    <b className="appName">coreFin</b> is the platform for business
                                    transformation that helps financial institutions to accelerate
                                    their digital transformation initiatives. Reimagine the way you
                                    engage with your customers through both digital and physical
                                    channels creating a consistent and seamless experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="6" className="d-flex align-items-center">
                <div className="container my-auto py-5">
                    <Row>
                        <div className="col-11 col-lg-9 col-xl-8 mx-auto">
                            <h3 className="font-weight-600 mb-4">Hello! let's get started</h3>
                            <div>Sign in to continue</div>
                            <Form onSubmit={userSignIn} className="pt-4">
                                <Input
                                    name="email"
                                    value={username}
                                    placeholder="Enter mobile number "
                                    errMessage=""
                                    type="text"
                                    controlId="formUserName"
                                    icon="fa fa-user fa-lg"
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <div className="pt-3"></div>
                                <Input
                                    name="password"
                                    placeholder="Password"
                                    errMessage=""
                                    value={password}
                                    type="password"
                                    controlId="formPassword"
                                    icon="fa fa-lock fa-lg"
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <Row className="show-grid offset-6">
                                    <Col xs={12} className="text-right px-0">
                                        <Button variant="link">Forgot Password?</Button>
                                    </Col>
                                </Row>

                                <div className="d-grid gap-2">
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        block="true"
                                        className="btn btn-primary btn-block "
                                        type="submit"
                                    >
                                        Login
                                    </Button>
                                </div>
                                <br />
                                <div align="center">{getMessage()}</div>
                            </Form>
                        </div>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};
export default SignIn;
