import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Button, Row, Col } from "react-bootstrap";
import DataTable from "Components/UI/DataTable";
import { Input, StatusBadge, ButtonLink } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/accountHolder.action";

const AccountHolderDetails = (props) => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [cardView, setCardView] = useState(false);
    const [accountHolders, setAccountHolders] = useState(null);
    const [search, setSearch] = useState("");

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.accountHolder);
    const stateAccountHolders = stateObject.accountHolders;

    const pageNavItems = [
        {
            title: "Account Holders",
            active: false,
            to: "/account-holders",
        },
    ];

    useEffect(() => {
        if (stateAccountHolders) {
            setAccountHolders(stateAccountHolders);
        }
    }, [stateAccountHolders]);

    useEffect(() => {
        dispatch(Actions.getAll());
    }, [dispatch]);

    const showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    const removeUser = (id) => {
        dispatch(Actions.remove({ id }));
    };

    const actionButtons = (cell, row) => {
        return (
            <>
                <Button variant="link" as={Link} to={`/account-holders/${row.id}`}>
                    <i className="fa fa-edit fa-lg" aria-hidden="true" />
                </Button>
                <Button variant="link" as={Link} to={`/account-holders/${row.id}/details`}>
                    <i className="fa fa-list fa-lg" aria-hidden="true" />
                </Button>
                <Button
                    variant="link"
                    onClick={() => {
                        removeUser(row.id);
                    }}
                >
                    <i className="fa fa-trash fa-lg" aria-hidden="true" />
                </Button>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "mobile",
            text: "Mobile",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "email",
            text: "Email",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "address",
            text: "Address",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "createdAt",
            text: "Created at",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.formatDateInTable,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const filterAccountHolders = (array, keyword) => {
        const searchTerm = keyword.toLowerCase();
        return array.filter((value) => {
            return (
                value.name.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.address.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.mobile.toLowerCase().match(new RegExp(searchTerm, "g")) ||
                value.email.toLowerCase().match(new RegExp(searchTerm, "g"))
            );
        });
    };

    const handleSearch = async (value) => {
        if (value.length > 2) {
            let result = await filterAccountHolders(stateAccountHolders, value);
            setAccountHolders(result);
        } else {
            setAccountHolders(stateAccountHolders);
        }
    };

    const BoxView = () => {
        return (
            <Button
                size="lg"
                variant="outline-secondary"
                onClick={() => {
                    setCardView(true);
                }}
            >
                <i className="fa fa-th-large fa-lg" aria-hidden="true"></i>
            </Button>
        );
    };
    const CardViewHeader = () => {
        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="search"
                                value={search}
                                placeHolder="Search"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    handleSearch(e.target.value);
                                }}
                            />
                        </Col>
                        <Col md={8} className="text-right">
                            <Button
                                className="ml-5px mr-5px"
                                variant="outline-secondary"
                                onClick={() => {
                                    setCardView(false);
                                }}
                            >
                                <i className="fa fa-table fa-lg" aria-hidden="true" />
                            </Button>

                            <ButtonLink
                                variant="success"
                                to="/account-holders/add"
                                type="button"
                                label="Create Account Holders"
                                icon="fa fa-plus"
                                size="lg"
                                onClick={() => {}}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const getAccountHolderCards = () => {
        return (
            <>
                {CardViewHeader()}
                <Row className="CardView">
                    {accountHolders ? (
                        accountHolders.map((item, idx) => {
                            return (
                                <Col md={4} key={"cardView" + idx}>
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col md={4}>
                                                    {Utils.getProfileImage(item.profileImage)}
                                                </Col>
                                                <Col md={8}>
                                                    <div className="RowItem">
                                                        <label>Name </label>{" "}
                                                        <Button
                                                            variant="link"
                                                            as={Link}
                                                            to={`/account-holders/${item.id}`}
                                                            className="text-left"
                                                        >
                                                            {item.name}
                                                        </Button>
                                                    </div>
                                                    <div className="RowItem">
                                                        <label>Mobile </label> {item.mobile}
                                                    </div>
                                                    <div className="RowItem">
                                                        <label>Email </label>
                                                        {item.email ? item.email : "NA"}
                                                    </div>
                                                    <div className="RowItem">
                                                        <label>DOB </label>
                                                        {item.dob
                                                            ? Utils.formatDate(item.dob)
                                                            : "NA"}
                                                    </div>
                                                    <div className="RowItem">
                                                        <label>Status </label>
                                                        {showBadge(item.status, item)}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="RowItem mt-10px ht-50px">
                                                <Col md={4}>
                                                    <label>Address</label>
                                                </Col>
                                                <Col md={8}>{item.address}</Col>
                                            </Row>
                                            <Row className="RowItem mt-10px ht-50px">
                                                <Col md={4}>
                                                    <label>Permanent Address</label>
                                                </Col>
                                                <Col md={8}>{item.permanentAddress}</Col>
                                            </Row>
                                            <br />
                                            <Row className="action-buttons">
                                                <Col md={4}>
                                                    <Button
                                                        className="text-primary"
                                                        variant="link"
                                                        as={Link}
                                                        to={`/account-holders/${item.id}`}
                                                    >
                                                        <i
                                                            className="fa fa-edit fa-lg"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                </Col>
                                                <Col md={4}>
                                                    <Button
                                                        variant="link"
                                                        as={Link}
                                                        to={`/account-holders/${item.id}/details`}
                                                    >
                                                        <i
                                                            className="fa fa-list fa-lg"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                </Col>
                                                <Col md={4}>
                                                    <Button
                                                        variant="link"
                                                        onClick={() => {
                                                            removeUser(item.id);
                                                        }}
                                                    >
                                                        <i
                                                            className="fa fa-trash fa-lg"
                                                            aria-hidden="true"
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })
                    ) : (
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={12} className="text-center">
                                        There are no account holders created yet!
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )}{" "}
                </Row>
            </>
        );
    };

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Account Holders">
            {cardView ? (
                getAccountHolderCards()
            ) : (
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <DataTable
                                    keyField="id"
                                    columns={columns}
                                    data={stateObject.accountHolders}
                                    search={true}
                                    pagination={true}
                                    button={true}
                                    buttonTitle="Create Account Holders"
                                    buttonURI="/account-holders/add"
                                    buttonVariant="success"
                                    boxView={BoxView}
                                />
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                    </Card.Body>
                </Card>
            )}
        </Layout>
    );
};

export default AccountHolderDetails;
