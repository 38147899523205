import getActions from "Actions/base.action";
import { AccountHolderConstants } from "../constants";

const baseActions = getActions(AccountHolderConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;
export { create, update, remove, getAll, getOne, failed };
