import React from "react";
import { Form, Spinner, InputGroup, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import imageUploadIcon from "Assets/Img/add-item-image.svg";
import {
    TRANSACTION_DEPOSIT,
    TRANSACTION_WITHDRAW,
    TRANSACTION_INTEREST_RECIEVED,
    TRANSACTION_INTEREST_PAID,
    TRANSACTION_CHARGES,
    TRANSACTION_CONTRA,
} from "Actions/constants";
import DatePicker from "react-datepicker";

/**
 * @author
 * @function Input
 **/
export const NomineeInput = (props) => {
    if (!props.icon) {
        return (
            <Form.Group controlid={"form" + props.name}>
                {props.label ? (
                    <>
                        <span className="pull-left">
                            <Form.Label>{props.label}</Form.Label>{" "}
                        </span>
                        <span className="pull-right">
                            <Button
                                className="padding-0px"
                                variant="link"
                                onClick={props.setNominee}
                            >
                                Nominee Same as Account Holder
                            </Button>
                        </span>
                    </>
                ) : (
                    ""
                )}
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
                <Form.Text className="text-danger">{props.errMessage}</Form.Text>
            </Form.Group>
        );
    }
    return (
        <>
            {props.label ? (
                <>
                    <span className="pull-left">
                        <Form.Label>{props.label}</Form.Label>{" "}
                    </span>
                    <span className="pull-right">
                        <Button className="padding-0px" variant="link" onClick={props.setNominee}>
                            Nominee Same as Account Holder
                        </Button>
                    </span>
                </>
            ) : (
                ""
            )}

            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className={props.icon} size="16" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
            </InputGroup>
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </>
    );
};

export const Input = (props) => {
    if (!props.icon) {
        return (
            <Form.Group controlid={"form" + props.name}>
                {props.label ? <Form.Label>{props.label}</Form.Label> : ""}
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder ? props.placeholder : props.label}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
                <Form.Text className="text-danger">{props.errMessage}</Form.Text>
            </Form.Group>
        );
    }
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}

            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className={props.icon} size="16" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
            </InputGroup>
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </>
    );
};

export const InputButtonGroup = (props) => {
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}

            <InputGroup>
                <Form.Control
                    {...props}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
                <Button variant="secondary" size="sm" onClick={props.btnOnClick}>
                    {props.btnText}
                </Button>
            </InputGroup>
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </>
    );
};

export const TextArea = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                as="textarea"
                rows="3"
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                name={props.name}
            />
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </Form.Group>
    );
};

export const SearchInput = (props) => {
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fa fa-search"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    autoFocus={true}
                    ref={props.ref}
                />
            </InputGroup>
        </>
    );
};

export const SearchableSelect = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            <label>{props.label}</label>
            <InputGroup className="mb-3 searchable-select">
                <Select
                    value={props}
                    values={props.Options.filter((option) => option.value === props.value)}
                    options={props.Options}
                    onChange={props.onChange}
                />
            </InputGroup>

            <div className="text-danger">{props.errMessage}</div>
        </Form.Group>
    );
};
export const SelectInput = (props) => (
    <Form.Group controlid={"form" + props.name}>
        <Form.Label>{props.label}</Form.Label>
        <select
            name={props.name}
            value={props.value}
            id={props.id}
            onChange={props.handleChange}
            className="select select-control"
        >
            <option value="">Select</option>
            {Object.entries(props.Options).map(([k, v]) => (
                <option key={k} value={k}>
                    {v}
                </option>
            ))}
        </select>
        <Form.Text className="text-danger">{props.errMessage}</Form.Text>
    </Form.Group>
);

export const SelectInputGroup = (props) => {
    return (
        <select
            name={props.name}
            value={props.value}
            id={props.id}
            onChange={props.onChange}
            className="select form-control"
        >
            {Object.entries(props.Options).map(([k, v]) => (
                <option key={k} value={v}>
                    {k}
                </option>
            ))}
        </select>
    );
};
export const CustomButton = (props) => {
    if (!props.icon) {
        return (
            <Button
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
            >
                {props.label}
            </Button>
        );
    } else {
        return (
            <Button
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
            >
                <i className={props.icon} size="16" /> {props.label}
            </Button>
        );
    }
};

export const ButtonLink = (props) => {
    if (!props.icon) {
        return (
            <Button
                as={Link}
                to={props.to}
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
                size="lg"
            >
                {props.label}
            </Button>
        );
    } else {
        return (
            <Button
                as={Link}
                to={props.to}
                variant={props.variant}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
                size="lg"
            >
                <i className={props.icon} size="16" /> &nbsp;
                {props.label}
            </Button>
        );
    }
};

export const FileInput = (props) => {
    return (
        <Form.Group controlid={"form" + props.name}>
            {props.multiple ? (
                <Form.File
                    name={props.name}
                    label={props.label}
                    onChange={props.onChange}
                    accept={props.accept}
                    id={props.id}
                    className={props.className}
                    ref={props.ref}
                    multiple="multiple"
                />
            ) : (
                <Form.File
                    name={props.name}
                    label={props.label}
                    onChange={props.onChange}
                    accept={props.accept}
                    id={props.id}
                    className={props.className}
                    ref={props.ref}
                />
            )}
        </Form.Group>
    );
};

const renderCheckBoxInputChecked = (props) => {
    return (
        <Form.Check
            type={props.type ? props.type : "checkbox"}
            label={props.label}
            value={props.value}
            onClick={props.onClick}
            name={props.name}
            defaultChecked="true"
        />
    );
};
const renderCheckBoxInputDefault = (props) => {
    return (
        <Form.Check
            type={props.type ? props.type : "checkbox"}
            label={props.label}
            value={props.value}
            onClick={props.onClick}
            name={props.name}
        />
    );
};

export const CheckBoxInput = (props) => {
    return (
        <Form.Group controlid={props.controlId}>
            {props.checked ? renderCheckBoxInputChecked(props) : renderCheckBoxInputDefault(props)}
        </Form.Group>
    );
};

export const Preloader = (props) => {
    return <Spinner animation="border" />;
};

export const ImageUpload = (props) => {
    const fileInput = React.useRef(null);
    const chooseFile = () => {
        fileInput.current.click();
    };
    const handleFileInputChange = (event) => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded, props.fileIndex);
    };
    return (
        <>
            <img
                src={imageUploadIcon}
                alt="Upload Product"
                height="100px"
                accept="image/*"
                onClick={chooseFile}
            />
            <input
                type="file"
                id="file"
                ref={fileInput}
                onChange={handleFileInputChange}
                className="d-none"
            />
        </>
    );
};

export const DisplaySelectedImage = (props) => {
    const removeImage = () => {
        props.removeImage(props.index);
    };
    return (
        <div>
            <Button className="text-danger photo-delete" variant="link" onClick={removeImage}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </Button>
            <div className="product-image">
                <img src={props.src} alt="Product" width="100px" />
            </div>
        </div>
    );
};

export const DatePickerInput = (props) => {
    /*  const CustomInput = React.forwardRef((props, ref) => {
        return (
            <div
                className="form-control"
                style={{ padding: "0.75rem 10px !important" }}
                onClick={props.onClick}
            >
                <label ref={ref} style={{ paddingRight: "15px" }}>
                    {props.value || props.placeholder}
                </label>
                <i className="fa fa-calendar" style={{ float: "right", marginTop: "2px" }} />
            </div>
        );
    }); */

    //
    return (
        <Form.Group controlid={"form" + props.name}>
            {props.label ? <Form.Label>{props.label}</Form.Label> : ""}
            <br />
            <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={props.selected}
                onChange={props.onChange}
                className="form-control calendar_icon"
            />
            <Form.Text className="text-danger">{props.errMessage}</Form.Text>
        </Form.Group>
    );
};

export const StatusBadge = (props) => {
    if (props.status === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Active
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                De-Activated
            </Badge>
        );
    }
};

export const ApproveStatusBadge = (props) => {
    if (props.status === 0) {
        return (
            <Badge bg="warning" className="bg-warning">
                Approve Pending
            </Badge>
        );
    } else if (props.status === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Approved
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                Status Unknown
            </Badge>
        );
    }
};

export const TransactionStatusBadge = (props) => {
    if (props.row && props.row.approveStatus === 0 && props.status !== 3) {
        return (
            <Badge bg="warning" className="bg-warning">
                Approval Pending
            </Badge>
        );
    }
    if (props.status === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Active
            </Badge>
        );
    } else if (props.status === 2) {
        return (
            <Badge bg="warning" className="bg-warning">
                Approval Pending
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                Deleted
            </Badge>
        );
    }
};

export const InterestPaybleStatusBadge = (props) => {
    if (props.status === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Active
            </Badge>
        );
    } else if (props.status === 2) {
        return (
            <Badge bg="warning" className="bg-warning">
                Interest Paid
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                Deleted
            </Badge>
        );
    }
};

export const HeadOfficeBadge = (props) => {
    if (props.headOffice === 1) {
        return (
            <Badge bg="success" className="bg-success">
                Yes
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-danger">
                No
            </Badge>
        );
    }
};

export const TypeBadge = (props) => {
    if (props.type === 1) {
        return (
            <Badge bg="success" className="bg-info">
                Society
            </Badge>
        );
    } else {
        return (
            <Badge bg="danger" className="bg-warning">
                Finance
            </Badge>
        );
    }
};

export const TransactionType = ({ type, typeStr }) => {
    if (type === TRANSACTION_DEPOSIT) {
        return (
            <Badge pill bg="success" className="bg-success">
                {typeStr}
            </Badge>
        );
    } else if (type === TRANSACTION_WITHDRAW) {
        return (
            <Badge pill bg="danger" className="bg-danger">
                {typeStr}
            </Badge>
        );
    } else if (type === TRANSACTION_INTEREST_RECIEVED) {
        return (
            <Badge pill bg="info" className="bg-success">
                {typeStr}
            </Badge>
        );
    } else if (type === TRANSACTION_INTEREST_PAID) {
        return (
            <Badge pill bg="info" className="bg-warning">
                {typeStr}
            </Badge>
        );
    } else if (type === TRANSACTION_CHARGES) {
        return (
            <Badge pill bg="warning" className="bg-warning">
                {typeStr}
            </Badge>
        );
    } else if (type === TRANSACTION_CONTRA) {
        return (
            <Badge pill bg="warning" className="bg-success">
                {typeStr}
            </Badge>
        );
    }
    return (
        <Badge pill className="bg-secondary">
            {typeStr}
        </Badge>
    );
};
