import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePickerInput, InterestPaybleStatusBadge } from "Components/UI/Input";
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/fixedDeposits.action";
import * as Utils from "Helpers/utils";
import DataTable from "Components/UI/DataTable";
import Scheme from "./Scheme";

const getInterestDetailsColumns = () => {
    return [
        {
            dataField: "fixedDeposits.certificateNo",
            text: "Certificate No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountNo",
            text: "Account No.",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "scheme.schemeName",
            text: "Scheme",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountHolder.name",
            text: "Name",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "fixedDeposits.deposit",
            text: "Deposit",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "interest",
            text: "Interest Payble",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
    ];
};

const getInterestPaybleColumn = () => {
    return [
        {
            dataField: "certificateNo",
            text: "Certificate No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "accountNo",
            text: "Account No.",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "scheme",
            text: "Scheme",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "name",
            text: "Name",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "depositAmount",
            text: "Deposit",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "interestPaid",
            text: "Pending Interest Payble",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "interestPayble",
            text: "Interest Payble",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
    ];
};

const InterestPaybleDialog = (props) => {
    const [schemeId, setSchemeId] = useState("");
    const [interestDate, setInterestDate] = useState(new Date());
    const stateObject = useSelector((state) => state.fixedDeposits);
    const lastInterestPaid = stateObject.lastInterestPaid;
    const interestPaybles = props.onlyDetails
        ? stateObject.interestPaybleDetails
        : stateObject.interestPaybles;

    const dispatch = useDispatch();
    const calcInterestPayble = (e) => {
        props.setAlertStatus(true);
        e.preventDefault();
        dispatch(
            Actions.calcInterestPayble({
                lastInterestPaid,
                interestDate: Utils.formatDate(interestDate),
                schemeId,
            })
        );
    };

    const addInterestPayble = (e) => {
        props.setAlertStatus(true);
        e.preventDefault();
        const data = interestPaybles.map((payble) => {
            return {
                fdId: payble.fdId,
                accountNo: payble.accountNo,
                interest: payble.interestPayble,
            };
        });
        dispatch(
            Actions.addInterestPayble({
                lastInterestPaid,
                interestDate: Utils.formatDate(interestDate),
                schemeId,
                interestDetails: data,
                totalInterestPayble: stateObject.totalInterestPayble,
            })
        );
    };

    const columns = props.onlyDetails ? getInterestDetailsColumns() : getInterestPaybleColumn();
    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Interest Payble</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!props.onlyDetails ? (
                    <Form onSubmit={calcInterestPayble} key="form1">
                        <Row>
                            <Col md={4}>
                                <Scheme
                                    schemeId={schemeId}
                                    setSchemeId={setSchemeId}
                                    setAlertStatus={props.setAlertStatus}
                                    errMessage={props.errors["schemeId"]}
                                />
                            </Col>

                            <Col md={3}>
                                <DatePickerInput
                                    errMessage={props.errors["interestDate"]}
                                    icon="fa-calendar"
                                    label="Interest Date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={interestDate}
                                    onChange={(date) => {
                                        setInterestDate(date);
                                    }}
                                    value={interestDate}
                                />
                            </Col>
                            <Col md={3}>
                                <br />
                                <Button variant="primary" type="submit" className="mt-6px">
                                    Calculate Interest Payble
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    ""
                )}
                {interestPaybles ? (
                    <Form onSubmit={addInterestPayble} key="form2">
                        <Row key={1}>
                            <Col md={12}>
                                <DataTable
                                    key="interestDetails"
                                    keyField="id"
                                    columns={columns}
                                    data={interestPaybles}
                                    search={true}
                                    pagination={false}
                                    button={false}
                                    className="transaction-table"
                                />
                            </Col>
                        </Row>
                        {!props.onlyDetails ? (
                            <>
                                <Row key={2}>
                                    <Col md="12">
                                        <div className="float-right">
                                            <div className="row ">
                                                <div className="col-md-6">Total&nbsp;Interest</div>
                                                <div className="col-md-6">
                                                    {Utils.currencyFormat(
                                                        stateObject.totalInterest
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row  mt-10px">
                                                <div className="col-md-6">Total&nbsp;Payble</div>
                                                <div className="col-md-6">
                                                    {Utils.currencyFormat(
                                                        stateObject.totalInterestPayble
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row key={3}>
                                    <Col md={12} className="mt-5px text-center">
                                        <br />
                                        <Button variant="primary" type="submit" className="mt-6px">
                                            Make Provision
                                        </Button>
                                        &nbsp;
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            className="mt-6px"
                                            onClick={() => {
                                                props.setDialog(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            ""
                        )}
                    </Form>
                ) : (
                    ""
                )}
            </Modal.Body>
        </Modal>
    );
};

const getColumns = (props) => {
    const actionButtons = (cell, row) => {
        return (
            <>
                <Button
                    variant="primary"
                    type="button"
                    onClick={(e) => {
                        props.showDetails(row);
                    }}
                    className="btn btn-sm"
                >
                    <i className="fa fa-table"></i> Details
                </Button>
            </>
        );
    };

    const showBadge = (cell, row) => {
        return <InterestPaybleStatusBadge status={row.status} />;
    };

    return [
        {
            dataField: "interestDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "scheme.schemeName",
            text: "Scheme",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "totalInterestPayble",
            text: "Interest Payble",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "createdAdmin.name",
            text: "Created By",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];
};

const InterestDetails = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [schemeId, setSchemeId] = useState("");
    const [showDialog, setDialog] = useState(false);
    const [onlyDetails, setOnlyDetails] = useState(false);
    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.fixedDeposits);
    const interestDetails = stateObject.interestDetails;

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    useEffect(() => {
        dispatch(Actions.getInterestPaybleDependencies());
    }, [dispatch]);

    const getInterestPayble = (e) => {
        e.preventDefault();
        dispatch(Actions.getInterestPayble({ schemeId }));
    };

    const showDetails = (row) => {
        setOnlyDetails(true);
        setDialog(true);
        dispatch(
            Actions.getInterestPaybleDetails({ schemeId: row.schemeId, interestHeadId: row.id })
        );
    };

    const showInterestPay = (row) => {
        setOnlyDetails(false);
        setDialog(true);
    };

    const undoLastInterestPayble = () => {
        setAlertStatus(true);
        dispatch(Actions.undoLastInterestPayble({ schemeId }));
    };

    const tableActionButtons = () => {
        return schemeId ? (
            <div className="pull-right mr-5px ">
                <Button
                    variant="danger"
                    size="md"
                    type="button"
                    onClick={() => undoLastInterestPayble()}
                >
                    Undo Last Payble
                </Button>
            </div>
        ) : (
            ""
        );
    };

    const columns = getColumns({ showDetails });

    const pageNavItems = [
        {
            title: "Fixed Deposits",
            active: true,
            to: "/fixed-deposits",
        },
        {
            title: "Interest Details",
            active: false,
            to: "/fixed-deposits/interest-details",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="FD Interest Details">
            <Card>
                <Card.Body>
                    <InterestPaybleDialog
                        showDialog={showDialog}
                        setDialog={setDialog}
                        setAlertStatus={setAlertStatus}
                        errors={errors}
                        onlyDetails={onlyDetails}
                    />
                    <Form onSubmit={getInterestPayble}>
                        <Row>
                            <Col md={3}>
                                <Scheme
                                    schemeId={schemeId}
                                    setSchemeId={setSchemeId}
                                    setAlertStatus={setAlertStatus}
                                    errMessage={errors["schemeId"]}
                                />
                            </Col>
                            <Col md={3}>
                                <br />
                                <Button variant="primary" type="submit" className="mt-6px">
                                    Load Details
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md="12">
                                        <DataTable
                                            keyField="id"
                                            columns={columns}
                                            data={interestDetails}
                                            search={true}
                                            pagination={false}
                                            button={false}
                                            className="transaction-table"
                                            dialogButton={true}
                                            dialogAction={showInterestPay}
                                            buttonLabel="FD Interest Provision"
                                            actionButtons={tableActionButtons}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <div className="text-center mt-4">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    );
};
export default InterestDetails;
