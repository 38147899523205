import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, FormControl } from "react-bootstrap";
import { SelectInput, Input, SearchableSelect, InputButtonGroup } from "Components/UI/Input";
import * as Utils from "Helpers/utils";

import { getAgents, getPigmyAccount } from "Actions/Vendors/pigmyAccount.action";
import {
    getAllSchemes as getSchemes,
    getOne as getSavingAccount,
} from "Actions/Vendors/savingAccount.action";

const ContraTransfer = (props) => {
    const pigmyState = useSelector((state) => state.pigmyAccount);
    const savingAccountState = useSelector((state) => state.savingAccount);

    const agents = pigmyState.agents;

    let schemeAgentOptions = [];

    if (props.accountType == "ACCOUNT_TYPE_PIGMY") {
        schemeAgentOptions = Utils.getSearchableOptions(agents, "name", "id");
    } else {
        schemeAgentOptions = Utils.getSearchableOptions(
            savingAccountState.schemes,
            "schemeName",
            "schemeId"
        );
    }

    let placeholder = "Select Scheme";
    const getPlaceHolder = () => {
        if (props.accountType == "ACCOUNT_TYPE_PIGMY") {
            return "Select Agent";
        } else {
            return "Select Scheme";
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.accountType == "ACCOUNT_TYPE_PIGMY") {
            dispatch(getAgents());
        } else if (props.accountType == "ACCOUNT_TYPE_SAVING") {
            dispatch(getSchemes());
        }
        placeholder = getPlaceHolder();
    }, [props.accountType]);

    const getBalance = () => {
        if (props.accountType == "ACCOUNT_TYPE_PIGMY") {
            dispatch(getPigmyAccount({ agentId: props.schemeAgentId, accountNo: props.accountNo }));
        } else if (props.accountType == "ACCOUNT_TYPE_SAVING") {
            dispatch(
                getSavingAccount({ accountNo: props.accountNo, schemeId: props.schemeAgentId })
            );
        }
    };

    const showAccountDetails = () => {
        if (props.accountType == "ACCOUNT_TYPE_PIGMY") {
            return showPigmyAccountDetails();
        } else if (props.accountType == "ACCOUNT_TYPE_SAVING") {
            return showSavingAccountDetails();
        }
    };

    const showSavingAccountDetails = () => {
        if (savingAccountState.savingAccount) {
            return (
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            placeholder="Account Holder Name"
                            id="name"
                            name="name"
                            label="Name"
                            value={savingAccountState.savingAccount.name}
                            readOnly={true}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            placeholder="Balance"
                            id="balance"
                            name="balance"
                            label="Balance"
                            value={savingAccountState.savingAccount.balance}
                            readOnly={true}
                        />
                    </Col>
                </Row>
            );
        }
    };

    const showPigmyAccountDetails = () => {
        if (pigmyState.accountDetails && pigmyState.accountDetails.account) {
            return (
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            placeholder="Account Holder Name"
                            id="name"
                            name="name"
                            label="Name"
                            value={pigmyState.accountDetails.account.accountHolder.name}
                            readOnly={true}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            placeholder="Balance"
                            id="balance"
                            name="balance"
                            label="Balance"
                            value={pigmyState.accountDetails.balance}
                            readOnly={true}
                        />
                    </Col>
                </Row>
            );
        }
    };

    return (
        <>
            <Row>
                <Col md={4}>
                    <SelectInput
                        errMessage={props.errors["accountType"]}
                        name="accountType"
                        placeholder="Select Account Type"
                        label="Select Account Type"
                        type="text"
                        Options={Utils.accountTypes()}
                        value={props.accountType}
                        handleChange={(e) => props.onChangeHandler("accountType", e.target.value)}
                        controlId="accountType"
                    />
                </Col>
                <Col md={4}>
                    <SearchableSelect
                        Options={schemeAgentOptions}
                        label={getPlaceHolder()}
                        errMessage={props.errors["schemeAgentId"]}
                        value={props.schemeAgentId}
                        controlId="schemeAgentId"
                        name="schemeAgentId"
                        onChange={(selectedOption) => {
                            if (selectedOption.length > 0) {
                                props.onChangeHandler("schemeAgentId", selectedOption[0].value);
                            }
                        }}
                    />
                </Col>
                <Col md={4}>
                    <InputButtonGroup
                        type="text"
                        placeholder="Account No"
                        label="Account No"
                        name="toAccountNo"
                        id="toAccountNo"
                        value={props.accountNo}
                        onChange={(e) => props.onChangeHandler("accountNo", e.target.value)}
                        btnText="Show Balance"
                        btnOnClick={getBalance}
                    />

                    <Input
                        type="hidden"
                        placeholder="transferAmount"
                        id="transferAmount"
                        name="transferAmount"
                        value={props.transferAmount}
                    />
                </Col>
            </Row>

            {showAccountDetails()}
            <br />
        </>
    );
};
export default ContraTransfer;
