import { call, put } from "redux-saga/effects";
import * as REST from "Services/rest.service";
import { logout } from "Actions/auth";
import { TOKEN_EXPIRED } from "Actions/constants";

const message = "Request Failed!";
let err = new Error(message);
err.status = 500;

const BaseSaga = (endPoint, failed) => {
    const create = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(REST.post(url, action.payload));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                console.log(response.data);
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    const post = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(REST.post(url, action.payload));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                console.log(response.data);
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    const update = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            console.log(action.payload);
            let response = yield call(REST.put(url + "/" + action.payload.id, action.payload));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };
    const remove = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(REST.remove(url + "/" + action.payload.id, action.payload));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    const removePut = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(
                REST.removePut(url + "/" + action.payload.id, action.payload)
            );
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    const getOne = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(REST.get(url + "/" + action.payload.id, action.payload));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };
    const getAll = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(REST.get(url, action.payload));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    const getRequest = function* (action, url = null) {
        try {
            if (!url) {
                url = endPoint;
            }
            let response = yield call(REST.get(url));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    const postFormData = function* (action, formData, url = null) {
        try {
            let response = yield call(REST.postFormdData(url, formData));
            if (!response) {
                throw err;
            }
            if (response.status === 200) {
                yield put({ type: action.success, payload: response.data });
            } else if (response.data.status === TOKEN_EXPIRED) {
                yield put(logout());
            } else {
                yield put(failed({ ...response.data }));
            }
        } catch (err) {
            yield put(failed({ err }));
        }
    };

    return {
        create,
        update,
        remove,
        removePut,
        getAll,
        getOne,
        getRequest,
        postFormData,
        post,
    };
};
export default BaseSaga;
