import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    DatePickerInput,
    SearchableSelect,
    SelectInput,
    TransactionStatusBadge,
    TransactionType,
} from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/pigmyAccount.action";
import * as Utils from "Helpers/utils";
import { PIGMY_TRANSACTIONS } from "Actions/constants";

import DataTable from "Components/UI/DataTable";

const PigmyTransaction = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [agentId, setAgentId] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [name, setName] = useState("");
    const [balance, setBalance] = useState("0");
    const [transactionDate, setTransactionDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionType, setTransactionType] = useState("");
    const [voucherNo, setVoucherNo] = useState("");
    const [particulars, setParticulars] = useState("By Cash");
    const [transactions, setTransactions] = useState([]);

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.pigmyAccount);
    const pigmyAccount = stateObject.accountTransaction;
    const agents = stateObject.agents;

    const agentOptions = Utils.getSearchableOptions(agents, "name", "id");

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    useEffect(() => {
        if (pigmyAccount) {
            setName(pigmyAccount.name);
            setAmount(pigmyAccount.amount);
            setBalance(pigmyAccount.balance);
            setTransactions(pigmyAccount.transactions);
        }
    }, [pigmyAccount]);

    useEffect(() => {
        dispatch(Actions.getAgents());
    }, [dispatch]);

    const getTransactions = () => {
        setAlertStatus(true);
        dispatch(Actions.getPigmyTransaction({ id: accountNo, agentId }));
    };

    const submitForm = (e) => {
        setAlertStatus(true);
        e.preventDefault();
        const data = {
            agentId,
            accountNo,
            transactionDate: Utils.formatDate(transactionDate),
            transactionType,
            amount,
            voucherNo,
            particulars,
        };
        dispatch(Actions.pigmyTransaction(data));
    };

    const resetform = () => {};

    const deleteTransaction = (transactionId, balanceStatus) => {
        setAlertStatus(true);
        if (balanceStatus === 0) {
            dispatch(
                Actions.failed({
                    message: "Please delete latest transaction first",
                    errors: [],
                })
            );
        } else {
            const data = {
                objId: agentId,
                objType: PIGMY_TRANSACTIONS,
                accountNo,
                transactionId,
            };
            dispatch(Actions.deleteTransaction(data));
        }
    };

    const showBadge = (cell, row) => {
        return <TransactionStatusBadge status={row.status} row={row} />;
    };

    const actionButtons = (cell, row) => {
        return (
            <Row>
                <Col md={12} className="text-center">
                    <Button
                        variant="link"
                        onClick={() => {
                            deleteTransaction(row.id, row.balanceStatus);
                        }}
                    >
                        <i className="fa fa-trash fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
    };

    const formatTransactionType = (cell, row) => {
        const typeStr = Utils.getTransactionTypeStr(cell);
        return <TransactionType type={cell} typeStr={typeStr} />;
    };

    const columns = [
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "particulars",
            text: "Particulars",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "voucherNo",
            text: "Voucher No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "transactionType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatTransactionType,
        },
        {
            dataField: "deposit",
            text: "Deposit",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "withdraw",
            text: "Withdraw",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "balance",
            text: "Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const pageNavItems = [
        {
            title: "Pigmy Account",
            active: true,
            to: "/pigmy-account",
        },
        {
            title: "Transaction",
            active: false,
            to: "/pigmy-account",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Pigmy Transactions">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <Col md={6}>
                                        <SearchableSelect
                                            label="Select Pigmy Agent"
                                            Options={agentOptions}
                                            errMessage={errors["agentId"]}
                                            value={agentId}
                                            controlId="AgentId"
                                            name="formAgentId"
                                            onChange={(selectedOption) => {
                                                if (selectedOption.length > 0) {
                                                    setAgentId(selectedOption[0].value);
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={8}>
                                                <Input
                                                    type="text"
                                                    label="Enter Account Number"
                                                    errMessage={errors["accountNo"]}
                                                    name="accountNo"
                                                    value={accountNo}
                                                    onChange={(e) => {
                                                        setAccountNo(e.target.value);
                                                    }}
                                                    controlId="AccountNo"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <br />
                                                <Button
                                                    size="lg"
                                                    className="mt-6px"
                                                    variant="primary"
                                                    onClick={getTransactions}
                                                    type="Button"
                                                >
                                                    Load Account
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={name}
                                            label="Account Holder Name"
                                            placeholder="Account Holder Name"
                                            readOnly={true}
                                            onChange={(e) => {}}
                                            controlId="Name"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            type="text"
                                            name="balance"
                                            value={balance}
                                            label="Account Balance"
                                            placeholder="Account Balance"
                                            readOnly={true}
                                            onChange={(e) => {}}
                                            controlId="Balance"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <DatePickerInput
                                            errMessage={errors["transactionDate"]}
                                            icon="fa-calendar"
                                            label="Transaction Date"
                                            dateFormat="yyyy-MM-dd"
                                            selected={transactionDate}
                                            onChange={(date) => setTransactionDate(date)}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="amount"
                                            errMessage={errors["amount"]}
                                            value={amount}
                                            label="Enter Amount"
                                            placeholder="Enter Amount"
                                            onChange={(e) => setAmount(e.target.value)}
                                            controlId="Amount"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <SelectInput
                                            errMessage={errors["transactionType"]}
                                            name="transactionType"
                                            placeholder="Select Transaction Type"
                                            label="Select Transaction Type"
                                            type="text"
                                            Options={Utils.getTransactionTypes()}
                                            value={transactionType}
                                            handleChange={(e) => setTransactionType(e.target.value)}
                                            controlId="TransactionType"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="voucherNo"
                                            value={voucherNo}
                                            label="Enter Voucher Number"
                                            placeholder="Enter Voucher Number"
                                            onChange={(e) => {
                                                setVoucherNo(e.target.value);
                                            }}
                                            controlId="formVoucherNo"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            type="text"
                                            name="particulars"
                                            value={particulars}
                                            label="Particulars"
                                            placeholder="Particulars"
                                            onChange={(e) => {
                                                setParticulars(e.target.value);
                                            }}
                                            controlId="Particulars"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>{Utils.getAccountDetails(pigmyAccount)}</Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <br />
                                <Button variant="primary" type="Submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={resetform} type="Button">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                        <Row>
                            <Col md="12">
                                {transactions ? (
                                    <DataTable
                                        keyField="id"
                                        columns={columns}
                                        data={transactions}
                                        search={true}
                                        pagination={true}
                                        button={false}
                                        className="transaction-table"
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default PigmyTransaction;
