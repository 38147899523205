import { PigmyAccountConstants as ActionConstant } from "Actions/constants";
const initState = {
    errors: [],
    accountHolders: [],
    PROCESSING: false,
    REQUEST_SUCCESS: false,
    REQUEST_FAILED: false,
    accountHolder: null,
    accountDetails: {},
    accountTransaction: {},
    approvalTransactions: [],
    downloadTransactions: [],
    uploadTransactions: [],
    filePath: null,
    message: "",
    agents: [],
};
let selectedTransactions = [];

const PigmyAccountReducer = (state = initState, action) => {
    state = {
        ...state,
        errors: [],
        message: "",
        PROCESSING: false,
        REQUEST_SUCCESS: false,
        REQUEST_FAILED: false,
    };
    switch (action.type) {
        case ActionConstant.CREATE:
        case ActionConstant.UPDATE:
        case ActionConstant.DELETE:
        case ActionConstant.PIGMY_ACCOUNT_TRANSACTION:
        case ActionConstant.POST_PIGMY_ACCOUNT_APPROVAL:
        case ActionConstant.PIGMY_DELETE_APPROVAL_TRANSACTION:
        case ActionConstant.PIGMY_TRANSACTION_DELETE:
        case ActionConstant.GET_PIGMY_ACCOUNT:
            state = {
                ...state,
                PROCESSING: true,
            };
            break;
        case ActionConstant.CREATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;

        case ActionConstant.UPDATE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;

        case ActionConstant.DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                accountHolders: action.payload.objects,
            };
            break;
        case ActionConstant.GET_PIGMY_ACCOUNT_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                accountDetails: action.payload.accountDetails,
            };
            break;
        case ActionConstant.GET:
            state = {
                ...state,
                accountHolder: null,
                accountTransaction: {},
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                accountHolder: action.payload.object,
            };
            break;
        case ActionConstant.GET_ALL:
            state = {
                ...state,
                accountHolders: [],
                accountTransaction: {},
                downloadTransactions: [],
                uploadTransactions: [],
                accountHolder: null,
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_ALL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                accountHolders: action.payload.objects,
            };
            break;
        case ActionConstant.GET_PIGMY_AGENTS:
            state = {
                ...state,
                agents: [],
                approvalTransactions: [],
            };
            break;
        case ActionConstant.GET_PIGMY_AGENTS_SUCCESS:
            state = {
                ...state,
                agents: action.payload.agents,
            };
            break;
        case ActionConstant.GET_PIGMY_ACCOUNT_TRANSACTION:
            state = {
                ...state,
                accountTransaction: {},
                PROCESSING: true,
            };
            break;
        case ActionConstant.GET_PIGMY_ACCOUNT_TRANSACTION_SUCCESS:
            state = {
                ...state,
                accountTransaction: action.payload.data,
                REQUEST_SUCCESS: true,
            };
            break;

        case ActionConstant.PIGMY_ACCOUNT_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
            };
            break;
        case ActionConstant.GET_PIGMY_APPROVAL_TRANSACTIONS:
            state = {
                ...state,
                PROCESSING: true,
                approvalTransactions: [],
            };
            break;
        case ActionConstant.GET_PIGMY_APPROVAL_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                approvalTransactions: action.payload.data,
            };
            break;
        case ActionConstant.SET_SELECTED_APPROVAL_TRANSACTIONS:
            selectedTransactions = state.approvalTransactions.map((item) => {
                return {
                    ...item,
                    selected: true,
                };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;
        case ActionConstant.SET_SELECTED_APPROVAL_TRANSACTION:
            selectedTransactions = state.approvalTransactions.map((item) => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        selected: action.payload.selected,
                    };
                }
                return { ...item };
            });
            state = {
                ...state,
                approvalTransactions: selectedTransactions,
            };
            break;

        case ActionConstant.POST_PIGMY_ACCOUNT_APPROVAL_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                approvalTransactions: [],
            };
            break;
        case ActionConstant.PIGMY_DOWNLOAD_TRANSACTIONS:
            state = {
                ...state,
                PROCESSING: true,
                downloadTransactions: [],
                uploadTransactions: [],
            };
            break;
        case ActionConstant.PIGMY_DOWNLOAD_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                downloadTransactions: action.payload.transactions,
                filePath: action.payload.fileName,
                message: action.payload.message,
            };
            break;
        case ActionConstant.PIGMY_UPLOAD_TRANSACTIONS:
            state = {
                ...state,
                PROCESSING: true,
                downloadTransactions: [],
                uploadTransactions: [],
            };
            break;
        case ActionConstant.PIGMY_UPLOAD_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                uploadTransactions: action.payload.transactions,
                message: action.payload.message,
            };
            break;
        case ActionConstant.PIGMY_DELETE_APPROVAL_TRANSACTION_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                approvalTransactions: action.payload.data,
            };
            break;

        case ActionConstant.PIGMY_TRANSACTION_DELETE_SUCCESS:
            state = {
                ...state,
                REQUEST_SUCCESS: true,
                message: action.payload.message,
                accountTransaction: action.payload.data,
            };
            break;
        case ActionConstant.ERROR:
            state = {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors,
                REQUEST_FAILED: true,
            };
            break;
        default:
            return state;
    }
    return state;
};

export default PigmyAccountReducer;
