import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    DatePickerInput,
    SelectInput,
    TransactionStatusBadge,
    TransactionType,
} from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/currentAccount.action";
import * as Utils from "Helpers/utils";
import { CURRENT_TRANSACTIONS } from "Actions/constants";

import DataTable from "Components/UI/DataTable";
import Scheme from "./SchemeDialog";

const Transactions = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [schemeId, setSchemeId] = useState("");
    const [state, setState] = useState({
        accountNo: "",
        accountHolderName: "",
        balance: 0,
        transactionDate: new Date(),
        amount: 0,
        transactionType: null,
        voucherNo: "",
        particulars: "",
        name: "",
    });

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.currentAccount);
    const currentAccount = stateObject.currentAccount;
    const transactions = stateObject.transactions;

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const handleChange = (field, value) => {
        setState({ ...state, [field]: value });
    };

    useEffect(() => {
        if (currentAccount) {
            setState({ ...state, name: currentAccount.name, balance: currentAccount.balance });
        }
    }, [currentAccount]);

    useEffect(() => {
        dispatch(Actions.getAllSchemes());
    }, [dispatch]);

    const getTransactions = () => {
        setAlertStatus(true);
        dispatch(Actions.getAllTransactions({ id: state.accountNo, schemeId: schemeId }));
    };

    const submitForm = (e) => {
        setAlertStatus(true);
        e.preventDefault();
        const data = {
            schemeId: schemeId,
            accountNo: state.accountNo,
            transactionDate: Utils.formatDate(state.transactionDate),
            transactionType: state.transactionType,
            amount: state.amount,
            voucherNo: state.voucherNo,
            particulars: state.particulars,
        };
        dispatch(Actions.createTransaction(data));
    };

    const resetform = () => {};

    const deleteTransaction = (transactionId, balanceStatus) => {
        setAlertStatus(true);
        if (balanceStatus === 0) {
            dispatch(
                Actions.failed({
                    message: "Please delete latest transaction first",
                    errors: [],
                })
            );
        } else {
            const data = {
                objId: schemeId,
                objType: CURRENT_TRANSACTIONS,
                accountNo: state.accountNo,
                transactionId,
            };
            dispatch(Actions.deleteTransaction(data));
        }
    };

    const showBadge = (cell, row) => {
        return <TransactionStatusBadge status={row.status} row={row} />;
    };

    const actionButtons = (cell, row) => {
        const showButtons = () => {
            return (
                <Row>
                    <Col md={12} className="text-center">
                        <Button
                            variant="link"
                            onClick={() => {
                                deleteTransaction(row.id, row.balanceStatus);
                            }}
                        >
                            <i className="fa fa-trash fa-lg" aria-hidden="true" />
                        </Button>
                    </Col>
                </Row>
            );
        };
        return row.status !== 3 ? showButtons() : "---";
    };

    const formatTransactionType = (cell, row) => {
        const typeStr = Utils.getTransactionTypeStr(cell);
        return <TransactionType type={cell} typeStr={typeStr} />;
    };

    const columns = [
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "particulars",
            text: "Particulars",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "voucherNo",
            text: "Voucher No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "transactionType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatTransactionType,
        },
        {
            dataField: "deposit",
            text: "Deposit",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "withdraw",
            text: "Withdraw",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "balance",
            text: "Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const pageNavItems = [
        {
            title: "Current Account",
            active: true,
            to: "/current-account",
        },
        {
            title: "Transaction",
            active: false,
            to: "/current-account/transaction",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Current Account Transactions">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <Col md={6}>
                                        <Scheme
                                            schemeId={schemeId}
                                            setSchemeId={setSchemeId}
                                            setAlertStatus={setAlertStatus}
                                            errMessage={errors["schemeId"]}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={8}>
                                                <Input
                                                    type="text"
                                                    label="Enter Account Number"
                                                    errMessage={errors["accountNo"]}
                                                    name="accountNo"
                                                    value={state.accountNo}
                                                    onChange={(e) => {
                                                        handleChange("accountNo", e.target.value);
                                                    }}
                                                    controlId="AccountNo"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <br />
                                                <Button
                                                    size="lg"
                                                    className="mt-6px"
                                                    variant="primary"
                                                    onClick={getTransactions}
                                                    type="Button"
                                                >
                                                    Load Account
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={state.name}
                                            label="Account Holder Name"
                                            placeholder="Account Holder Name"
                                            readOnly={true}
                                            onChange={(e) => {}}
                                            controlId="Name"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            type="text"
                                            name="balance"
                                            value={state.balance}
                                            label="Account Balance"
                                            placeholder="Account Balance"
                                            readOnly={true}
                                            onChange={(e) => {}}
                                            controlId="Balance"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <DatePickerInput
                                            errMessage={errors["transactionDate"]}
                                            icon="fa-calendar"
                                            label="Transaction Date"
                                            dateFormat="yyyy-MM-dd"
                                            selected={state.transactionDate}
                                            onChange={(date) =>
                                                handleChange("transactionDate", date)
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="amount"
                                            errMessage={errors["amount"]}
                                            value={state.amount}
                                            label="Enter Amount"
                                            placeholder="Enter Amount"
                                            onChange={(e) => handleChange("amount", e.target.value)}
                                            controlId="Amount"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <SelectInput
                                            errMessage={errors["transactionType"]}
                                            name="transactionType"
                                            placeholder="Select Transaction Type"
                                            label="Select Transaction Type"
                                            type="text"
                                            Options={Utils.getTransactionTypes()}
                                            value={state.transactionType}
                                            handleChange={(e) =>
                                                handleChange("transactionType", e.target.value)
                                            }
                                            controlId="TransactionType"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input
                                            type="text"
                                            name="voucherNo"
                                            value={state.voucherNo}
                                            label="Enter Voucher Number"
                                            placeholder="Enter Voucher Number"
                                            onChange={(e) => {
                                                handleChange("voucherNo", e.target.value);
                                            }}
                                            controlId="formVoucherNo"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Input
                                            type="text"
                                            name="particulars"
                                            value={state.particulars}
                                            label="Particulars"
                                            placeholder="Particulars"
                                            onChange={(e) => {
                                                handleChange("particulars", e.target.value);
                                            }}
                                            controlId="Particulars"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>{Utils.getAccountDetails(currentAccount)}</Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <br />
                                <Button variant="primary" type="Submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={resetform} type="Button">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                        <Row>
                            <Col md="12">
                                {transactions ? (
                                    <DataTable
                                        keyField="id"
                                        columns={columns}
                                        data={transactions}
                                        search={true}
                                        pagination={true}
                                        button={false}
                                        className="transaction-table"
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default Transactions;
