import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import * as Utils from "Helpers/utils";
import DataTable from "Components/UI/DataTable";
import * as BAAction from "Actions/Vendors/bankAccounts.action";

const ListTransactions = (props) => {
    const stateObject = useSelector((state) => state.bankAccounts);

    const dispatch = useDispatch();

    const transactions = stateObject.transactions;

    const deleteTransaction = (transactionId) => {
        props.setAlertStatus(true);
        const data = {
            transactionId,
            accountHeadId: props.accountHeadId,
            accountId: props.accountId,
            ledgerId: props.ledgerId,
        };
        dispatch(BAAction.deleteTransaction(data));
    };

    const actionButtons = (cell, row) => {
        return (
            <Row>
                <Col md={12} className="text-center">
                    <Button
                        variant="link"
                        onClick={() => {
                            deleteTransaction(row.transactionId);
                        }}
                    >
                        <i className="fa fa-trash fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
    };

    const txnAmountFormat = (cell, row) => {
        let amount = 0;
        if (row.deposit !== 0) {
            amount = Utils.currencyFormat(row.deposit);
        } else {
            amount = Utils.currencyFormat(row.withdraw);
        }
        return <>{amount}</>;
    };
    const columns = [
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "particulars",
            text: "Particulars",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "voucherNo",
            text: "Voucher No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "transactionType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatTransactionType,
        },
        {
            dataField: "amount",
            text: "Amount",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: txnAmountFormat,
        },

        {
            dataField: "balance",
            text: "Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.showBadge,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];
    return (
        <Row>
            <Col md="12">
                <br />
                {transactions ? (
                    <DataTable
                        keyField="id"
                        columns={columns}
                        data={transactions}
                        search={true}
                        pagination={true}
                        button={false}
                        className="transaction-table"
                        displayText={props.displayText}
                    />
                ) : (
                    ""
                )}
            </Col>
        </Row>
    );
};
export default ListTransactions;
