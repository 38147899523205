import { combineReducers } from "redux";
import authReducer from "./auth.reducer";

//Admin Reducers
import TenantReducer from "./Admin/tenant.reducer";
import SocietyReducer from "./Admin/society.reducer";
import UserReducer from "./Admin/user.reducer";

//Vendor Reducer
import AccountHolderReducer from "./Vendors/userAccount.reducer";
import PigmyAccountReducer from "./Vendors/pigmyAccount.reducer";
import DeleteTransactions from "./Vendors/approveDeleted.reducer";
import LoanAccountReducer from "./Vendors/loanAccount.reducer";
import BankAccountsReducer from "./Vendors/bankAccount.reducer";
import dayBeginEndReducer from "./Vendors/dayBeginEnd.reducer";
import SavingAccountReducer from "./Vendors/savingAccount.reducer";
import CurrentAccountReducer from "./Vendors/currentAccount.reducer";
import FixedDepositReducer from "./Vendors/fixedDeposits.reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    tenants: TenantReducer,
    society: SocietyReducer,
    user: UserReducer,
    accountHolder: AccountHolderReducer,
    pigmyAccount: PigmyAccountReducer,
    loan: LoanAccountReducer,
    deletedTransactions: DeleteTransactions,
    bankAccounts: BankAccountsReducer,
    dayBeginEnd: dayBeginEndReducer,
    savingAccount: SavingAccountReducer,
    currentAccount: CurrentAccountReducer,
    fixedDeposits: FixedDepositReducer,
});

export default rootReducer;
