import getActions from "Actions/base.action";
import { SavingAccountConstants } from "../constants";

const baseActions = getActions(SavingAccountConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getSBDependencies = () => {
    return {
        type: SavingAccountConstants.GET_SAVING_ACCOUNT_DEPENDENCIES,
        payload: null,
        success: SavingAccountConstants.GET_SAVING_ACCOUNT_DEPENDENCIES_SUCCESS,
    };
};

const getAllSchemes = () => {
    return {
        type: SavingAccountConstants.GET_ALL_SAVING_ACCOUNT_SCHEMES,
        payload: null,
        success: SavingAccountConstants.GET_ALL_SAVING_ACCOUNT_SCHEMES_SUCCESS,
    };
};

const createScheme = (data) => {
    return {
        type: SavingAccountConstants.CREATE_SAVING_ACCOUNT_SCHEME,
        payload: data,
        success: SavingAccountConstants.CREATE_SAVING_ACCOUNT_SCHEME_SUCCESS,
    };
};

const updateScheme = (data) => {
    return {
        type: SavingAccountConstants.UPDATE_SAVING_ACCOUNT_SCHEME,
        payload: data,
        success: SavingAccountConstants.UPDATE_SAVING_ACCOUNT_SCHEME_SUCCESS,
    };
};

const createTransaction = (data) => {
    return {
        type: SavingAccountConstants.CREATE_SAVING_ACCOUNT_TRANSACTION,
        payload: data,
        success: SavingAccountConstants.CREATE_SAVING_ACCOUNT_TRANSACTION_SUCCESS,
    };
};

const getAllTransactions = (data) => {
    return {
        type: SavingAccountConstants.GET_ALL_SAVING_ACCOUNT_TRANSACTIONS,
        payload: data,
        success: SavingAccountConstants.GET_ALL_SAVING_ACCOUNT_TRANSACTIONS_SUCCESS,
    };
};

const deleteApprovalTransaction = (data) => {
    return {
        type: SavingAccountConstants.DELETE_SAVING_ACCOUNT_APPROVAL_TRANSACTION,
        success: SavingAccountConstants.DELETE_SAVING_ACCOUNT_TRANSACTION_SUCCESS,
        payload: data,
        failed: SavingAccountConstants.ERROR,
    };
};

const deleteTransaction = (data) => {
    return {
        type: SavingAccountConstants.DELETE_SAVING_ACCOUNT_TRANSACTION,
        success: SavingAccountConstants.DELETE_SAVING_ACCOUNT_TRANSACTION_SUCCESS,
        payload: data,
        failed: SavingAccountConstants.ERROR,
    };
};

const getApprovalTransactions = (data) => {
    return {
        type: SavingAccountConstants.GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION,
        success: SavingAccountConstants.GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const approveTransactions = (data) => {
    return {
        type: SavingAccountConstants.APPROVE_SAVING_ACCOUNT_TRANSACTION,
        success: SavingAccountConstants.APPROVE_SAVING_ACCOUNT_TRANSACTION_SUCCESS,
        payload: data,
    };
};

const setSelectedAll = () => {
    return {
        type: SavingAccountConstants.SET_SELECTED_SAVING_ACCOUNT_TRANSACTIONS,
        payload: null,
    };
};

const setSelectedOne = (data) => {
    return { type: SavingAccountConstants.SET_SELECTED_SAVING_ACCOUNT_TRANSACTION, payload: data };
};

export {
    create,
    update,
    remove,
    getAll,
    getOne,
    failed,
    getAllSchemes,
    createScheme,
    updateScheme,
    createTransaction,
    getSBDependencies,
    getAllTransactions,
    deleteTransaction,
    approveTransactions,
    getApprovalTransactions,
    setSelectedAll,
    setSelectedOne,
    deleteApprovalTransaction,
};
