import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, TextArea, SelectInput, DatePickerInput } from "Components/UI/Input";
import { Card, Row, Col } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/accountHolder.action";
import * as Utils from "Helpers/utils";

const AccountHolder = ({ match }) => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [pan, setPan] = useState("");
    const [aadhar, setAadhar] = useState("");
    const [permanentAddress, setPermanentAddress] = useState("");
    const [nomineeName, setNomineeName] = useState("");
    const [nomineeAge, setNomineeAge] = useState("");
    const [nomineeRelation, setNomineeRelation] = useState("");
    const [status, setStatus] = useState(false);
    const [dob, setDOB] = useState(new Date());

    const {
        params: { id },
    } = match;

    let errors = [];

    let nomineeRelationOption = [];
    nomineeRelationOption["Father"] = "Father";
    nomineeRelationOption["Mother"] = "Mother";
    nomineeRelationOption["Son"] = "Son";
    nomineeRelationOption["Daughter"] = "Daughter";
    nomineeRelationOption["Brother"] = "Brother";
    nomineeRelationOption["Sister"] = "Sister";

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.accountHolder);
    const accountHolder = stateObject.accountHolder;

    useEffect(() => {
        if (accountHolder) {
            setName(accountHolder.name);
            setMobile(accountHolder.mobile);
            setEmail(accountHolder.email);
            setAddress(accountHolder.address);
            setPan(accountHolder.pan);
            setAadhar(accountHolder.aadhar);
            setPermanentAddress(accountHolder.permanentAddress);
            setNomineeName(accountHolder.nomineeName);
            setNomineeAge(accountHolder.nomineeAge);
            setNomineeRelation(accountHolder.nomineeRelation);
            setStatus(accountHolder.status === 1 ? true : false);
            if (accountHolder.dob) {
                setDOB(new Date(accountHolder.dob));
            }
        }
    }, [accountHolder]);

    useEffect(() => {
        if (id) {
            dispatch(Actions.getOne({ id }));
        }
    }, [dispatch, id]);

    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const pageNavItems = [
        {
            title: "Account Holders",
            active: true,
            to: "/account-holders",
        },
        {
            title: "Details",
            active: false,
            to: "/account-holders/" + id + "/details",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Account Holders">
            <Card>
                <Card.Body>
                    <div className="imageDiv">
                        {accountHolder
                            ? Utils.getModalImage(
                                  accountHolder.profileImage,
                                  "profileImage",
                                  "Profile"
                              )
                            : Utils.DefaultImage()}
                    </div>
                    <div className="imageDiv">
                        {accountHolder
                            ? Utils.getModalImage(accountHolder.panImage, "panImage", "Pan Card")
                            : Utils.DefaultImage()}
                    </div>
                    <div className="imageDiv">
                        {accountHolder
                            ? Utils.getModalImage(
                                  accountHolder.aadharImage,
                                  "aadharImage",
                                  "Aadhar"
                              )
                            : Utils.DefaultImage()}
                    </div>

                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                label="Name"
                                placeholder="Enter account holder name"
                                name="name"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                errMessage={errors["name"]}
                                controlId="formName"
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                label="Mobile Number"
                                placeholder="Enter mobile number"
                                name="mobile"
                                id="mobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                errMessage={errors["mobile"]}
                                controlId="formMobile"
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                label="Email"
                                placeholder="Enter email address"
                                name="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                errMessage={errors["email"]}
                                controlId="formEmail"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <DatePickerInput
                                icon="fa-calendar"
                                label="Date of birth"
                                dateFormat="yyyy-MM-dd"
                                selected={dob}
                                onChange={(date) => setDOB(date)}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                label="PAN"
                                placeholder="Enter PAN number"
                                type="text"
                                name="pan"
                                id="pan"
                                value={pan}
                                onChange={(e) => setPan(e.target.value)}
                                errMessage={errors["pan"]}
                                controlId="formPan"
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                label="Aadhar"
                                placeholder="Enter aadhar number"
                                type="text"
                                name="aadhar"
                                id="aadhar"
                                value={aadhar}
                                onChange={(e) => setAadhar(e.target.value)}
                                errMessage={errors["aadhar"]}
                                controlId="formAadhar"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                label="Nominee Name"
                                placeholder="Enter nominee name"
                                name="nomineeName"
                                id="nomineeName"
                                value={nomineeName}
                                onChange={(e) => setNomineeName(e.target.value)}
                                errMessage={errors["nomineeName"]}
                                controlId="formNomineeName"
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                label="Nominee Age"
                                placeholder="Enter nominee age"
                                name="nomineeAge"
                                id="nomineeAge"
                                value={nomineeAge}
                                onChange={(e) => setNomineeAge(e.target.value)}
                                errMessage={errors["nomineeAge"]}
                                controlId="formNomineeAge"
                            />
                        </Col>
                        <Col md="4">
                            <SelectInput
                                id="nomineeRelation"
                                name="nomineeRelation"
                                label="Select Nominee Relation"
                                errMessage={errors["nomineeRelation"]}
                                handleChange={(e) => setNomineeRelation(e.target.value)}
                                Options={nomineeRelationOption}
                                value={nomineeRelation}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                errMessage={errors["address"]}
                                label="Address"
                                name="address"
                                onChange={(e) => setAddress(e.target.value)}
                                id="address"
                                value={address}
                                controlId="formAddress"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                errMessage={errors["permanentAddress"]}
                                label="Permanent Address"
                                name="permanentAddress"
                                onChange={(e) => setPermanentAddress(e.target.value)}
                                id="permanentAddress"
                                value={permanentAddress}
                                controlId="formPermanentAddress"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} className="mt-5px">
                            <label>Status</label>
                            <br />
                            <BootstrapSwitchButton
                                checked={status}
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                onlabel="Active"
                                offlabel="InActive"
                                onChange={(checked) => {
                                    setStatus(checked);
                                }}
                            />
                        </Col>
                    </Row>

                    <div className="text-center mt-5">
                        <RequestStatus
                            stateObj={stateObject}
                            alertStatus={alertStatus}
                            setAlertStatus={setAlertStatus}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default AccountHolder;
