import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Input, SearchableSelect, DatePickerInput, TextArea } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";
import * as Actions from "Actions/Vendors/loanAccounts.action";

import LoanScheme from "./LoanScheme";
import GoldLoan from "./GoldLoan";
import FDLoan from "./FDLoan";
import VehicleLoan from "./VehicleLoan";
import EMIDialog from "./EMIDialog";

const LoanAccount = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [emiDialog, setEMIDialog] = useState(false);
    const [loanSchemeId, setLoanScheme] = useState(0);
    const [selectedScheme, setSelectedScheme] = useState(null);
    const [accountHolderId, setAccountHolderId] = useState(null);
    const [processingFees, setProcessingFees] = useState(null);
    const [insuranceFees, setInsuranceFees] = useState(null);
    const [legalFees, setLegalFees] = useState(null);
    const [items, setItems] = useState("");
    const [pledgeValue, setPledgeValue] = useState(0);
    const [evaluator, setEvaluator] = useState(null);
    const [evaluatorCharges, setEvaluatorCharges] = useState(null);
    const [installments, setInstallments] = useState(null);
    const [limitSanctioned, setLimitSanctioned] = useState(null);
    const [amountSanctioned, setAmountSanctioned] = useState(null);
    const [installmentAmount, setInstallmentAmount] = useState(null);
    const [interestRate, setInterestRate] = useState(null);
    const [penalInterest, setPenalInterest] = useState(null);
    const [issueDate, setIssueDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(null);
    const [interestDate, setInterestDate] = useState(new Date());
    const [interestAmount, setInterestAmount] = useState(null);
    const [guarantor1, setGuarantor1] = useState(null);
    const [guarantor2, setGuarantor2] = useState(null);
    const [guarantorMsg, setGuarantorMsg] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [interestBalance, setInterestBalance] = useState(0);
    const [penalInterestBalance, setPenalInterestBalance] = useState(0);
    const [vehicleType, setVehicleType] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [vehicleMake, setVehicleMake] = useState(null);
    const [vehicleModel, setVehicleModel] = useState(null);
    const [vehicleNo, setVehicleNo] = useState(null);
    const [vehicleChassiNo, setVehicleChassiNo] = useState(null);
    const [vehicleEngineNo, setVehicleEngineNo] = useState(null);
    const [hypothecationDetails, setHypothecationDetails] = useState(null);
    const [hypothecationDocuments, sethypothecationDocuments] = useState([]);
    const [insuranceCompany, setInsuranceCompany] = useState(null);
    const [insurancePolicyNo, setInsurancePolicyNo] = useState(null);
    const [insuranceDate, setInsuranceDate] = useState(null);
    const [insuranceValidity, setInsuranceValidity] = useState(null);
    const [noOfDays, setNoOfDays] = useState(0);
    const [accountHolder, setAccountHolder] = useState({});

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.loan);
    const pledgeItems = stateObject.pledgeItems;

    useEffect(() => {
        let itemNames = "";
        let totalValue = 0;
        pledgeItems.forEach((pledgeItem) => {
            itemNames += pledgeItem.item.toString() + ",";
            totalValue += parseFloat(pledgeItem.itemValue);
        });
        setItems(itemNames.substring(0, itemNames.length - 1));
        setPledgeValue(totalValue);
    }, [pledgeItems]);

    useEffect(() => {
        calculateInterest();
    }, [interestDate, issueDate]);

    useEffect(() => {
        if (issueDate && selectedScheme && selectedScheme.maxRepaymentDays) {
            setDueDate(
                Utils.getNextDate(issueDate, parseInt(selectedScheme.maxRepaymentDays), "day")
            );
            calculateInterest();
        }
    }, [issueDate, selectedScheme]);

    const loanSchemes = stateObject.loanSchemes;
    const accountNumber = stateObject.accountNumber;

    const accountHolderState = useSelector((state) => state.accountHolder);
    const accountHolders = accountHolderState.accountHolders;
    const accountHolderOptions = Utils.getSearchableOptions(accountHolders, "name", "id");

    const selectLoanScheme = (selectedId) => {
        setLoanScheme(selectedId);
        const scheme = Utils.Search(loanSchemes, selectedId);
        setSelectedScheme(scheme);
        if (scheme) {
            setInsuranceFees(scheme.insuranceFees);
            setLegalFees(scheme.legalFees);
            setInterestRate(scheme.interestRate);
            setPenalInterest(scheme.penalInterest);

            if (issueDate && scheme.maxRepaymentDays) {
                setDueDate(Utils.getNextDate(issueDate, parseInt(scheme.maxRepaymentDays), "day"));
                calculateInterest();
            }
        }
    };

    /* const showEMI = () => {
        const days = Utils.getNoOfDays(issueDate, installments);
        console.log(days);
    }; */

    const calculateInterest = () => {
        if (issueDate && interestDate) {
            const days = Utils.dateDiff(issueDate, interestDate);
            setNoOfDays(days);
            setInterestAmount(
                Math.round(parseFloat(((interestRate * amountSanctioned) / 100 / 365) * days))
            );
        }
    };

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const checkGuarantor = (val1, val2) => {
        setGuarantorMsg("");
        if (val1 === val2) {
            setGuarantorMsg("Please select different guarantor");
        }
    };

    useEffect(() => {
        dispatch(Actions.getLoanAccountDependencies());
    }, [dispatch]);

    const submitForm = (e) => {
        setAlertStatus(true);
        setGuarantorMsg("");
        if (guarantor1 === guarantor2) {
            setGuarantorMsg("Please select different guarantor");
            return false;
        }
        let data = {
            loanSchemeId,
            accountHolderId,
            processingFees,
            insuranceFees,
            legalFees,
            items,
            pledgeValue,
            evaluator,
            evaluatorCharges,
            installments,
            limitSanctioned,
            amountSanctioned,
            installmentAmount,
            interestRate,
            penalInterest,
            issueDate: Utils.formatDate(issueDate),
            dueDate: Utils.formatDate(dueDate),
            interestDate: Utils.formatDate(interestDate),
            interestAmount,
            guarantor1,
            guarantor2,
            remarks,
            interestBalance,
            penalInterestBalance,
            vehicleType,
            vehicle,
            vehicleMake,
            vehicleModel,
            vehicleNo,
            vehicleChassiNo,
            vehicleEngineNo,
            hypothecationDetails,
            hypothecationDocuments,
            insuranceCompany,
            insurancePolicyNo,
            insuranceDate,
            insuranceValidity,
            pledgeItems,
        };
        dispatch(Actions.create(data));
    };

    const pageNavItems = [
        {
            title: "Loan Account",
            active: true,
            to: "/loan-accounts",
        },
        {
            title: "New Loan Account",
            active: false,
            to: "/loan-accounts/create",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Loan Account">
            <Card>
                <Card.Body>
                    <EMIDialog
                        emiDialog={emiDialog}
                        setEMIDialog={setEMIDialog}
                        scheme={selectedScheme}
                        amountSanctioned={amountSanctioned}
                        issueDate={issueDate}
                        installments={installments}
                        setInstallmentAmount={setInstallmentAmount}
                    />

                    <RequestStatus
                        stateObj={stateObject}
                        alertStatus={alertStatus}
                        setAlertStatus={setAlertStatus}
                    />
                    <Row>
                        <Col md={8}>
                            <Row>
                                <Col md={4}>
                                    <LoanScheme
                                        setLoanScheme={selectLoanScheme}
                                        value={loanSchemeId}
                                        setAlertStatus={setAlertStatus}
                                    />
                                </Col>
                                <Col md={4}>
                                    <SearchableSelect
                                        name="accountHolderId"
                                        controlId="formAccountHolderId"
                                        label="Select Account Holder"
                                        Options={accountHolderOptions}
                                        errMessage={errors["accountHolderId"]}
                                        value={accountHolderId}
                                        onChange={(selectedOption) => {
                                            if (selectedOption.length > 0) {
                                                setAccountHolderId(selectedOption[0].value);
                                                let found = accountHolders.find(
                                                    (item) => item.id === selectedOption[0].value
                                                );
                                                if (!found) {
                                                    found = {};
                                                }
                                                setAccountHolder(found);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        name="accountNumber"
                                        id="accountNumber"
                                        value={accountNumber}
                                        placeholder="Account Number"
                                        label="Account Number"
                                        controlId="formAccountNumber"
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={4}>
                                    <Input
                                        name="processingFees"
                                        id="processingFees"
                                        placeholder="Processing Fees"
                                        label="Processing Fees"
                                        errMessage={errors["processingFees"]}
                                        value={processingFees}
                                        onChange={(e) => {
                                            setProcessingFees(e.target.value);
                                        }}
                                        controlId="formProcessingFees"
                                    />
                                </Col>

                                <Col md={4}>
                                    <Input
                                        name="insuranceFees"
                                        id="insuranceFees"
                                        placeholder="Insurance Fees"
                                        label="Insurance Fees"
                                        errMessage={errors["insuranceFees"]}
                                        value={insuranceFees}
                                        onChange={(e) => {
                                            setInsuranceFees(e.target.value);
                                        }}
                                        controlId="formInsuranceFees"
                                    />
                                </Col>

                                <Col md={4}>
                                    <Input
                                        name="legalFees"
                                        id="legalFees"
                                        placeholder="Legal Fees"
                                        label="Legal Fees"
                                        errMessage={errors["legalFees"]}
                                        value={legalFees}
                                        onChange={(e) => {
                                            setLegalFees(e.target.value);
                                        }}
                                        controlId="formLegalFees"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Input
                                        type="text"
                                        name="interestRate"
                                        id="interestRate"
                                        label="Rate of Interest"
                                        placeholder="Rate of Interest"
                                        value={interestRate}
                                        onChange={(e) => setInterestRate(e.target.value)}
                                        controlId="formInterestRate"
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input
                                        type="text"
                                        name="penalInterest"
                                        id="penalInterest"
                                        label="Penal Interest"
                                        placeholder="Penal Interest"
                                        value={penalInterest}
                                        onChange={(e) => setPenalInterest(e.target.value)}
                                        controlId="formPenalInterest"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>{Utils.getAccountDetails(accountHolder)}</Col>
                    </Row>
                    {selectedScheme && selectedScheme.goldLoan === 1 ? (
                        <GoldLoan
                            errors={errors}
                            items={items.trim(",")}
                            setItems={setItems}
                            pledgeValue={pledgeValue}
                            setPledgeValue={setPledgeValue}
                            evaluator={evaluator}
                            setEvaluator={setEvaluator}
                            evaluatorCharges={evaluatorCharges}
                            setEvaluatorCharges={setEvaluatorCharges}
                        />
                    ) : (
                        ""
                    )}
                    {selectedScheme && selectedScheme.fdLoan === 1 ? (
                        <FDLoan errors={errors} />
                    ) : (
                        ""
                    )}
                    {selectedScheme && selectedScheme.vehicleLoan === 1 ? (
                        <VehicleLoan
                            errors={errors}
                            vehicleType={vehicleType}
                            setVehicleType={setVehicleType}
                            vehicle={vehicle}
                            setVehicle={setVehicle}
                            vehicleMake={vehicleMake}
                            setVehicleMake={setVehicleMake}
                            vehicleModel={vehicleModel}
                            setVehicleModel={setVehicleModel}
                            vehicleNo={vehicleNo}
                            setVehicleNo={setVehicleNo}
                            vehicleChassiNo={vehicleChassiNo}
                            setVehicleChassiNo={setVehicleChassiNo}
                            vehicleEngineNo={vehicleEngineNo}
                            setVehicleEngineNo={setVehicleEngineNo}
                            hypothecationDetails={hypothecationDetails}
                            setHypothecationDetails={setHypothecationDetails}
                            hypothecationDocuments={hypothecationDocuments}
                            sethypothecationDocuments={sethypothecationDocuments}
                            insuranceCompany={insuranceCompany}
                            setInsuranceCompany={setInsuranceCompany}
                            insurancePolicyNo={insurancePolicyNo}
                            setInsurancePolicyNo={setInsurancePolicyNo}
                            insuranceDate={insuranceDate}
                            setInsuranceDate={setInsuranceDate}
                            insuranceValidity={insuranceValidity}
                            setInsuranceValidity={setInsuranceValidity}
                        />
                    ) : (
                        ""
                    )}
                    <br />

                    <Row>
                        <Col md={4}>
                            <DatePickerInput
                                errMessage={errors["issueDate"]}
                                icon="fa-calendar"
                                label="Issue Date"
                                dateFormat="yyyy-MM-dd"
                                selected={issueDate}
                                onChange={(date) => {
                                    setIssueDate(date);
                                    calculateInterest();
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <DatePickerInput
                                errMessage={errors["dueDate"]}
                                icon="fa-calendar"
                                label="Due Date"
                                dateFormat="yyyy-MM-dd"
                                selected={dueDate}
                                onChange={(date) => setDueDate(date)}
                                readOnly={true}
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="installments"
                                id="installments"
                                label="Loan Installments"
                                placeholder="Loan Installments"
                                value={installments}
                                onChange={(e) => setInstallments(e.target.value)}
                                controlId="formInstallments"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="limitSanctioned"
                                id="limitSanctioned"
                                label="Loan Limit Sanctioned"
                                placeholder="Loan Limit Sanctioned"
                                value={limitSanctioned}
                                onChange={(e) => setLimitSanctioned(e.target.value)}
                                controlId="formLimitSanctioned"
                            />
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md="10" style={{ paddingRight: "0px" }}>
                                    <Input
                                        type="text"
                                        name="amountSanctioned"
                                        id="amountSanctioned"
                                        label="Loan Amount Sanctioned"
                                        placeholder="Loan Amount Sanctioned"
                                        value={amountSanctioned}
                                        onChange={(e) => {
                                            setAmountSanctioned(e.target.value);
                                            calculateInterest();
                                        }}
                                        controlId="formamountSanctioned"
                                    />
                                </Col>
                                <Col md="2" style={{ paddingLeft: "1px" }}>
                                    <br />
                                    <Button
                                        variant="secondary"
                                        className="mt-7px"
                                        onClick={() => setEMIDialog(true)}
                                    >
                                        EMI's
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="installmentAmount"
                                id="installmentAmount"
                                label="Installment Amount"
                                placeholder="Installment Amount"
                                value={installmentAmount}
                                onChange={(e) => setInstallmentAmount(e.target.value)}
                                controlId="formInstallmentAmount"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <DatePickerInput
                                errMessage={errors["interestDate"]}
                                icon="fa-calendar"
                                label={
                                    noOfDays ? `Interest Date (${noOfDays} Days)` : "Interest Date"
                                }
                                dateFormat="yyyy-MM-dd"
                                selected={interestDate}
                                onChange={(date) => {
                                    setInterestDate(date);
                                    calculateInterest();
                                }}
                                controlId="formInterestDate"
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="interestAmount"
                                id="interestAmount"
                                placeholder="Interest Amount"
                                label="Interest Amount"
                                onChange={(e) => setInterestAmount(e.target.value)}
                                value={interestAmount}
                                controlId="formInterestAmount"
                            />
                        </Col>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="interestBalance"
                                id="interestBalance"
                                placeholder="Interest Balance"
                                label="Interest Balance"
                                value={interestBalance}
                                onChange={(e) => setInterestBalance(e.target.value)}
                                controlId="formInterestBalance"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Input
                                type="text"
                                name="penalInterestBalance"
                                id="penalInterestBalance"
                                placeholder="Penal Interest Balance"
                                label="Penal Interest Balance"
                                value={penalInterestBalance}
                                onChange={(e) => setPenalInterestBalance(e.target.value)}
                                controlId="formPenalInterestBalance"
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={4}>
                            <SearchableSelect
                                name="gurantor1"
                                controlId="formGurantor1"
                                label="Select Guarantor 1"
                                placeholder="Select Gurantor 1"
                                Options={accountHolderOptions}
                                errMessage={
                                    errors["guarantor1"] ? errors["guarantor1"] : guarantorMsg
                                }
                                value={guarantor1}
                                onChange={(selectedOption) => {
                                    if (selectedOption.length > 0) {
                                        setGuarantor1(selectedOption[0].value);
                                        checkGuarantor(selectedOption[0].value, guarantor2);
                                    }
                                }}
                            />
                        </Col>
                        <Col md={4}>
                            <SearchableSelect
                                name="gurantor2"
                                controlId="formGurantor2"
                                label="Select Guarantor 2"
                                placeholder="Select Gurantor 2"
                                Options={accountHolderOptions}
                                errMessage={
                                    errors["guarantor2"] ? errors["guarantor2"] : guarantorMsg
                                }
                                value={guarantor2}
                                onChange={(selectedOption) => {
                                    if (selectedOption.length > 0) {
                                        setGuarantor2(selectedOption[0].value);
                                        checkGuarantor(guarantor1, selectedOption[0].value);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextArea
                                name="remarks"
                                label="Remarks"
                                placeholder="Remarks"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button variant="primary" type="button" onClick={submitForm}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default LoanAccount;
