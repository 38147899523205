import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "Components/Layout";
import { Col, Row, Card, Button, Badge } from "react-bootstrap";
import { Input } from "Components/UI/Input";
import * as Utils from "Helpers/utils";
import AlertDialog from "Components/UI/AlertDialog";
import DataTable from "Components/UI/DataTable";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/dayBeginEnd.actions";

const ListTransactions = (props) => {
    const stateObject = useSelector((state) => state.dayBeginEnd);

    const transactions = stateObject.transactions;

    const getClosedBy = (cell, row) => {
        return (
            <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                <Badge bg="success" className="bg-success">
                    {row.user.name}
                </Badge>
            </div>
        );
    };

    const columns = [
        {
            dataField: "openingDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },

        {
            dataField: "openingBalance",
            text: "Opening Balance",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },

        {
            dataField: "closingBalance",
            text: "Closing Balance",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "#",
            text: "Closed By",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: getClosedBy,
        },
        {
            dataField: "createdAt",
            text: "Closing Date",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
    ];
    return (
        <Row>
            <Col md="12">
                <br />
                {transactions ? (
                    <DataTable
                        keyField="id"
                        columns={columns}
                        data={transactions}
                        search={true}
                        pagination={true}
                        button={false}
                        className="transaction-table"
                        displayText={props.displayText}
                    />
                ) : (
                    ""
                )}
            </Col>
        </Row>
    );
};

const DayBeginEnd = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [state, setState] = useState({
        openingDate: localStorage.getItem("openingDate"),
        openingBalance: localStorage.getItem("openingBalance"),
        showDialog: false,
    });

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.dayBeginEnd);

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const closeDay = () => {
        dispatch(
            Actions.closeDay({
                openingDate: state.openingDate,
                closingBalance: state.closingBalanace,
                openingBalance: state.openingBalance,
            })
        );
    };

    useEffect(() => {
        dispatch(Actions.getAll());
    }, [dispatch]);

    const pageNavItems = [
        {
            title: "Day Begin / End",
            active: true,
            to: "/loan-accounts",
        },
    ];
    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Day Begin / End">
            <AlertDialog
                showDialog={state.showDialog}
                onChangeHandler={onChangeHandler}
                closeDay={closeDay}
            />
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Input
                                name="openingDate"
                                id="openingDate"
                                value={state.openingDate}
                                placeholder="Current Date"
                                label="Current Date"
                                controlId="formOpeningDate"
                                readOnly={true}
                            />
                        </Col>
                        <Col md={3}>
                            <Input
                                name="openingBalance"
                                id="openingBalance"
                                value={state.openingBalance}
                                placeholder="Opening Balance"
                                label="Opening Balance"
                                controlId="formopeningBalance"
                                readOnly={true}
                            />
                        </Col>
                        <Col md={3}>
                            <Input
                                name="closingBalance"
                                id="closingBalance"
                                value={stateObject.closingBalance}
                                placeholder="Closing Balance"
                                label="Closing Balance"
                                controlId="closingBalance"
                                readOnly={true}
                            />
                        </Col>
                        <Col md={3}>
                            <br />
                            <Button
                                variant="primary"
                                type="button"
                                size="lg"
                                className="mt-5px"
                                onClick={() => onChangeHandler("showDialog", true)}
                            >
                                Close Day
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ListTransactions displayText="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default DayBeginEnd;
