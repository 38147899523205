import { authConstants } from "Actions/constants";
const initState = {
	user: {},
	authFailure: false,
	authenticated: false,
	message: "",
	access_token: "",
	refresh_token: "",
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
			state = {
				...state,
				message: "",
				authenticating: true,
			};
			break;
		case authConstants.LOGIN_SUCCESS:
			state = {
				...initState,
				user: action.payload.user,
				message: action.payload.message,
				authenticated: true,
				access_token: action.payload.access_token,
				refresh_token: action.payload.refresh_token,
			};
			break;
		case authConstants.LOGIN_ERROR:
			state = {
				...initState,
				message: action.payload.message,
				authFailure: true,
			};
			break;
		case authConstants.LOGOUT_SUCCESS:
			state = {
				...initState,
				message: action.payload.message,
				authenticated: false,
			};
			break;
		default:
			return state;
	}
	return state;
};
export default authReducer;
