import { take, takeLatest, call, put } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import { logout } from "Actions/auth";
import * as REST from "Services/rest.service";
import {
    PigmyAccountConstants as ActionConstant,
    AccountHolderConstants,
    TOKEN_EXPIRED,
} from "Actions/constants";
import {
    failed,
    getAgents,
    getAll as getAllPigmyAccounts,
} from "Actions/Vendors/pigmyAccount.action";
import { getAll as getAccountHolder } from "Actions/Vendors/accountHolder.action";

import { watchTransactionDeleteRequest } from "./deleteTransaction.saga";

const endPoint = "/vendor/pigmy-account";
const transactionEndPoint = "/vendor/pigmy-transaction";

const sagaWatchers = BaseSaga(endPoint, failed);

const SagaActions = {
    ...sagaWatchers,
};

const message = "Request Failed!";
let err = new Error(message);
err.status = 500;

const getFormData = (data) => {
    let formData = new FormData();
    formData.set("agentId", data.agentId);
    formData.set("pigmytopc", data.dataFile);
    return formData;
};

function* getPigmyAgents(action) {
    try {
        let response = yield call(REST.get("/vendor/pigmy-agents"));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchPigmyAccountDependency(action) {
    if (action.payload.id) {
        yield take(ActionConstant.GET_SUCCESS);
    }
    yield put(getAgents());
    yield take(ActionConstant.GET_PIGMY_AGENTS_SUCCESS);
    yield put(getAccountHolder());
    yield take(AccountHolderConstants.GET_ALL_SUCCESS);
}

function* watchPigmyAccountDetailsDependency(action) {
    yield put(getAllPigmyAccounts());
    yield take(ActionConstant.GET_ALL_SUCCESS);
    yield put(getAgents());
}

function* watchPigmyAccountDetailRequest(action) {
    try {
        let response = yield call(
            REST.get(transactionEndPoint + "/" + action.payload.id + "/" + action.payload.agentId)
        );
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchPigmyTransactionRequest(action) {
    try {
        let response = yield call(REST.post(transactionEndPoint, action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchPigmyApprovalTransactionGetRequest(action) {
    try {
        let response = yield call(
            REST.post(transactionEndPoint + "/get-approvals", action.payload)
        );
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchPigmyTransactionApproveRequest(action) {
    try {
        let response = yield call(REST.post(transactionEndPoint + "/approve", action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchPigmyTransactionDownloadRequest(action) {
    try {
        let response = yield call(REST.post(transactionEndPoint + "/download", action.payload));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchPigmyTansactionUploadRequest(action) {
    try {
        const formData = getFormData(action.payload);
        let response = yield call(REST.postFormdData(transactionEndPoint + "/upload", formData));
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchApprovalTransactionDeleteRequest(action) {
    try {
        let response = yield call(
            REST.post(
                transactionEndPoint + "/approve/delete/" + action.payload.transactionId,
                action.payload
            )
        );
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

function* watchGetAccountRequest(action) {
    try {
        let response = yield call(
            REST.get(endPoint + "/" + action.payload.accountNo + "/" + action.payload.agentId)
        );
        if (!response) {
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        yield put(failed({ err }));
    }
}

export function* watchPigmyAccountRequests() {
    yield takeLatest(ActionConstant.CREATE, SagaActions.create);
    yield takeLatest(ActionConstant.UPDATE, SagaActions.update);
    yield takeLatest(ActionConstant.DELETE, SagaActions.remove);
    yield takeLatest(ActionConstant.GET_ALL, SagaActions.getAll);
    yield takeLatest(ActionConstant.GET_PIGMY_ACCOUNT, watchGetAccountRequest);
    yield takeLatest(ActionConstant.GET, SagaActions.getOne);
    yield takeLatest(ActionConstant.GET_PIGMY_AGENTS, getPigmyAgents);
    yield takeLatest(ActionConstant.PIGMY_ACCOUNT_DEPENDENCIES, watchPigmyAccountDependency);
    yield takeLatest(ActionConstant.GET_PIGMY_ACCOUNT_TRANSACTION, watchPigmyAccountDetailRequest);
    yield takeLatest(ActionConstant.PIGMY_ACCOUNT_TRANSACTION, watchPigmyTransactionRequest);
    yield takeLatest(
        ActionConstant.GET_PIGMY_APPROVAL_TRANSACTIONS,
        watchPigmyApprovalTransactionGetRequest
    );
    yield takeLatest(
        ActionConstant.POST_PIGMY_ACCOUNT_APPROVAL,
        watchPigmyTransactionApproveRequest
    );
    yield takeLatest(
        ActionConstant.PIGMY_ACCOUNT_DETAILS_DEPENDENCY,
        watchPigmyAccountDetailsDependency
    );
    yield takeLatest(
        ActionConstant.PIGMY_DOWNLOAD_TRANSACTIONS,
        watchPigmyTransactionDownloadRequest
    );
    yield takeLatest(ActionConstant.PIGMY_UPLOAD_TRANSACTIONS, watchPigmyTansactionUploadRequest);
    yield takeLatest(
        ActionConstant.PIGMY_DELETE_APPROVAL_TRANSACTION,
        watchApprovalTransactionDeleteRequest
    );
    yield takeLatest(ActionConstant.PIGMY_TRANSACTION_DELETE, watchTransactionDeleteRequest);
}
