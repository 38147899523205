import React from "react";
import Header from "./header";
//import {Container} from 'react-bootstrap'
import PageNav from "Components/UI/PageNav";
import { Col, Row } from "react-bootstrap";
const Layout = (props) => {
    return (
        <>
            <Header />
            <div id="page-wrapper">
                <div className="container-fluid">
                    <Row>
                        <Col md={12}>
                            {props.pageNavItems ? (
                                <PageNav
                                    pageNavItems={props.pageNavItems}
                                    pageTitle={props.pageTitle}
                                />
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                    {props.children}
                </div>
            </div>
        </>
    );
};
export default Layout;
