import { BankAccountLedgerConstants } from "../constants";
import getActions from "Actions/base.action";

const baseActions = getActions(BankAccountLedgerConstants);
const create = baseActions.create;
const update = baseActions.update;
const remove = baseActions.remove;
//const getAll = baseActions.getAll;
const getOne = baseActions.getOne;
const failed = baseActions.failed;

const getAll = (data) => {
    return {
        type: BankAccountLedgerConstants.GET_ALL,
        success: BankAccountLedgerConstants.GET_ALL_SUCCESS,
        payload: data,
    };
};

export { create, update, remove, getAll, getOne, failed };
