import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxInput, DatePickerInput, StatusBadge } from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/fixedDeposits.action";
import * as Utils from "Helpers/utils";
import { FD_TRANSACTIONS } from "Actions/constants";
import DataTable from "Components/UI/DataTable";
import Scheme from "./Scheme";

const ApproveTransaction = () => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [schemeId, setSchemeId] = useState("");
    const [transactionDate, setTransactionDate] = useState(new Date());
    const [approveDate, setApproveDate] = useState(new Date());

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.fixedDeposits);

    let approvalTransactions = stateObject.approvalTransactions;

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    useEffect(() => {
        dispatch(Actions.getAllSchemes());
    }, [dispatch]);

    const getTransactions = () => {
        setAlertStatus(true);
        dispatch(
            Actions.getApprovalTransactions({
                schemeId,
                transactionDate: Utils.formatDate(transactionDate),
            })
        );
    };

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        let selectedTxns = approvalTransactions.map((item) => (item.selected ? item.id : 0));

        let selectedIds = selectedTxns.filter((item) => {
            if (item) {
                return item;
            }
        });
        dispatch(
            Actions.approveTransactions({
                transactionIds: selectedIds,
                schemeId,
                transactionDate: Utils.formatDate(transactionDate),
                approveDate: Utils.formatDate(approveDate),
            })
        );
    };

    const showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    const deleteTransaction = (transactionId, accountNo) => {
        setAlertStatus(true);
        const data = {
            objId: schemeId,
            objType: FD_TRANSACTIONS,
            accountNo: accountNo,
            transactionId,
            transactionDate: Utils.formatDate(transactionDate),
        };
        dispatch(Actions.deleteApprovalTransaction(data));
    };

    const setTransactionSelection = (id, selected) => {
        dispatch(Actions.setSelectedOne({ id, selected }));
    };

    const selectAll = () => {
        dispatch(Actions.setSelectedAll());
    };

    const actionButtons = (cell, row) => {
        return (
            <Row>
                <Col md={12} className="text-center">
                    <Button
                        variant="link"
                        onClick={() => {
                            deleteTransaction(row.id, row.accountNo);
                        }}
                    >
                        <i className="fa fa-trash fa-lg" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        );
    };

    const formatSelectedOptions = (cell, row) => {
        if (row.selected) {
            return (
                <CheckBoxInput
                    checked={true}
                    controlId={"formCheck" + row.id}
                    onClick={(e) => {
                        setTransactionSelection(row.id, e.target.checked);
                    }}
                />
            );
        } else {
            return (
                <CheckBoxInput
                    checked={false}
                    controlId={"formCheck" + row.id}
                    onClick={(e) => {
                        setTransactionSelection(row.id, e.target.checked);
                    }}
                />
            );
        }
    };

    const columns = [
        {
            dataField: "selected",
            text: "Select",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: formatSelectedOptions,
        },
        {
            dataField: "transactionDate",
            text: "Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "maturityDate",
            text: "Maturity Date",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.formatDate,
        },
        {
            dataField: "particulars",
            text: "Particulars",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "voucherNo",
            text: "Voucher No",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
        },
        {
            dataField: "transactionType",
            text: "Transaction Type",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.getTransactionTypeStr,
        },
        {
            dataField: "deposit",
            text: "Deposit",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "withdraw",
            text: "Withdraw",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            formatter: Utils.currencyFormat,
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: showBadge,
        },
        {
            dataField: "#",
            text: "Action",
            headerStyle: { textAlign: "center" },
            style: { textAlign: "center" },
            formatter: actionButtons,
        },
    ];

    const pageNavItems = [
        {
            title: "Fixed Deposit Account",
            active: true,
            to: "/sb-account",
        },
        {
            title: "Approve Transaction",
            active: false,
            to: "/sb-account/transaction/approve",
        },
    ];
    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Approve Fixed Deposit Transactions">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={3}>
                                <Scheme
                                    schemeId={schemeId}
                                    setSchemeId={setSchemeId}
                                    setAlertStatus={setAlertStatus}
                                    errMessage={errors["schemeId"]}
                                />
                            </Col>
                            <Col md={3}>
                                <DatePickerInput
                                    errMessage={errors["transactionDate"]}
                                    icon="fa-calendar"
                                    label="Transaction Date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={transactionDate}
                                    onChange={(date) => setTransactionDate(date)}
                                />
                            </Col>
                            <Col md={3}>
                                <DatePickerInput
                                    errMessage={errors["approveDate"]}
                                    icon="fa-calendar"
                                    label="Approve Date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={approveDate}
                                    onChange={(date) => setApproveDate(date)}
                                />
                            </Col>
                            <Col md={3}>
                                <br />
                                <Button
                                    variant="primary"
                                    type="Button"
                                    className="mt-6px"
                                    onClick={getTransactions}
                                >
                                    Load Transaction
                                </Button>
                            </Col>
                        </Row>

                        {approvalTransactions && Object.keys(approvalTransactions).length !== 0 ? (
                            <>
                                <Row>
                                    <Col md="12">
                                        <DataTable
                                            keyField="id"
                                            columns={columns}
                                            data={approvalTransactions}
                                            search={false}
                                            pagination={false}
                                            button={false}
                                            className="transaction-table"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <br />
                                        <Button
                                            variant="secondary"
                                            onClick={selectAll}
                                            type="Button"
                                        >
                                            Select All
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button variant="primary" type="Submit">
                                            Approve
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            ""
                        )}

                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default ApproveTransaction;
