import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, DatePickerInput, SelectInput, TextArea } from "Components/UI/Input";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import RequestStatus from "Helpers/RequestStatus";
import * as Utils from "Helpers/utils";

//Actions
import * as AccountHeadActions from "Actions/Vendors/bankAccountHeads.action";
import * as BAAction from "Actions/Vendors/bankAccounts.action";

import AccountHead from "./AccountHead";
import Accounts from "./Accounts";
import Ledger from "./Ledger";
import ListTransactions from "./ListTransactions";

const BankTransaction = () => {
    const [alertStatus, setAlertStatus] = useState(false);
    const [accountHeadId, setAccountHeadId] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [ledgerId, setLedgerId] = useState(null);

    const [state, setState] = useState({
        transactionDate: new Date(),
        transactionAmount: 0,
        transactionType: 1,
        voucherNo: "",
        maturityDate: new Date(),
        particulars: "",
        transactions: [],
        accountHeads: [],
        ledgers: [],
        accounts: [],
        showAccountHeadDialog: false,
        showAccountDialog: false,
        showLedgerDialog: false,
        loadTransactions: true,
    });

    const dispatch = useDispatch();

    const stateObject = useSelector((state) => state.bankAccounts);

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    useEffect(() => {
        dispatch(AccountHeadActions.getAll());
    }, [dispatch]);

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        const data = {
            accountHeadId: accountHeadId,
            accountNo: accountId,
            ledgerId: ledgerId,
            transactionDate: Utils.formatDate(state.transactionDate),
            amount: state.transactionAmount,
            transactionType: state.transactionType,
            voucherNo: state.voucherNo,
            maturityDate: state.maturityDate ? Utils.formatDate(state.maturityDate) : null,
            particulars: state.particulars,
        };
        dispatch(BAAction.newTransaction(data));
    };

    const resetform = () => {
        setAccountHeadId(null);
        setAccountId(null);
        setLedgerId(null);
        setState({
            transactionDate: new Date(),
            transactionAmount: 0,
            transactionType: 1,
            voucherNo: "",
            maturityDate: new Date(),
            particulars: "",
            showAccountHeadDialog: false,
            showAccountDialog: false,
            showLedgerDialog: false,
        });
    };

    const pageNavItems = [
        {
            title: "Bank Accounts",
            active: true,
            to: "/bank-accounts",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Bank Accounts">
            <Card>
                <Card.Body>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={4}>
                                <AccountHead
                                    handleChange={setAccountHeadId}
                                    accountHeadId={accountHeadId}
                                    setAlertStatus={setAlertStatus}
                                    errors={errors}
                                    loadTransactions={state.loadTransactions}
                                    setAccountId={setAccountId}
                                />
                            </Col>
                            <Col md={4}>
                                <Accounts
                                    handleChange={setAccountId}
                                    accountId={accountId}
                                    accountHeadId={accountHeadId}
                                    ledgerId={ledgerId}
                                    setAlertStatus={setAlertStatus}
                                    errors={errors}
                                    loadTransactions={state.loadTransactions}
                                    setLedgerId={setLedgerId}
                                />
                            </Col>
                            <Col md={4}>
                                <Ledger
                                    handleChange={setLedgerId}
                                    accountId={accountId}
                                    accountHeadId={accountHeadId}
                                    ledgerId={ledgerId}
                                    setAlertStatus={setAlertStatus}
                                    errors={errors}
                                    loadTransactions={state.loadTransactions}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <DatePickerInput
                                    errMessage={errors["transactionDate"]}
                                    icon="fa-calendar"
                                    label="Transaction Date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={state.transactionDate}
                                    onChange={(date) => onChangeHandler("transactionDate", date)}
                                />
                            </Col>
                            <Col md={2}>
                                <Input
                                    type="text"
                                    name="amount"
                                    errMessage={errors["amount"]}
                                    value={state.transactionAmount}
                                    label="Enter Amount"
                                    placeholder="Enter Amount"
                                    onChange={(e) =>
                                        onChangeHandler("transactionAmount", e.target.value)
                                    }
                                    controlId="Amount"
                                />
                            </Col>
                            <Col md={2}>
                                <SelectInput
                                    errMessage={errors["transactionType"]}
                                    name="transactionType"
                                    placeholder="Select Transaction Type"
                                    label="Select Transaction Type"
                                    type="text"
                                    Options={Utils.getTransactionTypes()}
                                    value={state.transactionType}
                                    handleChange={(e) =>
                                        onChangeHandler("transactionType", e.target.value)
                                    }
                                    controlId="TransactionType"
                                />
                            </Col>
                            <Col md={2}>
                                <Input
                                    type="text"
                                    name="voucherNo"
                                    errMessage={errors["voucherNo"]}
                                    value={state.voucherNo}
                                    label="Enter Voucher Number"
                                    placeholder="Voucher Number"
                                    onChange={(e) => onChangeHandler("voucherNo", e.target.value)}
                                    controlId="Voucher"
                                />
                            </Col>
                            <Col md={4}>
                                <DatePickerInput
                                    errMessage={errors["maturityDate"]}
                                    icon="fa-calendar"
                                    label="Maturity Date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={state.maturityDate}
                                    onChange={(date) => onChangeHandler("maturityDate", date)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextArea
                                    type="text"
                                    name="particulars"
                                    errMessage={errors["particulars"]}
                                    value={state.particulars}
                                    label="Particulars"
                                    placeholder="Particulars"
                                    onChange={(e) => onChangeHandler("particulars", e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <br />
                                <Button variant="primary" type="Submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={resetform} type="Button">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <ListTransactions
                            accountId={accountId}
                            accountHeadId={accountHeadId}
                            ledgerId={ledgerId}
                            setAlertStatus={setAlertStatus}
                            displayText={
                                stateObject.balance && stateObject.balance !== 0
                                    ? " Total Balance : " +
                                      Utils.currencyFormat(stateObject.balance)
                                    : ""
                            }
                        />
                        <Row>
                            <Col md={12}>
                                <RequestStatus
                                    stateObj={stateObject}
                                    alertStatus={alertStatus}
                                    setAlertStatus={setAlertStatus}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default BankTransaction;
