import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Input, SearchableSelect } from "Components/UI/Input";
import * as Utils from "Helpers/utils";

import { createScheme, updateScheme } from "Actions/Vendors/currentAccount.action";

const SchemeDialog = (props) => {
    const [state, setState] = useState({
        schemeName: "",
        interestRate: 0,
    });

    const dispatch = useDispatch();
    const stateObject = useSelector((state) => state.currentAccount);

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const onChangeHandler = (field, value) => {
        setState({ ...state, [field]: value });
    };

    useEffect(() => {
        if (props.schemeId) {
            const scheme = stateObject.schemes.find((item) => props.schemeId === item.schemeId);
            if (scheme) {
                setState({ schemeName: scheme.schemeName, interestRate: scheme.interestRate });
            }
        } else {
            setState({
                schemeName: "",
                interestRate: 0,
            });
        }
    }, [props.schemeId]);

    const submitForm = (e) => {
        props.setAlertStatus(true);
        if (props.schemeId) {
            dispatch(
                updateScheme({
                    id: props.schemeId,
                    schemeId: props.schemeId,
                    schemeName: state.schemeName,
                    interestRate: state.interestRate,
                })
            );
        } else {
            dispatch(
                createScheme({ schemeName: state.schemeName, interestRate: state.interestRate })
            );
        }
    };
    const resetForm = (e) => {
        setState({ schemeName: "", interestRate: 0 });
    };
    return (
        <Modal show={props.showDialog} onHide={props.setDialog} centered="true" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>New Scheme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="schemeName"
                            controlId="formschemeName"
                            label="Enter Account Name"
                            placeholder="Enter Account Name"
                            value={state.schemeName}
                            onChange={(e) => {
                                onChangeHandler("schemeName", e.target.value);
                            }}
                            errMessage={errors["schemeName"]}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="interestRate"
                            controlId="forminterestRate"
                            label="Interest Rate"
                            value={state.interestRate}
                            onChange={(e) => {
                                onChangeHandler("interestRate", e.target.value);
                            }}
                            errMessage={errors["interestRate"]}
                        />
                    </Col>
                    <Col md={4} className="mt-5px">
                        <br />
                        <Button variant="primary" onClick={submitForm}>
                            Submit
                        </Button>
                        &nbsp;
                        <Button variant="secondary" onClick={resetForm}>
                            Reset
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

const Scheme = (props) => {
    const [showDialog, setDialog] = useState(false);

    const stateObject = useSelector((state) => state.currentAccount);

    const schemeOptions = Utils.getSearchableOptions(stateObject.schemes, "schemeName", "schemeId");

    return (
        <>
            <SchemeDialog
                showDialog={showDialog}
                setDialog={setDialog}
                setAlertStatus={props.setAlertStatus}
                schemeId={props.schemeId}
            />
            <Row>
                <Col md={10} style={{ paddingRight: "0px" }}>
                    <SearchableSelect
                        label="Select Scheme"
                        Options={schemeOptions}
                        controlId="formScheme"
                        name="scheme"
                        value={props.schemeId}
                        errMessage={props.errMessage}
                        onChange={(selectedOption) => {
                            if (selectedOption.length > 0) {
                                props.setSchemeId(selectedOption[0].value);
                            }
                        }}
                    />
                </Col>
                <Col md={2} style={{ paddingLeft: "1px" }}>
                    <br />
                    <Button
                        variant="secondary"
                        className="mt-7px"
                        onClick={() => {
                            setDialog(true);
                        }}
                    >
                        ...
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default Scheme;
