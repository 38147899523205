const getActions = (ActionConstant) => {
    const create = (data) => {
        return {
            type: ActionConstant["CREATE"],
            payload: data,
            success: ActionConstant["CREATE_SUCCESS"],
        };
    };

    const remove = (data) => {
        return {
            type: ActionConstant["DELETE"],
            payload: data,
            success: ActionConstant["DELETE_SUCCESS"],
        };
    };

    const update = (data) => {
        return {
            type: ActionConstant["UPDATE"],
            payload: data,
            success: ActionConstant["UPDATE_SUCCESS"],
        };
    };

    const getAll = () => {
        return {
            type: ActionConstant["GET_ALL"],
            payload: null,
            success: ActionConstant["GET_ALL_SUCCESS"],
        };
    };

    const getOne = (data) => {
        return {
            type: ActionConstant["GET"],
            payload: data,
            success: ActionConstant["GET_SUCCESS"],
        };
    };

    const failed = (data) => {
        return {
            type: ActionConstant["ERROR"],
            payload: data,
        };
    };
    return {
        create,
        update,
        remove,
        getOne,
        getAll,
        failed,
    };
};

export default getActions;
