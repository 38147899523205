import React from "react";
import { Row, Col } from "react-bootstrap";
import { Input, SelectInput, DatePickerInput, FileInput, TextArea } from "Components/UI/Input";
import * as Utils from "Helpers/utils";
const VehicleLoan = (props) => {
    const vehicleOptions = Utils.getVehicleOptions();
    const vehicleTypeOptions = Utils.getVehicleType();
    const yearOptions = Utils.getYearOptions();
    return (
        <>
            <Row>
                <Col md="4">
                    <SelectInput
                        label="Vehicle"
                        placeholder="Vehicle"
                        Options={vehicleOptions}
                        controlId="formVehicle"
                        name="vehicle"
                        value={props.vehicle}
                        handleChange={(e) => props.setVehicle(e.target.value)}
                        errMessage={props.errors["vehicle"]}
                    />
                </Col>
                <Col md="4">
                    <SelectInput
                        label="Vehicle Type"
                        placeholder="Vehicle Type"
                        Options={vehicleTypeOptions}
                        controlId="formVehicleType"
                        name="vehicleType"
                        value={props.vehicleType}
                        handleChange={(e) => props.setVehicleType(e.target.value)}
                        errMessage={props.errors["vehicleType"]}
                    />
                </Col>
                <Col md="4">
                    <Input
                        type="text"
                        name="vehicleMake"
                        id="vehicleMake"
                        label="Vehicle Make"
                        placeholder="Vehicle Make"
                        value={props.vehicleMake}
                        onChange={(e) => props.setVehicleMake(e.target.value)}
                        controlId="vehicleMake"
                        errMessage={props.errors["vehicleMake"]}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Input
                        type="text"
                        name="vehicleModel"
                        id="vehicleModel"
                        label="Vehicle Model Name"
                        placeholder="Vehicle Model Name"
                        value={props.vehicleModel}
                        onChange={(e) => props.setVehicleModel(e.target.value)}
                        controlId="vehicleModelName"
                        errMessage={props.errors["vehicleModelName"]}
                    />
                </Col>
                <Col md="4">
                    <Input
                        type="text"
                        name="vehicleChassiNo"
                        id="vehicleChassiNo"
                        label="Vehicle Chassi No"
                        placeholder="Vehicle Chassi No"
                        value={props.vehicleChassiNo}
                        onChange={(e) => props.setVehicleChassiNo(e.target.value)}
                        controlId="vehicleChassiNo"
                        errMessage={props.errors["vehicleChassiNo"]}
                    />
                </Col>
                <Col md="4">
                    <Input
                        type="text"
                        name="vehicleEngineNo"
                        id="vehicleEngineNo"
                        label="Vehicle Engine No"
                        placeholder="Vehicle Engine No"
                        value={props.vehicleEngineNo}
                        onChange={(e) => props.setVehicleEngineNo(e.target.value)}
                        controlId="vehicleEngineNo"
                        errMessage={props.errors["vehicleEngineNo"]}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Input
                        type="text"
                        name="vehicleNo"
                        id="vehicleNo"
                        label="Vehicle No"
                        placeholder="Vehicle No"
                        value={props.vehicleNo}
                        onChange={(e) => props.setVehicleNo(e.target.value)}
                        controlId="vehicleNo"
                        errMessage={props.errors["vehicleNo"]}
                    />
                </Col>
                <Col md="4">
                    <Input
                        type="text"
                        name="insuranceCompany"
                        id="insuranceCompany"
                        placeholder="Insurance Company"
                        label="Insurance Company"
                        value={props.insuranceCompany}
                        onChange={(e) => props.setInsuranceCompany(e.target.value)}
                        controlId="insuranceCompany"
                        errMessage={props.errors["insuranceCompany"]}
                    />
                </Col>
                <Col md="4">
                    <Input
                        type="text"
                        name="insurancePolicyNo"
                        id="insurancePolicyNo"
                        label="Insurance Policy No"
                        placeholder="Insurance Policy No"
                        value={props.insurancePolicyNo}
                        onChange={(e) => props.setInsurancePolicyNo(e.target.value)}
                        controlId="insurancePolicyNo"
                        errMessage={props.errors["insurancePolicyNo"]}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <SelectInput
                        label="Insurance Date"
                        placeholder="Insurance Date"
                        Options={yearOptions}
                        controlId="formInsuranceDate"
                        name="insuranceDate"
                        value={props.insuranceDate}
                        handleChange={(e) => props.setInsuranceDate(e.target.value)}
                        errMessage={props.errors["insuranceDate"]}
                    />
                </Col>
                <Col md="4">
                    <DatePickerInput
                        errMessage={props.errors["insuranceValidity"]}
                        icon="fa-calendar"
                        label="Issue Date"
                        dateFormat="yyyy-MM-dd"
                        selected={props.insuranceValidity}
                        onChange={(date) => props.setInsuranceValidity(date)}
                    />
                </Col>
                <Col md="4">
                    <FileInput
                        name="hypothecationDocument"
                        label="Upload Hypthecation Document"
                        value={props.hypothecationDocument}
                        placeholder="Upload Hypthecation Document"
                        accept="image/*"
                        errMessage=""
                        controlId="formhypothecationDocument"
                        onChange={(e) => props.sethypothecationDocuments(e.target.files)}
                        multiple={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <TextArea
                        name="hypothecationDetails"
                        placeholder="Hypothecation Details"
                        label="Hypothecation Details"
                        value={props.hypothecationDetails}
                        onChange={(e) => props.setHypothecationDetails(e.target.value)}
                        controlId="hypothecationDetails"
                        errMessage={props.errors["hypothecationDetails"]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default VehicleLoan;
