import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { SearchableSelect } from "Components/UI/Input";
import * as Utils from "Helpers/utils";

const AccountHolderOptions = (props) => {
    const accountHolderState = useSelector((state) => state.accountHolder);
    const accountHolderOptions = Utils.getSearchableOptions(
        accountHolderState.accountHolders,
        "name",
        "id"
    );
    return (
        <Row>
            <Col md={12} style={{ paddingRight: "0px" }}>
                <SearchableSelect
                    label="Select Account Holder"
                    Options={accountHolderOptions}
                    controlId="formAccuntHolder"
                    name="accountHolder"
                    value={props.accountHolderId}
                    errMessage={props.errMessage}
                    onChange={(selectedOption) => {
                        if (selectedOption.length > 0) {
                            props.setAccountHolderId(selectedOption[0].value);
                        }
                    }}
                />
            </Col>
        </Row>
    );
};

export default AccountHolderOptions;
