import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageNav = (props) => {
    /*  const setActive = (e, cb) => {
        $(".nav-pills .nav-item .nav-link").removeClass("active");
        e.target.className += " active";
        cb();
    }; */
    const getLink = (item, idx) => {
        return item.active ? (
            <li className="breadcrumb-item active" key={idx}>
                <Nav.Link key={"nav-link" + idx} as={Link} to={item.to}>
                    {item.title}
                </Nav.Link>
            </li>
        ) : (
            <li className="breadcrumb-item" key={idx}>
                {item.title}
            </li>
        );
    };

    return (
        <>
            {/* <Breadcrumb>
                <Nav variant="pills">
                    {props.pageNavItems.map((item, idx) =>
                        item.link && item.eventName === null
                            ? getLink(item, idx)
                            : getButton(item, idx)
                    )}
                </Nav>
            </Breadcrumb> */}
            <div className="page-title-box">
                <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                            <Nav.Link key="main-nav-link" to="/" as={Link}>
                                coreFin
                            </Nav.Link>
                        </li>
                        {props.pageNavItems.map((item, idx) => getLink(item, idx))}
                    </ol>
                </div>
                <h4 className="page-title">{props.pageTitle}</h4>
            </div>
        </>
    );
};

export default PageNav;
