import { takeLatest, call, put } from "redux-saga/effects";
import BaseSaga from "../base.saga";
import { AccountHolderConstants as ActionConstant } from "Actions/constants";
import * as REST from "Services/rest.service";
import { failed } from "Actions/Vendors/accountHolder.action";
import { logout } from "Actions/auth";
import { TOKEN_EXPIRED } from "Actions/constants";

const endPoint = "/vendor/account-holders";

const sagaWatchers = BaseSaga(endPoint, failed);

const SagaActions = {
    ...sagaWatchers,
};

const getFormData = (data) => {
    let formData = new FormData();
    formData.set("name", data.name);
    formData.set("mobile", data.mobile);
    if (data.oldMobile) {
        formData.set("oldMobile", data.oldMobile);
    }
    formData.set("email", data.email);
    formData.set("address", data.address);
    formData.set("pan", data.pan);
    formData.set("aadhar", data.aadhar);
    formData.set("permanentAddress", data.permanentAddress);
    formData.set("nomineeName", data.nomineeName);
    formData.set("nomineeAge", data.nomineeAge);
    formData.set("nomineeRelation", data.nomineeRelation);
    formData.set("profileImage", data.profileImage);
    formData.set("panImage", data.panImage);
    formData.set("aadharImage", data.aadharImage);
    formData.set("status", data.status);
    formData.set("dob", data.dob);

    return formData;
};

function* update(action) {
    try {
        const formData = getFormData(action.payload);
        let response = yield call(REST.postFormdData(endPoint + "/" + action.payload.id, formData));
        if (!response) {
            let err = new Error("Request Failed");
            err.status = 500;
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        console.log(err);
        yield put(failed({ err }));
    }
}

function* create(action) {
    try {
        const formData = getFormData(action.payload);
        let response = yield call(REST.postFormdData(endPoint, formData));
        if (!response) {
            let err = new Error("Request Failed");
            err.status = 500;
            throw err;
        }
        if (response.status === 200) {
            yield put({ type: action.success, payload: response.data });
        } else if (response.data.status === TOKEN_EXPIRED) {
            yield put(logout());
        } else {
            yield put(failed({ ...response.data }));
        }
    } catch (err) {
        console.log(err);
        yield put(failed({ err }));
    }
}

export function* watchAccountHolderRequests() {
    yield takeLatest(ActionConstant.CREATE, create);
    yield takeLatest(ActionConstant.UPDATE, update);
    yield takeLatest(ActionConstant.DELETE, SagaActions.removePut);
    yield takeLatest(ActionConstant.GET_ALL, SagaActions.getAll);
    yield takeLatest(ActionConstant.GET, SagaActions.getOne);
}
