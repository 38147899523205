export const STATIC_FILES_PATH = "http://localhost:4040/static/";
export const TRANSACTION_DELETE_END_POINT = "/vendor/delete-transaction";

export const TOKEN_EXPIRED = 402;

export const TRANSACTION_DEPOSIT = 1;
export const TRANSACTION_WITHDRAW = 2;
export const TRANSACTION_INTEREST_RECIEVED = 3;
export const TRANSACTION_INTEREST_PAID = 4;
export const TRANSACTION_CHARGES = 5;
export const TRANSACTION_CONTRA = 6;

export const PIGMY_TRANSACTIONS = "pigmy";
export const SAVING_TRANSACTIONS = "saving";
export const CURRENT_TRANSACTIONS = "current";
export const FD_TRANSACTIONS = "fd";

const getRestConstants = (prefix) => {
    const object = {
        GET: `${prefix}_GET`,
        GET_SUCCESS: `${prefix}_GET_SUCCESS`,

        GET_ALL: `${prefix}_GET_ALL`,
        GET_ALL_SUCCESS: `${prefix}_GET_ALL_SUCCESS`,

        UPDATE: `${prefix}_UPDATE`,
        UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,

        DELETE: `${prefix}_DELETE`,
        DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,

        CREATE: `${prefix}_CREATE`,
        CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,

        ERROR: `${prefix}_ERROR`,
    };
    return object;
};

export const authConstants = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",

    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

    IS_AUTHENTICATED: "IS_AUTHENTICATED",
    TOKEN_REFRESH_REQUEST: "TOKEN_REFRESH_REQUEST",
    TOKEN_REFRESH_SUCCESS: "TOKEN_REFRESH_SUCCESS",
};

export const TenantConstants = {
    ...getRestConstants("TENANT"),
    DB_MIGRATE: "DB_MIGRATE",
    DB_MIGRATE_SUCCESS: "DB_MIGRATE_SUCCESS",
};

export const SocietyConstant = {
    ...getRestConstants("SOCIETY"),
};

//Vendor Constants
export const UserConstant = {
    ...getRestConstants("USER"),
};

export const AccountHolderConstants = {
    ...getRestConstants("ACCOUNT_HOLDERS"),
};

export const PigmyAccountConstants = {
    ...getRestConstants("PIGMY_ACCOUNT_CONSTANTS"),
    PIGMY_ACCOUNT_TRANSACTION: "PIGMY_ACCOUNT_TRANSACTION",
    PIGMY_ACCOUNT_TRANSACTION_SUCCESS: "PIGMY_ACCOUNT_TRANSACTION_SUCCESS",

    PIGMY_UPLOAD_TRANSACTIONS: "PIGMY_UPLOAD_TRANSACTIONS",
    PIGMY_UPLOAD_TRANSACTIONS_SUCCESS: "PIGMY_UPLOAD_TRANSACTIONS_SUCCESS",

    PIGMY_DOWNLOAD_TRANSACTIONS: "PIGMY_DOWNLOAD_TRANSACTION",
    PIGMY_DOWNLOAD_TRANSACTIONS_SUCCESS: "PIGMY_DOWNLOAD_TRANSACTIONS_SUCCESS",

    PIGMY_ACCOUNT_DEPENDENCIES: "PIGMY_ACCOUNT_DEPENDENCIES",
    PIGMY_ACCOUNT_DEPENDENCIES_SUCCESS: "PIGMY_ACCOUNT_DEPENDENCIES_SUCCESS",
    GET_PIGMY_AGENTS: "GET_PIGMY_AGENTS",
    GET_PIGMY_AGENTS_SUCCESS: "GET_PIGMY_AGENTS_SUCCESS",

    GET_PIGMY_ACCOUNT: "GET_PIGMY_ACCOUNT",
    GET_PIGMY_ACCOUNT_SUCCESS: "GET_PIGMY_ACCOUNT_SUCCESS",

    GET_PIGMY_ACCOUNT_TRANSACTION: "GET_PIGMY_ACCOUNT_TRANSACTION",
    GET_PIGMY_ACCOUNT_TRANSACTION_SUCCESS: "GET_PIGMY_ACCOUNT_TRANSACTION_SUCCESS",

    GET_PIGMY_APPROVAL_TRANSACTIONS: "GET_PIGMY_APPROVAL_TRANSACTIONS",
    GET_PIGMY_APPROVAL_TRANSACTIONS_SUCCESS: "GET_PIGMY_APPROVAL_TRANSACTIONS_SUCCESS",
    SET_SELECTED_APPROVAL_TRANSACTIONS: "SET_SELECTED_APPROVAL_TRANSACTIONS",
    SET_SELECTED_APPROVAL_TRANSACTION: "SET_SELECTED_APPROVAL_TRANSACTION",
    PIGMY_DELETE_APPROVAL_TRANSACTION: "PIGMY_DELETE_APPROVAL_TRANSACTION",
    PIGMY_DELETE_APPROVAL_TRANSACTION_SUCCESS: "PIGMY_DELETE_APPROVAL_TRANSACTION_SUCCESS",

    POST_PIGMY_ACCOUNT_APPROVAL: "POST_PIGMY_ACCOUNT_APPROVAL",
    POST_PIGMY_ACCOUNT_APPROVAL_SUCCESS: "POST_PIGMY_ACCOUNT_APPROVAL_SUCCESS",

    PIGMY_ACCOUNT_DETAILS_DEPENDENCY: "PIGMY_ACCOUNT_DETAILS_DEPENDENCY",
    PIGMY_ACCOUNT_DETAILS_DEPENDENCY_SUCCESS: "PIGMY_ACCOUNT_DETAILS_DEPENDENCY_SUCCESS",

    PIGMY_TRANSACTION_DELETE: "PIGMY_TRANSACTION_DELETE",
    PIGMY_TRANSACTION_DELETE_SUCCESS: "PIGMY_TRANSACTION_DELETE_SUCCESS",
};

export const ApproveDeletedConstants = {
    DELETE_TRANSACTION_ERROR: "DELETE_TRANSACTION_ERROR",

    GET_DELETED_TRANSACTIONS: "GET_DELETED_TRANSACTIONS",
    GET_DELETED_TRANSACTIONS_SUCCESS: "GET_DELETED_TRANSACTION_SUCCESS",

    APPROVE_DELETED_TRANSACTIONS: "APPROVE_DELETED_TRANSACTIONS",
    APPROVE_DELETED_TRANSACTIONS_SUCCESS: "APPROVE_DELETED_TRANSACTIONS_SUCCESS",

    SET_SELECTED_APPROVAL_TRANSACTIONS: "SET_SELECTED_DELETED_APPROVAL_TRANSACTIONS",
    SET_SELECTED_APPROVAL_TRANSACTION: "SET_SELECTED_DELETED_APPROVAL_TRANSACTION",

    DELETE_TRANSACTION: "DELETE_TRANSACTION",
    DELETE_TRANSACTION_SUCCESS: "DELETE_TRANSACTION_SUCCESS",
};

export const LoanAccountConstants = {
    ...getRestConstants("LOAN_ACCOUNT"),
    GET_LOAN_ACCOUNT_CATEGORY: "GET_LOAN_ACCOUNT_CATEGORY",
    GET_LOAN_ACCOUNT_CATEGORY_SUCCESS: "GET_LOAN_ACCOUNT_CATEGORY_SUCCESS",

    CREATE_LOAN_SCHEME: "CREATE_LOAN_SCHEME",
    CREATE_LOAN_SCHEME_SUCCESS: "CREATE_LOAN_SCHEME_SUCCESS",

    GET_LOAN_SCHEMES: "GET_LOAN_SCHEMES",
    GET_LOAN_SCHEMES_SUCCESS: "GET_LOAN_SCHEMES_SUCCESS",

    GET_LOAN_ACCOUNT_DEPENDENCIES: "GET_LOAN_ACCOUNT_DEPENDENCIES",
    GET_LOAN_ACCOUNT_DEPENDENCIES_SUCCESS: "GET_LOAN_ACCOUNT_DEPENDENCIES_SUCCESS",

    GET_LOAN_ACCOUNT_NUMBER: "GET_LOAN_ACCOUNT_NUMBER",
    GET_LOAN_ACCOUNT_NUMBER_SUCCESS: "GET_LOAN_ACCOUNT_NUMBER_SUCCESS",

    ADD_PLEDGE_ITEM: "ADD_PLEDGE_ITEM",
    REMOVE_PLEDGE_ITEM: "REMOVE_PLEDGE_ITEM",
};

export const BankAccountHeadContants = {
    ...getRestConstants("BANK_ACCOUNTS_HEADS"),
};

export const BankAccountConstants = {
    GET_BANK_ACCOUNT_DEPENDENCIES: "GET_LOAN_ACCOUNT_DEPENDENCIES",
    GET_BANK_ACCOUNT_DEPENDENCIES_SUCCESS: "GET_LOAN_ACCOUNT_DEPENDENCIES_SUCCESS",

    GET_BANK_ACCOUNT_TRANSACTION: "GET_BANK_ACCOUNT_TRANSACTION",
    GET_BANK_ACCOUNT_TRANSACTION_SUCCESS: "GET_BANK_ACCOUNT_TRANSACTION_SUCCESS",

    ...getRestConstants("BANK_ACCOUNTS"),

    GET_ALL_TRANSACTION: "GET_ALL_TRANSACTION",
    GET_ALL_TRANSACTION_SUCCESS: "GET_ALL_TRANSACTION_SUCCESS",

    CREATE_TRANSACTION: "CREATE_TRANSACTION",
    CREATE_TRANSACTION_SUCCESS: "CREATE_TRANSACTION_SUCCESS",

    DELETE_TRANSACTION: "DELETE_BANK_TRANSACTION",
    DELETE_TRANSACTION_SUCCESS: "DELETE_BANK_TRANSACTION_SUCCESS",

    UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
    UPDATE_TRANSACTION_SUCCESS: "UPDATE_TRANSACTION_SUCCESS",

    GET_LEDGER_TRANSACTION: "GET_LEDGER_TRANSACTION",
    GET_LEDGER_TRANSACTION_SUCCESS: "GET_LEDGER_TRANSACTION_SUCCESS",

    BA_DELETE_APPROVAL_TRANSACTION: "BA_DELETE_APPROVAL_TRANSACTION",
    BA_DELETE_APPROVAL_TRANSACTION_SUCCESS: "BA_DELETE_APPROVAL_TRANSACTION_SUCCESS",

    BA_GET_APPROVAL_TRANSACTIONS: "BA_GET_APPROVAL_TRANSACTIONS",
    BA_GET_APPROVAL_TRANSACTIONS_SUCCESS: "BA_GET_APPROVAL_TRANSACTIONS_SUCCESS",

    BA_SET_SELECTED_APPROVAL_TRANSACTIONS: "BA_SET_SELECTED_APPROVAL_TRANSACTIONS",

    BA_SET_SELECTED_APPROVAL_TRANSACTION: "BA_SET_SELECTED_APPROVAL_TRANSACTION",

    BA_APPROVE_TRANSACTIONS: "BA_APPROVE_TRANSACTIONS",
    BA_APPROVE_TRANSACTIONS_SUCCESS: "BA_APPROVE_TRANSACTIONS_SUCCESS",

    BA_DELETE_TEMP_TRANSACTION: "BA_DELETE_TEMP_TRANSACTION",
    BA_DELETE_TEMP_TRANSACTION_SUCCESS: "BA_DELETE_TEMP_TRANSACTION_SUCCESS",
};

export const BankAccountLedgerConstants = {
    ...getRestConstants("BANK_ACCOUNTS_LEDGER"),
};

export const dayBeginEndConstants = {
    GET_CURRENT_OPENING_DATE: "GET_CURRENT_OPENING_DATE",
    GET_CURRENT_OPENING_DATE_SUCCESS: "GET_CURRENT_OPENING_DATE_SUCCESS",

    GET_ALL: "GET_ALL_DAY_BEGIN_END",
    GET_ALL_SUCCESS: "GET_ALL_DAY_BEGIN_END_SUCCESS",

    CLOSE_DAY: "CLOSE_DAY",
    CLOSE_DAY_SUCCESS: "CLOSE_DAY_SUCCESS",

    ERROR: `DAY_BEGIN_END_ERROR`,
};

export const SavingAccountConstants = {
    ...getRestConstants("SAVING_ACCOUNT"),

    CREATE_SAVING_ACCOUNT_SCHEME: "CREATE_SAVING_ACCOUNT_SCHEME",
    CREATE_SAVING_ACCOUNT_SCHEME_SUCCESS: "CREATE_SAVING_ACCOUNT_SCHEME_SUCCESS",

    UPDATE_SAVING_ACCOUNT_SCHEME: "UPDATE_SAVING_ACCOUNT_SCHEME",
    UPDATE_SAVING_ACCOUNT_SCHEME_SUCCESS: "UPDATE_SAVING_ACCOUNT_SCHEME_SUCCESS",

    GET_ALL_SAVING_ACCOUNT_SCHEMES: "GET_ALL_SAVING_ACCOUNT_SCHEMES",
    GET_ALL_SAVING_ACCOUNT_SCHEMES_SUCCESS: "GET_ALL_SAVING_ACCOUNT_SCHEMES_SUCCESS",

    GET_SAVING_ACCOUNT_SCHEME: "GET_SAVING_ACCOUNT_SCHEME",
    GET_SAVING_ACCOUNT_SCHEME_SUCCESS: "GET_SAVING_ACCOUNT_SCHEME_SUCCESS",

    GET_SAVING_ACCOUNT_DEPENDENCIES: "GET_SAVING_ACCOUNT_DEPENDENCIES",
    GET_SAVING_ACCOUNT_DEPENDENCIES_SUCCESS: "GET_SAVING_ACCOUNT_DEPENDENCIES_SUCCESS",

    CREATE_SAVING_ACCOUNT_TRANSACTION: "CREATE_SAVING_ACCOUNT_TRANSACTION",
    CREATE_SAVING_ACCOUNT_TRANSACTION_SUCCESS: "CREATE_SAVING_ACCOUNT_TRANSACTION_SUCCESS",

    GET_ALL_SAVING_ACCOUNT_TRANSACTIONS: "GET_ALL_SAVING_ACCOUNT_TRANSACTIONS",
    GET_ALL_SAVING_ACCOUNT_TRANSACTIONS_SUCCESS: "GET_ALL_SAVING_ACCOUNT_TRANSACTIONS_SUCCESS",

    DELETE_SAVING_ACCOUNT_TRANSACTION: "DELETE_SAVING_ACCOUNT_TRANSACTION",
    DELETE_SAVING_ACCOUNT_TRANSACTION_SUCCESS: "DELETE_SAVING_ACCOUNT_TRANSACTION_SUCCESS",

    DELETE_SAVING_ACCOUNT_APPROVAL_TRANSACTION: "DELETE_SAVING_ACCOUNT_APPROVAL_TRANSACTION",
    DELETE_SAVING_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS:
        "DELETE_SAVING_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS",

    GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION: "GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION",
    GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS:
        "GET_SAVING_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS",

    APPROVE_SAVING_ACCOUNT_TRANSACTION: "APPROVE_SAVING_ACCOUNT_TRANSACTION",
    APPROVE_SAVING_ACCOUNT_TRANSACTION_SUCCESS: "APPROVE_SAVING_ACCOUNT_TRANSACTION_SUCCESS",

    SET_SELECTED_SAVING_ACCOUNT_TRANSACTION: "SET_SELECTED_SAVING_ACCOUNT_TRANSACTION",
    SET_SELECTED_SAVING_ACCOUNT_TRANSACTIONS: "SET_SELECTED_SAVING_ACCOUNT_TRANSACTIONS",
};

export const CurrentAccountConstants = {
    ...getRestConstants("CURRENT_ACCOUNT"),

    CREATE_CURRENT_ACCOUNT_SCHEME: "CREATE_CURRENT_ACCOUNT_SCHEME",
    CREATE_CURRENT_ACCOUNT_SCHEME_SUCCESS: "CREATE_CURRENT_ACCOUNT_SCHEME_SUCCESS",

    UPDATE_CURRENT_ACCOUNT_SCHEME: "UPDATE_CURRENT_ACCOUNT_SCHEME",
    UPDATE_CURRENT_ACCOUNT_SCHEME_SUCCESS: "UPDATE_CURRENT_ACCOUNT_SCHEME_SUCCESS",

    GET_ALL_CURRENT_ACCOUNT_SCHEMES: "GET_ALL_CURRENT_ACCOUNT_SCHEMES",
    GET_ALL_CURRENT_ACCOUNT_SCHEMES_SUCCESS: "GET_ALL_CURRENT_ACCOUNT_SCHEMES_SUCCESS",

    GET_CURRENT_ACCOUNT_SCHEME: "GET_CURRENT_ACCOUNT_SCHEME",
    GET_CURRENT_ACCOUNT_SCHEME_SUCCESS: "GET_CURRENT_ACCOUNT_SCHEME_SUCCESS",

    GET_CURRENT_ACCOUNT_DEPENDENCIES: "GET_CURRENT_ACCOUNT_DEPENDENCIES",
    GET_CURRENT_ACCOUNT_DEPENDENCIES_SUCCESS: "GET_CURRENT_ACCOUNT_DEPENDENCIES_SUCCESS",

    CREATE_CURRENT_ACCOUNT_TRANSACTION: "CREATE_CURRENT_ACCOUNT_TRANSACTION",
    CREATE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS: "CREATE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS",

    GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS: "GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS",
    GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS_SUCCESS: "GET_ALL_CURRENT_ACCOUNT_TRANSACTIONS_SUCCESS",

    DELETE_CURRENT_ACCOUNT_TRANSACTION: "DELETE_CURRENT_ACCOUNT_TRANSACTION",
    DELETE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS: "DELETE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS",

    GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION: "GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION",
    GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS:
        "GET_CURRENT_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS",

    APPROVE_CURRENT_ACCOUNT_TRANSACTION: "APPROVE_CURRENT_ACCOUNT_TRANSACTION",
    APPROVE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS: "APPROVE_CURRENT_ACCOUNT_TRANSACTION_SUCCESS",

    SET_SELECTED_CURRENT_ACCOUNT_TRANSACTION: "SET_SELECTED_CURRENT_ACCOUNT_TRANSACTION",
    SET_SELECTED_CURRENT_ACCOUNT_TRANSACTIONS: "SET_SELECTED_CURRENT_ACCOUNT_TRANSACTIONS",

    DELETE_CURRENT_ACCOUNT_APPROVAL_TRANSACTION: "DELETE_CURRENT_ACCOUNT_APPROVAL_TRANSACTION",
    DELETE_CURRENT_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS:
        "DELETE_CURRENT_ACCOUNT_APPROVAL_TRANSACTION_SUCCESS",
};

export const FixedDepositConstant = {
    GET_FD_DEPENDEICIES: "GET_FD_DEPENDEICIES",
    GET_FD_DEPENDEICIES_SUCCESS: "GET_FD_DEPENDEICIES_SUCCESS",

    CREATE_FD_ACCOUNT: "CREATE_FD_ACCOUNT",
    CREATE_FD_ACCOUNT_SUCCESS: "CREATE_FD_ACCOUNT_SUCCESS",

    UPDATE_FD_ACCOUNT: "UPDATE_FD_ACCOUNT",
    UPDATE_FD_ACCOUNT_SUCCESS: "UPDATE_FD_ACCOUNT_SUCCESS",

    GET_FD_ACCOUNT: "GET_FD_ACCOUNT",
    GET_FD_ACCOUNT_SUCCESS: "GET_FD_ACCOUNT_SUCCESS",

    GET_ALL_FD_ACCOUNT: "GET_ALL_FD_ACCOUNT",
    GET_ALL_FD_ACCOUNT_SUCCESS: "GET_ALL_FD_ACCOUNT_SUCCESS",

    CREATE_FD_SCHEME: "CREATE_FD_SCHEME",
    CREATE_FD_SCHEME_SUCCESS: "CREATE_FD_SCHEME_SUCCESS",

    UPDATE_FD_SCHEME: "UPDATE_FD_SCHEME",
    UPDATE_FD_SCHEME_SUCCESS: "UPDATE_FD_SCHEME_SUCCESS",

    GET_FD_SCHEME: "GET_FD_SCHEME",
    GET_FD_SCHEME_SUCCESS: "GET_FD_SCHEME_SUCCESS",

    GET_ALL_FD_SCHEME: "GET_ALL_FD_SCHEME",
    GET_ALL_FD_SCHEME_SUCCESS: "GET_ALL_FD_SCHEME_SUCCESS",

    SET_SELECTED_FD_TRANSACTIONS: "SET_SELECTED_FD_TRANSACTIONS",
    SET_SELECTED_FD_TRANSACTION: "SET_SELECTED_FD_TRANSACTION",

    CREATE_FD_TRANSACTION: "CREATE_FD_TRANSACTION",
    CREATE_FD_TRANSACTION_SUCCESS: "CREATE_FD_TRANSACTION_SUCCESS",

    DELETE_FD_TRANSACTION: "DELETE_FD_TRANSACTION",
    DELETE_FD_TRANSACTION_SUCCESS: "DELETE_FD_TRANSACTION_SUCCESS",

    GET_FD_APPROVAL_TRANSACTION: "GET_FD_APPROVAL_TRANSACTION",
    GET_FD_APPROVAL_TRANSACTION_SUCCESS: "GET_FD_APPROVAL_TRANSACTION_SUCCESS",

    APPROVE_FD_TRANSACTION: "APPROVE_FD_TRANSACTION",
    APPROVE_FD_TRANSACTION_SUCCESS: "APPROVE_FD_TRANSACTION_SUCCESS",

    DELETE_FD_APPROVAL_TRANSACTION: "DELETE_FD_APPROVAL_TRANSACTION",
    DELETE_FD_APPROVAL_TRANSACTION_SUCCESS: "DELETE_FD_APPROVAL_TRANSACTION_SUCCESS",

    GET_FD_TRANSACTION: "GET_FD_TRANSACTION",
    GET_FD_TRANSACTION_SUCCESS: "GET_FD_TRANSACTION_SUCCESS",

    CALCULATE_FD_INTEREST_PAYBLE: "CALCULATE_FD_INTEREST_PAYBLE",
    CALCULATE_FD_INTEREST_PAYBLE_SUCCESS: "CALCULATE_FD_INTEREST_PAYBLE_SUCCESS",

    ADD_FD_INTEREST_PAYBLE: "ADD_FD_INTEREST_PAYBLE",
    ADD_FD_INTEREST_PAYBLE_SUCCESS: "ADD_FD_INTEREST_PAYBLE_SUCCESS",

    GET_INTEREST_PAYBLE: "GET_INTEREST_PAYBLE",
    GET_INTEREST_PAYBLE_SUCCESS: "GET_INTEREST_PAYBLE_SUCCESS",

    GET_INTEREST_PAYBLE_DETAILS: "GET_INTEREST_PAYBLE_DETAILS",
    GET_INTEREST_PAYBLE_DETAILS_SUCCESS: "GET_INTEREST_PAYBLE_DETAILS_SUCCESS",

    GET_INTEREST_PAYBLE_DEFAULT: "GET_INTEREST_PAYBLE_DEFAULT",
    GET_INTEREST_PAYBLE_DEFAULT_SUCCESS: "GET_INTEREST_PAYBLE_DEFAULT_SUCCESS",

    UNDO_FD_INTEREST_PAYBLE: "UNDO_INTEREST_PAYBLE",
    UNDO_FD_INTEREST_PAYBLE_SUCCESS: "UNDO_INTEREST_PAYBLE_SUCCESS",

    GET_FD_INTEREST_DETAILS: "GET_FD_INTEREST_DETAILS",
    GET_FD_INTEREST_DETAILS_SUCCESS: "GET_FD_INTEREST_DETAILS_SUCCESS",
    PAY_FD_INTEREST: "PAY_FD_INTEREST",
    PAY_FD_INTEREST_SUCCESS: "PAY_FD_INTEREST_SUCCESS",

    ERROR: "FD_ERROR",
};
