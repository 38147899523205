import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "Components/Layout";
import * as TenantAction from "Actions/Admin/tenant.action";
import { Card, Row, Col, Button } from "react-bootstrap";
import DataTable from "Components/UI/DataTable";
import { Link } from "react-router-dom";
import { StatusBadge } from "Components/UI/Input";
import RequestStatus from "Helpers/RequestStatus";

class TenantDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            dbName: "",
            dbHost: "",
            dbUsername: "",
            dbPassword: "",
            dbPort: "",
            status: "",
            alertStatus: true,
        };
    }
    setAlertStatus = (status) => {
        this.setState({ alertStatus: status });
    };

    showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    removeTenant = (id) => {
        this.props.actions.remove({ id });
    };

    migrateDB = (id) => {
        this.props.actions.migrateDB({ id });
    };

    actionButtons = (cell, row) => {
        return (
            <>
                <Button
                    variant="link"
                    as={Link}
                    to={`/tenants/${row.id}`}
                    className="btn btn-sm"
                    title="Edit Database"
                >
                    <i className="fa fa-edit fa-lg" aria-hidden="true" />
                </Button>
                <Button
                    variant="link"
                    onClick={() => {
                        this.removeTenant(row.id);
                    }}
                    title="Remove Database"
                >
                    <i className="fa fa-trash fa-lg" aria-hidden="true" />
                </Button>
                <Button
                    variant="link"
                    onClick={() => {
                        this.migrateDB(row.id);
                    }}
                    title="Migrate Database"
                >
                    <i className="fa fa-database fa-lg" aria-hidden="true" />
                </Button>
            </>
        );
    };

    componentDidMount() {
        this.props.actions.getAll();
    }

    render() {
        //Nav Items
        const pageNavItems = [
            {
                title: "Databases",
                active: false,
                to: "/tenants",
            },
        ];
        const columns = [
            {
                dataField: "dbName",
                text: "Database Name",
                style: { textAlign: "center" },
                headerStyle: { textAlign: "center" },
            },
            {
                dataField: "dbHost",
                text: "Host",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "dbPort",
                text: "Port",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "dbUsername",
                text: "Username",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
            },
            {
                dataField: "dbPassword",
                text: "Password",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" /* "-webkit-text-security": "disc"  */ },
            },
            {
                dataField: "status",
                text: "Status",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" /* "-webkit-text-security": "disc"  */ },
                formatter: this.showBadge,
            },
            {
                dataField: "#",
                text: "Action",
                headerStyle: { textAlign: "center" },
                style: { textAlign: "center" },
                formatter: this.actionButtons,
            },
        ];
        return (
            <Layout pageNavItems={pageNavItems} pageTitle="Databases">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md="12">
                                <DataTable
                                    keyField="id"
                                    columns={columns}
                                    data={this.props.stateObject.details}
                                    search={true}
                                    pagination={true}
                                    button={true}
                                    buttonTitle="Create Database"
                                    buttonURI="/tenants/add"
                                    buttonVariant="success"
                                />
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={this.props.stateObject}
                                alertStatus={this.state.alertStatus}
                                setAlertStatus={this.setAlertStatus}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({
    tenants: state.tenants.details,
    error: state.tenants.error,
    stateObject: state.tenants,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(TenantAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantDetails);
