import moment from "moment";
import jwt_decode from "jwt-decode";
import { STATIC_FILES_PATH } from "Actions/constants";
import ModalImage from "react-modal-image";
import { Row, Col } from "react-bootstrap";
import { StatusBadge } from "Components/UI/Input";
import {
    TRANSACTION_DEPOSIT,
    TRANSACTION_WITHDRAW,
    TRANSACTION_INTEREST_PAID,
    TRANSACTION_INTEREST_RECIEVED,
    TRANSACTION_CONTRA,
    TRANSACTION_CHARGES,
} from "Actions/constants";

import { TransactionStatusBadge, TransactionType } from "Components/UI/Input";

export const ACCOUNT_TYPE_PIGMY = "ACCOUNT_TYPE_PIGMY";
export const ACCOUNT_TYPE_SAVING = "ACCOUNT_TYPE_SAVING";
//export const ACCOUNT_TYPE_CURRENT = "ACCOUNT_TYPE_CURRENT";

let transactionTypes = [];
transactionTypes[TRANSACTION_DEPOSIT] = "Deposit";
transactionTypes[TRANSACTION_WITHDRAW] = "Withdraw";
transactionTypes[TRANSACTION_CHARGES] = "Charges";
transactionTypes[TRANSACTION_INTEREST_PAID] = "Interst Paid";
transactionTypes[TRANSACTION_INTEREST_RECIEVED] = "Interst Recieved";
transactionTypes[TRANSACTION_CONTRA] = "Contra";

export const getSession = () => {
    try {
        const access_token = localStorage.getItem("access_token");
        const decoded_info = jwt_decode(access_token);
        return { ...decoded_info.user };
    } catch (error) {
        console.log(error);
    }
};
export const formatDateInTable = (cell, row) => {
    return moment(cell.toString()).format("YYYY-MM-DD h:mm:ss");
};

export const now = () => {
    return moment().format("YYYY-MM-DD");
};

export const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

export const getNextDate = (date, timeVal, indentifier) => {
    const formatedDate = moment(date).format("YYYY-MM-DD");

    return new Date(moment(formatedDate).add(timeVal, indentifier).format("YYYY-MM-DD"));
};

export const getOptions = (dataObj, label, value) => {
    let options = [];
    dataObj.map((item, index) => (options[item[value]] = item[label]));
    return options;
};

export const getNomineeRelationOptions = () => {
    let nomineeRelationOption = [];
    nomineeRelationOption["Father"] = "Father";
    nomineeRelationOption["Mother"] = "Mother";
    nomineeRelationOption["Son"] = "Son";
    nomineeRelationOption["Daughter"] = "Daughter";
    nomineeRelationOption["Brother"] = "Brother";
    nomineeRelationOption["Sister"] = "Sister";
    return nomineeRelationOption;
};

export const accountTypes = () => {
    let accountTypes = [];
    accountTypes["ACCOUNT_TYPE_PIGMY"] = "PIGMY";
    accountTypes["ACCOUNT_TYPE_SAVING"] = "SAVING ACCOUNT";
    //accountTypes["ACCOUNT_TYPE_CURRENT"] = "CURRENT ACCOUNT";
    return accountTypes;
};

export const getTransactionTypes = () => {
    return transactionTypes;
};

export const getTransactionTypeStr = (transactionType) => {
    return transactionTypes[transactionType];
};

export const getSearchableOptions = (dataObj, label, value) => {
    let options = [];
    options[0] = { label: "Select", value: "" };
    dataObj.map(
        (item, index) =>
            (options[index + 1] = {
                label: item[label],
                value: item[value],
            })
    );
    return options;
};

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const getDataFile = (filePath) => {
    const session = getSession();
    return (
        <>
            {filePath ? (
                <a
                    variant="primary"
                    href={STATIC_FILES_PATH + "S" + session.societyId + "/data/" + filePath}
                    type="button"
                    label="Download"
                    icon="fa fa-download"
                    size="lg"
                    className="btn btn-primary"
                    download
                >
                    <i className="fa fa-download" size="16" /> Download
                </a>
            ) : (
                ""
            )}
        </>
    );
};

export const getProfileImage = (profileImage) => {
    const session = getSession();
    return (
        <>
            {profileImage ? (
                <img
                    src={
                        STATIC_FILES_PATH +
                        "S" +
                        session.societyId +
                        "/profileImage/" +
                        profileImage
                    }
                    alt="Not Found"
                    width="100%"
                    height="150px"
                />
            ) : (
                DefaultImage()
            )}
        </>
    );
};

export const getAccountDetails = (accountObj) => {
    const showBadge = (cell, row) => {
        return <StatusBadge status={row.status} />;
    };

    const showBalance = () => {
        return (
            <div className="RowItem">
                <label>Balance </label>
                {currencyFormat(accountObj.balance)}
            </div>
        );
    };

    return (
        <>
            {accountObj && Object.keys(accountObj).length !== 0 ? (
                <Row className="CardView">
                    <Col md={4}>{getProfileImage(accountObj.profileImage)}</Col>
                    <Col md={8}>
                        <div className="RowItem">
                            <label>Name </label>
                            {accountObj.name}
                        </div>
                        <div className="RowItem">
                            <label>Mobile </label> {accountObj.mobile}
                        </div>
                        <div className="RowItem">
                            <label>Email </label>
                            {accountObj.email ? accountObj.email : "NA"}
                        </div>

                        <div className="RowItem">
                            <label>Status </label>
                            {showBadge(accountObj.status, accountObj)}
                        </div>
                        {accountObj.balance ? showBalance() : ""}
                    </Col>
                </Row>
            ) : (
                <Row className="CardView">
                    <Col md={4}>{getProfileImage(accountObj.profileImage)}</Col>
                    <Col md={8}>
                        <div className="RowItem">
                            <label>Name </label>
                            --
                        </div>
                        <div className="RowItem">
                            <label>Mobile </label>
                            --
                        </div>
                        <div className="RowItem">
                            <label>Email </label>
                            --
                        </div>

                        <div className="RowItem">
                            <label>Status </label>
                            --
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export const DefaultImage = () => {
    return (
        <img
            src={STATIC_FILES_PATH + "/image-not-available.jpg"}
            width="100%"
            height="150px"
            alt="Not Found"
        />
    );
};

export const getImage = (image, path) => {
    const session = getSession();
    return (
        <>
            {image ? (
                <img
                    src={STATIC_FILES_PATH + "S" + session.societyId + "/" + path + "/" + image}
                    alt="Not Found"
                />
            ) : (
                DefaultImage()
            )}
        </>
    );
};

export const getModalImage = (image, path, title = "") => {
    const session = getSession();
    const imageURL = STATIC_FILES_PATH + "S" + session.societyId + "/" + path + "/" + image;
    return <ModalImage small={imageURL} large={imageURL} alt={title} className="imageObject" />;
};

export const currencyFormat = (cell, row = null) => {
    return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(cell);
};

export const Search = (arr, searchKey) => {
    return arr.find((item) => {
        if (item.id === searchKey) {
            return item;
        }
    });
};

export const getVehicleOptions = () => {
    return { 1: "Commercial", 2: "Domestic" };
};

export const getVehicleType = () => {
    return { 1: "Two Wheeler", 2: "Four Wheeler" };
};

export const getYearOptions = () => {
    return { 1: "1 Year", 2: "2 Year", 3: "3 Year", 4: "4 Year", 5: "5 Year" };
};

export const dateDiff = (fromDateStr, toDateStr) => {
    const fromDate = moment(fromDateStr);
    const toDate = moment(toDateStr);
    const days = Math.abs(toDate.diff(fromDate, "days"));
    return days;
};

export const getNoOfDays = (fromDate, installments) => {
    let noOfDays = 0;
    let date = fromDate;
    for (let i = 1; i <= installments; i++) {
        date = moment(date).add(1, "month").calendar();
        date = moment(date).format("YYYY-MM-DD");
        noOfDays += moment(date, "YYYY-MM").daysInMonth();
    }
    return noOfDays - 1;
};

export const formatTransactionType = (cell, row) => {
    const typeStr = getTransactionTypeStr(cell);
    return <TransactionType type={cell} typeStr={typeStr} />;
};

export const showBadge = (cell, row) => {
    return <TransactionStatusBadge status={row.status} row={row} />;
};

export const getNomineeDetails = (accountHolderId, accountHolders) => {
    const accountHolder = accountHolders.find((item) => item.id === accountHolderId);
    return accountHolder;
};

export const getInterestRate = (data, days) => {
    if (!data) {
        return { depositInterest: 0, loanInterest: 0 };
    }

    if (days <= 30) {
        return { depositInterest: data.depositIntrest_15_30, loanInterest: data.loanIntrest_15_30 };
    } else if (days > 30 && days <= 45) {
        return { depositInterest: data.depositIntrest_30_45, loanInterest: data.loanIntrest_30_45 };
    } else if (days > 45 && days <= 90) {
        return { depositInterest: data.depositIntrest_45_3, loanInterest: data.loanIntrest_45_3 };
    } else if (days > 90 && days <= 180) {
        return { depositInterest: data.depositIntrest_3_6, loanInterest: data.loanIntrest_3_6 };
    } else if (days > 180 && days <= 365) {
        return { depositInterest: data.depositIntrest_6_12, loanInterest: data.loanIntrest_6_12 };
    } else if (days > 365 && days <= 730) {
        return { depositInterest: data.depositIntrest_12_24, loanInterest: data.loanIntrest_12_24 };
    } else if (days > 730 && days <= 1095) {
        return { depositInterest: data.depositIntrest_24_36, loanInterest: data.loanIntrest_24_36 };
    } else if (days > 1095) {
        return {
            depositInterest: data.depositIntrest_above_36,
            loanInterest: data.loanIntrest_above_36,
        };
    }
};
