import Layout from "Components/Layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    NomineeInput,
    SelectInput,
    DatePickerInput,
    SearchableSelect,
} from "Components/UI/Input";

import { Card, Row, Col, Form, Button } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import RequestStatus from "Helpers/RequestStatus";
import * as Actions from "Actions/Vendors/pigmyAccount.action";
import * as Utils from "Helpers/utils";

const PigmyAccount = ({ match }) => {
    const [alertStatus, setAlertStatus] = useState(true);
    const [agentId, setAgentId] = useState("");
    const [accountHolderId, setAccountHolderId] = useState("");
    const [ledgerNumber, setLedgerNumber] = useState("");
    const [folioNumber, setFolioNumber] = useState("");
    const [frequency, setFrequency] = useState("");
    const [amount, setAmount] = useState("");
    const [maturityDate, setMaturityDate] = useState(new Date());
    const [notifyMaturity, setNotifyMaturity] = useState(false);
    const [nomineeAge, setNomineeAge] = useState();
    const [nomineeName, setNomineeName] = useState();
    const [nomineeRelation, setNomineeRelation] = useState();
    const [introducer, setIntroducer] = useState();
    const [status, setStatus] = useState(false);

    const session = Utils.getSession();

    const {
        params: { id },
    } = match;

    const dispatch = useDispatch();
    const accountHolderState = useSelector((state) => state.accountHolder);
    const accountHolders = accountHolderState.accountHolders;

    const stateObject = useSelector((state) => state.pigmyAccount);
    const pigmyAccount = stateObject.accountHolder;
    const agents = stateObject.agents;

    let errors = [];
    if (typeof stateObject.errors !== "undefined") {
        stateObject.errors.map((item) => (errors[item.param] = item.msg));
    }

    const agentOptions = Utils.getSearchableOptions(agents, "name", "id");
    const accountHolderOptions = Utils.getSearchableOptions(accountHolders, "name", "id");

    const setNominee = () => {
        const selectedAccount = accountHolders.find((element) => {
            return element.id === accountHolderId;
        });

        if (selectedAccount) {
            setNomineeName(selectedAccount.nomineeName);
            setNomineeAge(selectedAccount.nomineeAge);
            setNomineeRelation(selectedAccount.nomineeRelation);
        }
    };

    useEffect(() => {
        if (pigmyAccount) {
            setAgentId(pigmyAccount.agentId);
            setAccountHolderId(pigmyAccount.accountHolderId);
            setLedgerNumber(pigmyAccount.ledgerNumber);
            setFolioNumber(pigmyAccount.folioNumber);
            setFrequency(pigmyAccount.frequency);
            setAmount(pigmyAccount.amount);
            setMaturityDate(new Date(pigmyAccount.maturityDate));
            setNotifyMaturity(pigmyAccount.notifyMaturity ? true : false);
            setNomineeName(pigmyAccount.nomineeName);
            setNomineeAge(pigmyAccount.nomineeAge);
            setNomineeRelation(pigmyAccount.nomineeRelation);
            setIntroducer(pigmyAccount.introducer);
            setStatus(pigmyAccount.status ? true : false);
        }
    }, [pigmyAccount]);

    useEffect(() => {
        if (id) {
            dispatch(Actions.getOne({ id }));
        }
        dispatch(Actions.pigmyDependencies({ id }));
    }, [dispatch, id]);

    const submitForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        const data = {
            agentId,
            accountHolderId,
            ledgerNumber,
            folioNumber,
            frequency,
            amount,
            maturityDate: Utils.formatDate(maturityDate),
            notifyMaturity: notifyMaturity ? 1 : 0,
            status: status ? 1 : 0,
            nomineeName,
            nomineeAge,
            nomineeRelation,
            introducer,
            societyId: session.societyId,
        };
        dispatch(Actions.create(data));
    };

    const updateForm = (e) => {
        e.preventDefault();
        setAlertStatus(true);
        const data = {
            id,
            agentId,
            accountHolderId,
            ledgerNumber,
            folioNumber,
            frequency,
            amount,
            maturityDate: Utils.formatDate(maturityDate),
            notifyMaturity: notifyMaturity ? 1 : 0,
            status: status ? 1 : 0,
            nomineeName,
            nomineeAge,
            nomineeRelation,
            introducer,
            societyId: session.societyId,
        };
        dispatch(Actions.update(data));
    };

    const resetform = () => {
        setAgentId("");
        setAccountHolderId("");
        setLedgerNumber("");
        setFolioNumber("");
        setFrequency("");
        setAmount("");
        setMaturityDate("");
        setNotifyMaturity(false);
        setNomineeName("");
        setNomineeAge("");
        setNomineeRelation("");
        setIntroducer("");
        setStatus(false);
    };

    let pigmyOptions = [];
    pigmyOptions["daily"] = "Daily";
    pigmyOptions["weekly"] = "Weekly";
    pigmyOptions["fortnightly"] = "Fortnightly";
    pigmyOptions["monthly"] = "Monthly";
    pigmyOptions["bimonthly"] = "Bimonthly";
    pigmyOptions["quarterly"] = "Quarterly";

    let nomineeRelationOption = Utils.getNomineeRelationOptions();

    const pageNavItems = [
        {
            title: "Pigmy Account",
            active: true,
            to: "/pigmy-account",
        },
        {
            title: "Create / Update",
            active: false,
            to: "/pigmy-account/add",
        },
    ];

    return (
        <Layout pageNavItems={pageNavItems} pageTitle="Pigmy Account">
            <Card>
                <Card.Body>
                    <Form onSubmit={id ? updateForm : submitForm}>
                        <Row>
                            <Col md={4}>
                                <SearchableSelect
                                    label="Select Pigmy Agent"
                                    Options={agentOptions}
                                    errMessage={errors["agentId"]}
                                    value={agentId}
                                    controlId="formAgentId"
                                    name="formAgentId"
                                    onChange={(selectedOption) => {
                                        if (selectedOption.length > 0) {
                                            setAgentId(selectedOption[0].value);
                                        }
                                    }}
                                />
                            </Col>

                            <Col md={4}>
                                <SearchableSelect
                                    name="accountHolderId"
                                    controlId="formAccountHolderId"
                                    label="Select Account Holder"
                                    Options={accountHolderOptions}
                                    errMessage={errors["accountHolderId"]}
                                    value={accountHolderId}
                                    onChange={(selectedOption) => {
                                        if (selectedOption.length > 0) {
                                            setAccountHolderId(selectedOption[0].value);
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                                <SearchableSelect
                                    name="introducer"
                                    controlId="formIntroducer"
                                    label="Select Introducer Holder"
                                    Options={accountHolderOptions}
                                    errMessage={errors["introducer"]}
                                    value={introducer}
                                    onChange={(selectedOption) => {
                                        if (selectedOption.length > 0) {
                                            setIntroducer(selectedOption[0].value);
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Input
                                    name="folioNumber"
                                    type="text"
                                    label="Enter Folio Number"
                                    placeholder="Enter Folio Number"
                                    value={folioNumber}
                                    onChange={(e) => setFolioNumber(e.target.value)}
                                    controlId="formFolioNumber"
                                />
                            </Col>
                            <Col md={4}>
                                <Input
                                    name="ledgerNumber"
                                    type="text"
                                    label="Enter Ledger Number"
                                    placeholder="Enter Ledger Number"
                                    value={ledgerNumber}
                                    onChange={(e) => setLedgerNumber(e.target.value)}
                                    controlId="formLedgerNumber"
                                />
                            </Col>
                            <Col md={4}>
                                <SelectInput
                                    name="frequency"
                                    placeholder="Select Frequency"
                                    label="Select Frequency"
                                    type="text"
                                    Options={pigmyOptions}
                                    value={frequency}
                                    handleChange={(e) => setFrequency(e.target.value)}
                                    controlId="formFrequency"
                                />
                            </Col>
                            <Col md={4}>
                                <Input
                                    type="text"
                                    name="amount"
                                    label="Enter Amount"
                                    placeholder="Enter Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    controlId="formAmount"
                                />
                            </Col>
                            <Col md={4}>
                                <DatePickerInput
                                    errMessage={errors["maturityDate"]}
                                    icon="fa-calendar"
                                    label="Maturity Date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={maturityDate}
                                    onChange={(date) => setMaturityDate(date)}
                                />
                            </Col>
                            <Col md={2}>
                                <label>Notify On Maturity</label>
                                <br />
                                <BootstrapSwitchButton
                                    checked={notifyMaturity}
                                    onstyle="outline-success"
                                    offstyle="outline-danger"
                                    onlabel="Active"
                                    offlabel="InActive"
                                    onChange={(checked) => {
                                        setNotifyMaturity(checked);
                                    }}
                                />
                            </Col>
                            <Col md={2}>
                                <label>Status</label>
                                <br />
                                <BootstrapSwitchButton
                                    checked={status}
                                    onstyle="outline-success"
                                    offstyle="outline-danger"
                                    onlabel="Active"
                                    offlabel="InActive"
                                    onChange={(checked) => {
                                        setStatus(checked);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <NomineeInput
                                    type="text"
                                    label="Nominee Name"
                                    placeholder="Enter nominee name"
                                    name="nomineeName"
                                    id="nomineeName"
                                    value={nomineeName}
                                    onChange={(e) => setNomineeName(e.target.value)}
                                    errMessage={errors["nomineeName"]}
                                    controlId="formNomineeName"
                                    setNominee={setNominee}
                                />
                            </Col>
                            <Col md={4}>
                                <Input
                                    type="text"
                                    label="Nominee Age"
                                    placeholder="Enter nominee age"
                                    name="nomineeAge"
                                    id="nomineeAge"
                                    value={nomineeAge}
                                    onChange={(e) => setNomineeAge(e.target.value)}
                                    errMessage={errors["nomineeAge"]}
                                    controlId="formNomineeAge"
                                />
                            </Col>
                            <Col md="4">
                                <SelectInput
                                    id="nomineeRelation"
                                    name="nomineeRelation"
                                    label="Select Nominee Relation"
                                    errMessage={errors["nomineeRelation"]}
                                    handleChange={(e) => setNomineeRelation(e.target.value)}
                                    Options={nomineeRelationOption}
                                    value={nomineeRelation}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <br />
                                <Button variant="primary" type="Submit">
                                    Submit
                                </Button>
                                &nbsp;&nbsp;
                                <Button variant="secondary" onClick={resetform} type="Button">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <div className="text-center mt-5">
                            <RequestStatus
                                stateObj={stateObject}
                                alertStatus={alertStatus}
                                setAlertStatus={setAlertStatus}
                            />
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default PigmyAccount;
